import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as Truck } from '../../../../assets/truck.svg';
import { ReactComponent as Barcode } from '../../../../assets/barcode-sm.svg';
import Logo from '../../../../components/fragments/logo/logo';

import Heading from '../../../../components/fragments/heading';
import Text from '../../../../components/fragments/text';
import Button from '../../../../components/fragments/button';

import { AssetActions } from '../../../../states/actions';

const PostOnboard = () => {
  const dispatch = useDispatch();
  const assets = useSelector(state => state.assets);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(AssetActions.fetchOnboardCompleted(id));
    } // eslint-disable-next-line
  }, [id]);

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className={` px-4 sm:px-6 h-full bg-dots`}>
        <div className={`py-8 sm:py-16 space-y-6 w-full`}>
          <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-start items-center`}>
            <Heading className={`text-brand_blue font-semibold`} title={`Post-Asset Onboarding`} size={2} />
          </div>
          <div className={`flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 w-full`}>
            <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center space-x-4`}>
              <div className={`flex-1 space-y-6`}>
                <Heading
                  className={`font-medium`}
                  title={`${assets.onBoardCompleted.length} ${assets.onBoardCompleted.length === 1 ? `Asset` : `Assets`} onboarded`}
                  size={2}
                />
                {/* <Text
                className={`font-semibold underline text-brand_blue text-lg cursor-pointer`}
                value={`Onboard More Assets`}
                onClick={() => history.push('/onboarding/upload-assets')}
              /> */}
              </div>
              <div className={`flex-1`}>
                <Truck className={`w-full h-full sm:w-60`} />
              </div>
            </div>
            <div className={`flex-1 rounded-lg space-y-6 py-6 lg:py-10 px-6 sm:px-0`}>
              <Barcode />
              <Text value={`You Have To Generate ${assets.onBoardCompleted.length} Sticker IDs`} />
              <Button text={`Generate Sticker IDs`} onClick={() => history.push('/onboarding/onboarded-assets')} />
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default PostOnboard;
