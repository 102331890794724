import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Checked } from '../../../assets/radio-checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/radio-unchecked.svg';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdDelete } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';

import Button from '../button';
import InputBox from '../input-box';
import SelectBox from '../select-box';
import Dialog from '../dialog';

import AssetActions from '../../../states/actions/assets';

const AssetTable = ({ data, variant, nameLink, onSelect, onClick, moving, onboarding, tags, appName, perPage, currentPage }) => {
  const dispatch = useDispatch();
  const [assets, setAssets] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [assetsToMove, setAssetsToMove] = useState([]);

  useEffect(() => {
    if (assets) {
      setAllSelected(assets.every(a => a.selected));
      if (onSelect) onSelect(assets);
    }
    // eslint-disable-next-line
  }, [assets]);

  useEffect(() => {
    if (data) {
      let allAssets = data
        .filter(asset => {
          return asset.name || asset.day || asset.status || asset.count_batch || asset.id;
        })
        .map((asset, idx) => {
          // asset.unit = 1000;
          // asset.totalPrice = asset.price * asset.unit;
          // asset.dateInstalled = 'Mon Jan 08 2021';
          if (onboarding && tags[idx] && !asset.macAddress && !asset.editted) {
            asset.macAddress = tags[idx];
            dispatch(AssetActions.updateItem(0, asset));
          }
          return asset;
        });

      setAssets(allAssets);
    } // eslint-disable-next-line
  }, [data]);

  const selectAll = () => {
    if (allSelected) {
      let selected = assets.map(asset => {
        asset.selected = false;
        asset.moveDetails = '';
        return asset;
      });
      // setAllSelected(false);
      setAssets(selected);
    } else {
      let selected = assets.map(asset => {
        if (moving && asset.packageLevel !== 'Primary') {
          setAssetsToMove([asset]);
          setOpen(true);
        } else {
          asset.selected = true;
        }
        return asset;
      });
      // setAllSelected(true);
      setAssets(selected);
    }
  };

  const selectAsset = index => {
    // setAllSelected(false);
    let items = assets.map((item, idx) => {
      if (index === idx) {
        if (!item.selected && moving && item.packageLevel !== 'Primary') {
          setAssetsToMove([item]);
          setOpen(true);
        } else {
          if (onSelect) onSelect(item);
          if (onClick && !item.selected) onClick(item);
          item.selected = !item.selected;
        }
      }
      return item;
    });
    setAssets(items);
  };

  const selectAssetBySN = asset => {
    let items = assets.map(item => {
      if (item.serialNumber === asset.serialNumber) {
        if (onSelect) onSelect(item);
        if (onClick && !item.selected) onClick(item);
        item.selected = !item.selected;
      }
      return item;
    });
    setAssets(items);
  };

  const onMoveDetailsAdd = items => {
    setAssetsToMove([]);
    let lt = assets.map(a => {
      let item = '';
      items.forEach(b => {
        if (a.id === b.id) {
          b.selected = true;
          item = b;
        }
      });
      if (item) {
        return item;
      } else {
        return a;
      }
    });
    setAssets(lt);
  };

  const onItemSave = (asset, index) => {
    asset.selected = false;
    asset.macAddress = asset?.macAddress === 'Select Mac Address' ? '' : asset.macAddress;
    asset.editted = true;
    dispatch(AssetActions.updateItem(index, asset));
  };

  const deleteAsset = asset => {
    dispatch(AssetActions.deleteItem(asset));
  };

  return (
    <>
      {!variant && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                {allSelected ? <Checked /> : <Unchecked />}
              </div>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              <div className={`w-10 flex-shrink-0 invisible text-center`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item key={idx} asset={item} idx={idx} selectAsset={selectAsset} onSave={onItemSave} nameLink={nameLink} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 'primary' && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                {/* {allSelected ? <Checked /> : <Unchecked />} */}
              </div>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              {appName !== 'im' && <div className={`w-40 flex-shrink-0`}>Mac Address</div>}
              <div className={`w-10 flex-shrink-0 invisible text-center`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets
                .filter(d => d.packageLevel === 'Primary')
                .map((item, idx) => {
                  return (
                    <ItemPrimary
                      key={idx}
                      asset={item}
                      idx={idx}
                      selectAsset={selectAssetBySN}
                      onSave={onItemSave}
                      nameLink={nameLink}
                      tags={tags}
                      appName={appName}
                      deleteAsset={deleteAsset}
                    />
                  );
                })}
          </FadeIn>
        </div>
      )}
      {variant === 'secondary' && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                {/* {allSelected ? <Checked /> : <Unchecked />} */}
              </div>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Number of Sub Units</div>
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              {appName !== 'im' && <div className={`w-40 flex-shrink-0`}>Mac Address</div>}
              <div className={`w-10 flex-shrink-0 invisible text-center`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets
                .filter(d => d.packageLevel === 'Secondary')
                .map((item, idx) => {
                  return (
                    <ItemSecondary
                      key={idx}
                      asset={item}
                      idx={idx}
                      selectAsset={selectAssetBySN}
                      onSave={onItemSave}
                      nameLink={nameLink}
                      tags={tags}
                      appName={appName}
                      deleteAsset={deleteAsset}
                    />
                  );
                })}
          </FadeIn>
        </div>
      )}
      {variant === 'tertiary' && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                {/* {allSelected ? <Checked /> : <Unchecked />} */}
              </div>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Number of Sub Units</div>
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              {appName !== 'im' && <div className={`w-40 flex-shrink-0`}>Mac Address</div>}
              <div className={`w-10 flex-shrink-0 invisible text-center`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets
                .filter(d => d.packageLevel === 'Tertiary')
                .map((item, idx) => {
                  return (
                    <ItemTertiary
                      key={idx}
                      asset={item}
                      idx={idx}
                      selectAsset={selectAssetBySN}
                      onSave={onItemSave}
                      nameLink={nameLink}
                      tags={tags}
                      appName={appName}
                      deleteAsset={deleteAsset}
                    />
                  );
                })}
          </FadeIn>
        </div>
      )}
      {variant === 1 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-64 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}> Price</div>
              <div className={`w-40 flex-shrink-0`}>Unit</div>
              <div className={`w-40 flex-shrink-0`}>Total Price</div>
              <div className={`w-40 flex-shrink-0`}>Date Onboarded</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item52 key={idx} asset={item} idx={idx} selectAsset={selectAsset} onSave={onItemSave} nameLink={nameLink} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 1.5 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-64 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Unit</div>
              <div className={`w-40 flex-shrink-0`}>Total Price</div>
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item51 key={idx} asset={item} idx={idx} selectAsset={selectAsset} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 1.6 && (
        <div className={`overflow-auto  py-6 w-full`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-64 flex-shrink-0`}>Stock Type Counted</div>
              <div className='flex-1'></div>
              <div className={`w-48 flex-shrink-0`}>Last Count</div>
              <div className='flex-1'></div>
              <div className={`w-48 flex-shrink-0`}>New Count</div>
              <div className='flex-1'></div>
              <div className='w-60'>Comments</div>
              <div className='flex-1'></div>
              <div className={`w-48 flex-shrink-0`}>Date Counted</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item530 key={idx} asset={item} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 1.61 && (
        <div className={`overflow-auto  py-6 w-full`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-64 flex-shrink-0`}>Report Type</div>
              <div className='flex-1'></div>
              <div className={`w-48 flex-shrink-0`}>Last Count</div>
              <div className='flex-1'></div>
              <div className={`w-48 flex-shrink-0`}>New Count</div>
              <div className='flex-1'></div>
              <div className={`w-48 flex-shrink-0`}>Date Recorded</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item53 key={idx} asset={item} idx={idx} selectAsset={selectAsset} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 1.7 && (
        <div className={`overflow-auto  py-6 min-w-full`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-64 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-48 flex-shrink-0`}>Warehouse Name</div>
              <div className={`w-48 flex-shrink-0`}>Warehouse Location</div>
              <div className={`w-48 flex-shrink-0`}>Date Generated</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item54 key={idx} asset={item} idx={idx} selectAsset={selectAsset} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 2 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-72 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-56 flex-shrink-0`}>Asset Price</div>
              <div className={`w-56 flex-shrink-0`}>Batch Number</div>
              <div className={`w-56 flex-shrink-0`}>Expiry Date</div>
              <div className={`w-56 flex-shrink-0`}>Sticker ID</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item2 key={idx} asset={item} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 3 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              {moving ? (
                <div className={`w-6`}></div>
              ) : (
                <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                  {allSelected ? <Checked /> : <Unchecked />}
                </div>
              )}
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-60 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              <div className={`w-40 flex-shrink-0`}>Sticker ID</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item3 key={idx} asset={item} idx={idx} selectAsset={selectAsset} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 4 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-16 flex-shrink-0`}>Day</div>
              <div className={`w-16 flex-shrink-0`}>Time</div>
              <div className={`w-16 flex-shrink-0`}>Initial K</div>
              <div className={`w-16 flex-shrink-0`}>Final K</div>
              <div className={`w-24 flex-shrink-0 flex-1`}>Date</div>
              <div className={`w-10 flex-shrink-0 invisible text-center`}></div>
              <div className={`w-10 flex-shrink-0 invisible text-center`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item4 key={idx} asset={item} idx={idx} selectAsset={selectAsset} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 6 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                {allSelected ? <Checked /> : <Unchecked />}
              </div>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Sticker ID</div>
              {appName !== 'im' && <div className={`w-40 flex-shrink-0`}>Mac Address</div>}
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              <div className={`w-40 flex-shrink-0`}>Date Onboarded</div>
              <div className={`w-40 flex-shrink-0`}></div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item6 key={idx} asset={item} idx={idx} selectAsset={selectAsset} nameLink={nameLink} appName={appName} tags={tags} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 6.1 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div onClick={selectAll} className={`w-6 cursor-pointer`}>
                {allSelected ? <Checked /> : <Unchecked />}
              </div>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-40 flex-shrink-0`}>Asset Price</div>
              <div className={`w-40 flex-shrink-0`}>Batch Number</div>
              <div className={`w-40 flex-shrink-0`}>Category</div>
              <div className={`w-40 flex-shrink-0`}>Package Level</div>
              <div className={`w-40 flex-shrink-0`}>Sticker ID</div>
              {appName !== 'im' && <div className={`w-40 flex-shrink-0`}>Mac Address</div>}
              <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
              <div className={`w-40 flex-shrink-0`}>Date Onboarded</div>
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item61 key={idx} asset={item} idx={idx} selectAsset={selectAsset} appName={appName} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 7 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-16 flex-shrink-0`}>SN</div>
              <div className={`w-72 flex-shrink-0`}>Asset Name</div>
              <div className={`flex-1`}></div>
              <div className={`w-56 flex-shrink-0`}>Asset Price</div>
              <div className={`w-56 flex-shrink-0`}>Batch Number</div>
              <div className={`w-56 flex-shrink-0`}>Category</div>
              <div className={`w-56 flex-shrink-0`}>Package Level</div>
              <div className={`w-56 flex-shrink-0`}>Expiry Date</div>
              {appName !== 'im' && <div className={`w-56 flex-shrink-0 overflow-auto`}>Mac Address</div>}
            </div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item7 key={idx} asset={item} appName={appName} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 7.1 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 p-6 font-medium border-b border-gray-200`}>
            <div className={`w-72 flex-shrink-0`}>Process</div>
            <div className={`w-72 flex-shrink-0`}>Started</div>
            <div className={`w-56 flex-shrink-0 pl-24`}>Status</div>
            <div className={`flex-1`}></div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item71 key={idx} asset={item} />;
              })}
          </FadeIn>
        </div>
      )}
      {variant === 7.2 && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 p-6 font-medium border-b border-gray-200`}>
            <div className={`w-72 flex-shrink-0`}>Name</div>
            <div className={`w-40 flex-shrink-0`}>Price</div>
            <div className={`w-40 flex-shrink-0`}>Serial Number</div>
            <div className={`w-40 flex-shrink-0`}>Batch Number</div>
            <div className={`w-40 flex-shrink-0`}>Mac Address</div>
            <div className={`w-40 flex-shrink-0`}>Category</div>
            <div className={`w-40 flex-shrink-0`}>Package Level</div>
            <div className={`w-40 flex-shrink-0`}>Number of Sub Units</div>
            <div className={`w-40 flex-shrink-0`}>Expiry Date</div>
            <div className={`w-40 flex-shrink-0`}>Date of Alarm</div>
            <div className={`w-36 flex-shrink-0`}>Notification</div>
          </div>
          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {assets &&
              assets.map((item, idx) => {
                return <Item72 key={idx} asset={item} perPage={perPage} currentPage={currentPage} />;
              })}
          </FadeIn>
        </div>
      )}
      {assetsToMove && assetsToMove.length > 0 && (
        <Dialog open={open} setOpen={setOpen} type={`move-assets`} data={assetsToMove} onMoveDetailsAdd={onMoveDetailsAdd} />
      )}
    </>
  );
};

const Item = ({ asset, idx, selectAsset, onSave, nameLink }) => {
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    if (name === 'numberOfSubUnits') {
      setItem({ ...item, [name]: Number(value) });
      return;
    }
    setItem({ ...item, [name]: value });
  };

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/assets/detail/${idx}`);
    } else {
      selectAsset(idx);
    }
  };

  const formatDate = date => {
    let d = new Date(date).toLocaleDateString();
    let arr = d.split('/');
    let i = arr[0];
    let j = arr[1];
    let k = arr[2];
    let arr2 = [k, j, i];
    return arr2.join('-');
  };

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(idx)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-48 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`} onClick={handleNameClick}>
            {item?.name}
          </span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        <div className={`w-10 flex-shrink-0 flex justify-center items-center cursor-pointer`}>
          {item?.selected ? <MdKeyboardArrowUp size={24} /> : <MdKeyboardArrowDown size={24} />}
        </div>
      </div>
      {item?.selected && (
        <div className={`w-full flex flex-shrink-0 items-end border-t border-gray-300 space-x-6 py-6`}>
          <div className={`w-6 invisible`}></div>

          <div className={`flex items-center space-x-6 w-full flex-1 text-sm`}>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.serialNumber}
                name={`serialNumber`}
                value={item?.serialNumber}
                onValueChange={handleInputChange}
                label={`Serial Number`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium flex-1`}>
              <InputBox variant={2} placeholder={item?.name} name={`name`} value={item?.name} onValueChange={handleInputChange} label={`Asset Name`} />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.price}
                name={`price`}
                type={`number`}
                value={item?.price}
                onValueChange={handleInputChange}
                label={`Asset Price`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.batchNumber}
                name={`batchNumber`}
                value={item?.batchNumber}
                onValueChange={handleInputChange}
                label={`Batch Number`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.category}
                name={`category`}
                value={item?.category}
                onValueChange={handleInputChange}
                label={`Category`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.packageLevel}
                name={`packageLevel`}
                value={item?.packageLevel}
                onValueChange={handleInputChange}
                label={`Package Level`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={new Date(item?.expiryDate).toDateString()}
                name={`expiryDate`}
                type={`date`}
                value={formatDate(item?.expiryDate)}
                onValueChange={handleInputChange}
                label={`Expiry Date`}
              />
            </div>
            <Button className={`mt-8`} text={`Save`} size={2} onClick={() => onSave(item, idx)} />
          </div>
        </div>
      )}
    </div>
  );
};

const ItemPrimary = ({ asset, idx, selectAsset, onSave, nameLink, tags, appName, deleteAsset }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  const handleInputChange = (event, data) => {
    const { name, value } = event.target;
    if (name === 'numberOfSubUnits') {
      setItem({ ...item, [name]: Number(value) });
      return;
    }
    if (name === 'macAddress') {
      if (value === 'Select Mac Address') {
        dispatch(AssetActions.updateItemMacAddress({ asset_id: data.asset_id, mac_address: '' }));
      } else {
        dispatch(AssetActions.updateItemMacAddress({ asset_id: data.asset_id, mac_address: data.mac_address }));
      }
    }
    setItem({ ...item, [name]: value });
  };

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/assets/detail/${idx}`);
    } else {
      selectAsset(item);
    }
  };

  const formatDate = date => {
    let d = new Date(date).toLocaleDateString();
    let arr = d.split('/');
    let i = arr[0];
    let j = arr[1];
    let k = arr[2];
    let arr2 = [k, j, i];
    return arr2.join('-');
  };

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(item)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-56 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`} onClick={handleNameClick}>
            {item?.name}
          </span>
        </div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        {appName !== 'im' && <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.macAddress}</div>}
        <div className={`flex-1`}></div>
        <div className={`w-10 flex-shrink-0 flex justify-center items-center cursor-pointer`}>
          {item?.selected ? <MdKeyboardArrowUp size={24} /> : <MdKeyboardArrowDown size={24} />}
        </div>
      </div>
      {item?.selected && (
        <div className={`w-full flex flex-shrink-0 items-end border-t border-gray-300 space-x-6 py-6`}>
          <div className={`w-6 invisible`}></div>

          <div className={`flex items-center space-x-6 w-full flex-1 text-sm`}>
            <div className={`w-16 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.serialNumber}
                name={`serialNumber`}
                value={item?.serialNumber}
                onValueChange={handleInputChange}
                label={`SN`}
                readOnly={true}
              />
            </div>
            <div style={{ width: '13.5rem' }} className={`text-gray-400 font-medium`}>
              <InputBox variant={2} placeholder={item?.name} name={`name`} value={item?.name} onValueChange={handleInputChange} label={`Asset Name`} />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.price}
                name={`price`}
                type={`number`}
                value={item?.price}
                onValueChange={handleInputChange}
                label={`Asset Price`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.batchNumber}
                name={`batchNumber`}
                value={item?.batchNumber}
                onValueChange={handleInputChange}
                label={`Batch Number`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.category}
                name={`category`}
                value={item?.category}
                onValueChange={handleInputChange}
                label={`Category`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.packageLevel}
                name={`packageLevel`}
                value={item?.packageLevel}
                onValueChange={handleInputChange}
                label={`Package Level`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={new Date(item?.expiryDate).toDateString()}
                name={`expiryDate`}
                type={`date`}
                value={formatDate(item?.expiryDate)}
                onValueChange={handleInputChange}
                label={`Expiry Date`}
              />
            </div>
            {appName !== 'im' && (
              <div className={`w-40 text-gray-400 font-medium`}>
                <SelectBox
                  variant={2}
                  options={tags}
                  name={`macAddress`}
                  value={item?.macAddress}
                  placeholder={'Select Mac Address'}
                  onValueChange={e => handleInputChange(e, { asset_id: item?.serialNumber, mac_address: e.target.value })}
                  label={`Mac Address`}
                />
              </div>
            )}
            <div className={`flex-1`}></div>
            <div className={`space-y-4 flex flex-col justify-between items-center`}>
              <MdDelete onClick={() => deleteAsset(item)} className='cursor-pointer' size={20} />
              <Button text={`Save`} size={2} onClick={() => onSave(item, idx)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ItemSecondary = ({ asset, idx, selectAsset, onSave, nameLink, tags, appName, deleteAsset }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  const handleInputChange = (event, data) => {
    const { name, value } = event.target;
    if (name === 'numberOfSubUnits') {
      setItem({ ...item, [name]: Number(value) });
      return;
    }
    if (name === 'macAddress') {
      if (value === 'Select Mac Address') {
        dispatch(AssetActions.updateItemMacAddress({ asset_id: data.asset_id, mac_address: '' }));
      } else {
        dispatch(AssetActions.updateItemMacAddress({ asset_id: data.asset_id, mac_address: data.mac_address }));
      }
    }
    setItem({ ...item, [name]: value });
  };

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/assets/detail/${idx}`);
    } else {
      selectAsset(item);
    }
  };

  const formatDate = date => {
    let d = new Date(date).toLocaleDateString();
    let arr = d.split('/');
    let i = arr[0];
    let j = arr[1];
    let k = arr[2];
    let arr2 = [k, j, i];
    return arr2.join('-');
  };

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(item)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-56 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`} onClick={handleNameClick}>
            {item?.name}
          </span>
        </div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.numberOfSubUnits}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        {appName !== 'im' && <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.macAddress}</div>}
        <div className={`flex-1`}></div>
        <div className={`w-10 flex-shrink-0 flex justify-center items-center cursor-pointer`}>
          {item?.selected ? <MdKeyboardArrowUp size={24} /> : <MdKeyboardArrowDown size={24} />}
        </div>
      </div>
      {item?.selected && (
        <div className={`w-full flex flex-shrink-0 items-end border-t border-gray-300 space-x-6 py-6`}>
          <div className={`w-6 invisible`}></div>

          <div className={`flex items-center space-x-6 w-full flex-1 text-sm`}>
            <div className={`w-16 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.serialNumber}
                name={`serialNumber`}
                value={item?.serialNumber}
                onValueChange={handleInputChange}
                label={`SN`}
                readOnly={true}
              />
            </div>
            <div style={{ width: '13.5rem' }} className={`text-gray-400 font-medium`}>
              <InputBox variant={2} placeholder={item?.name} name={`name`} value={item?.name} onValueChange={handleInputChange} label={`Asset Name`} />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.price}
                name={`price`}
                type={`number`}
                value={item?.price}
                onValueChange={handleInputChange}
                label={`Asset Price`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.batchNumber}
                name={`batchNumber`}
                value={item?.batchNumber}
                onValueChange={handleInputChange}
                label={`Batch Number`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.category}
                name={`category`}
                value={item?.category}
                onValueChange={handleInputChange}
                label={`Category`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.packageLevel}
                name={`packageLevel`}
                value={item?.packageLevel}
                onValueChange={handleInputChange}
                label={`Package Level`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.numberOfSubUnits}
                name={`numberOfSubUnits`}
                value={item?.numberOfSubUnits}
                onValueChange={handleInputChange}
                label={`Number of Sub Units`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={new Date(item?.expiryDate).toDateString()}
                name={`expiryDate`}
                type={`date`}
                value={formatDate(item?.expiryDate)}
                onValueChange={handleInputChange}
                label={`Expiry Date`}
              />
            </div>
            {appName !== 'im' && (
              <div className={`w-40 text-gray-400 font-medium`}>
                <SelectBox
                  variant={2}
                  options={tags}
                  name={`macAddress`}
                  value={item?.macAddress}
                  placeholder={'Select Mac Address'}
                  onValueChange={e => handleInputChange(e, { asset_id: item?.serialNumber, mac_address: e.target.value })}
                  label={`Mac Address`}
                />
              </div>
            )}
            <div className={`flex-1`}></div>
            <div className={`space-y-4 flex flex-col justify-between items-center`}>
              <MdDelete onClick={() => deleteAsset(item)} className='cursor-pointer' size={20} />
              <Button text={`Save`} size={2} onClick={() => onSave(item, idx)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ItemTertiary = ({ asset, idx, selectAsset, onSave, nameLink, tags, appName, deleteAsset }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  const handleInputChange = (event, data) => {
    const { name, value } = event.target;
    if (name === 'numberOfSubUnits') {
      setItem({ ...item, [name]: Number(value) });
      return;
    }
    if (name === 'macAddress') {
      if (value === 'Select Mac Address') {
        dispatch(AssetActions.updateItemMacAddress({ asset_id: data.asset_id, mac_address: '' }));
      } else {
        dispatch(AssetActions.updateItemMacAddress({ asset_id: data.asset_id, mac_address: data.mac_address }));
      }
    }
    setItem({ ...item, [name]: value });
  };

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/assets/detail/${idx}`);
    } else {
      selectAsset(item);
    }
  };

  const formatDate = date => {
    let d = new Date(date).toLocaleDateString();
    let arr = d.split('/');
    let i = arr[0];
    let j = arr[1];
    let k = arr[2];
    let arr2 = [k, j, i];
    return arr2.join('-');
  };

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(item)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-56 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`} onClick={handleNameClick}>
            {item?.name}
          </span>
        </div>

        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.numberOfSubUnits}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        {appName !== 'im' && <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.macAddress}</div>}
        <div className={`flex-1`}></div>
        <div className={`w-10 flex-shrink-0 flex justify-center items-center cursor-pointer`}>
          {item?.selected ? <MdKeyboardArrowUp size={24} /> : <MdKeyboardArrowDown size={24} />}
        </div>
      </div>
      {item?.selected && (
        <div className={`w-full flex flex-shrink-0 items-end border-t border-gray-300 space-x-6 py-6`}>
          <div className={`w-6 invisible`}></div>

          <div className={`flex items-center space-x-6 w-full flex-1 text-sm`}>
            <div className={`w-16 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.serialNumber}
                name={`serialNumber`}
                value={item?.serialNumber}
                onValueChange={handleInputChange}
                label={`SN`}
                readOnly={true}
              />
            </div>
            <div style={{ width: '13.5rem' }} className={`text-gray-400 font-medium`}>
              <InputBox variant={2} placeholder={item?.name} name={`name`} value={item?.name} onValueChange={handleInputChange} label={`Asset Name`} />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.price}
                name={`price`}
                type={`number`}
                value={item?.price}
                onValueChange={handleInputChange}
                label={`Asset Price`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.batchNumber}
                name={`batchNumber`}
                value={item?.batchNumber}
                onValueChange={handleInputChange}
                label={`Batch Number`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.category}
                name={`category`}
                value={item?.category}
                onValueChange={handleInputChange}
                label={`Category`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.packageLevel}
                name={`packageLevel`}
                value={item?.packageLevel}
                onValueChange={handleInputChange}
                label={`Package Level`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={item?.numberOfSubUnits}
                name={`numberOfSubUnits`}
                value={item?.numberOfSubUnits}
                onValueChange={handleInputChange}
                label={`Number of Sub Units`}
              />
            </div>
            <div className={`w-40 text-gray-400 font-medium`}>
              <InputBox
                variant={2}
                placeholder={new Date(item?.expiryDate).toDateString()}
                name={`expiryDate`}
                type={`date`}
                value={formatDate(item?.expiryDate)}
                onValueChange={handleInputChange}
                label={`Expiry Date`}
              />
            </div>
            {appName !== 'im' && (
              <div className={`w-40 text-gray-400 font-medium`}>
                <SelectBox
                  variant={2}
                  options={tags}
                  name={`macAddress`}
                  value={item?.macAddress}
                  placeholder={'Select Mac Address'}
                  onValueChange={e => handleInputChange(e, { asset_id: item?.serialNumber, mac_address: e.target.value })}
                  label={`Mac Address`}
                />
              </div>
            )}
            <div className={`flex-1`}></div>
            <div className={`space-y-4 flex flex-col justify-between items-center`}>
              <MdDelete onClick={() => deleteAsset(item)} className='cursor-pointer' size={20} />
              <Button text={`Save`} size={2} onClick={() => onSave(item, idx)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Item2 = ({ asset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl bg-gray-100`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue text-lg w-72 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`}>{item?.name}</span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.stickerId?.value ? item?.stickerId?.value : '8498761330764250'}</div>
      </div>
    </div>
  );
};

const Item3 = ({ asset, idx, selectAsset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={` min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(idx)} className={`cursor-pointer w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-60 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`}>{item?.name}</span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.stickerId?.value ? item?.stickerId?.value : '8498761330764250'}</div>
      </div>
    </div>
  );
};

const Item4 = ({ asset, idx, selectAsset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100 text-sm`}`}>
      <div onClick={() => selectAsset(idx)} className={`cursor-pointer w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue w-16 font-semibold overflow-auto`}>
          <span className={``}>{item?.day}</span>
        </div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.time}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.initialTemp}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.finalTemp}</div>
        <div className={`w-24 flex-shrink-0 flex-1`}>{new Date(item?.date).toDateString()}</div>
        <div className={`w-10 flex-shrink-0 flex justify-center items-center cursor-pointer`}>
          <BsThreeDotsVertical size={16} />
        </div>
        <div className={`w-10 flex-shrink-0 flex justify-center items-center cursor-pointer`}>
          {item?.selected ? <MdKeyboardArrowUp size={24} /> : <MdKeyboardArrowDown size={18} />}
        </div>
      </div>
    </div>
  );
};

const Item52 = ({ asset, idx, selectAsset, onSave, nameLink }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(idx)} className={`cursor-pointer w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue text-lg w-64 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`}>{item?.name}</span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.package_level}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.unit}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price * item?.unit}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
      </div>
    </div>
  );
};

const Item51 = ({ asset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue text-lg w-64 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`}>{item?.name}</span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.unit}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.totalPrice}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
      </div>
    </div>
  );
};

const Item53 = ({ asset, idx, selectAsset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div onClick={() => selectAsset(idx)} className={`min-w-max flex-shrink-0 px-6 rounded-2xl bg-gray-100`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium cursor-pointer`}>
        <div className={`text-brand_blue text-lg w-64 flex-shrink-0 overflow-auto`}>
          <span>Stock Report</span>
        </div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.last_count}</div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.new_count}</div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
      </div>
    </div>
  );
};

const Item530 = ({ asset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl bg-gray-100`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue text-lg w-64 flex-shrink-0 overflow-auto`}>
          <span>{item?.asset?.name}</span>
        </div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.lastCount}</div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.newCount}</div>
        <div className='flex-1'></div>
        <div className='w-60'>{item?.comment}</div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
      </div>
    </div>
  );
};

const Item54 = ({ asset }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium cursor-pointer`}>
        <div className={`text-brand_blue text-lg w-64 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`}>{item?.name}</span>
        </div>
        <div className='flex-1'></div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.warehouseName}</div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.warehouseLocation}</div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
      </div>
    </div>
  );
};

const Item6 = ({ asset, idx, selectAsset, nameLink, appName, tags }) => {
  const [item, setItem] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/assets/detail/${asset.id}`);
    } else {
      selectAsset(idx);
    }
  };

  const setMacAddress = (event, assetId) => {
    event.preventDefault();
    event.stopPropagation();
    const { value } = event.target;
    let rfidId = tags.find(d => d.macAddress === value).id;
    if (window.confirm('Set mac address ?')) {
      dispatch(AssetActions.assignRfidTag(assetId, rfidId)).then(_ => window.location.reload());
    }
  };

  const disableRfid = (e, macAddress) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Are you sure you want to disable this mac address ?')) {
      dispatch(AssetActions.disableRfidTags(macAddress)).then(_ => window.location.reload());
    }
  };

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(idx)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-48 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer underline`} onClick={handleNameClick}>
            {item?.name}
          </span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.stickerId?.value}</div>

        {appName !== 'im' && (
          <>
            {!item?.macAddress ? (
              <div className={`w-40 text-gray-400 font-medium`}>
                <SelectBox
                  variant={2}
                  options={tags.map(d => d?.macAddress)}
                  name={`macAddress`}
                  value={item?.macAddress ? 'item?.macAddress' : 'Set Mac Address'}
                  onValueChange={e => setMacAddress(e, item.id)}
                  placeholder={'Set Mac Address'}
                />
              </div>
            ) : (
              <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.macAddress}</div>
            )}
          </>
        )}
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
        <div
          onClick={e => disableRfid(e, item?.macAddress)}
          className={`w-40 flex-shrink-0 overflow-auto text-brand_blue underline cursor-pointer ${item?.macAddress ? '' : 'pointer-events-none'}`}
        >
          {item?.macAddress ? `Disable Mac Address` : ''}
        </div>
      </div>
    </div>
  );
};

const Item61 = ({ asset, idx, selectAsset, nameLink, appName }) => {
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/assets/detail/${idx}`);
    } else {
      selectAsset(idx);
    }
  };

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected ? `bg-white border border-brand_blue` : `bg-gray-100`}`}>
      <div onClick={() => selectAsset(idx)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-48 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`} onClick={handleNameClick}>
            {item?.name}
          </span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.stickerId?.value ? item?.stickerId?.value : '8498761330764250'}</div>
        {appName !== 'im' && <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.macAddress}</div>}
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
      </div>
    </div>
  );
};

const Item7 = ({ asset, appName }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl bg-gray-100`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{item?.serialNumber}</div>
        <div className={`text-brand_blue text-lg w-72 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`}>{item?.name}</span>
        </div>
        <div className={`flex-1`}></div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.price}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.batchNumber}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.category}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.packageLevel}</div>
        <div className={`w-56 flex-shrink-0 overflow-auto`}>{new Date(item?.expiryDate).toDateString()}</div>
        {appName !== 'im' && <div className={`w-56 flex-shrink-0 overflow-auto`}>{item?.macAddress}</div>}
      </div>
    </div>
  );
};

const Item71 = ({ asset }) => {
  const history = useHistory();
  const [item, setItem] = useState();

  useEffect(() => {
    if (asset) {
      setItem(asset);
    }
  }, [asset]);

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl bg-gray-100`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue text-lg w-72 flex-shrink-0 overflow-auto`}>
          <span>Asset Onboard Process</span>
        </div>
        <div className={`w-72 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toUTCString()}</div>
        <div className={`${item?.status === 'Completed' ? 'text-green-600' : 'text-yellow-600'} w-56 flex-shrink-0 overflow-auto pl-24`}>{item?.status}</div>
        <div className={`flex-1`}></div>
        <div
          onClick={() => history.push(`/onboarding/${item.id}/post-onboard`)}
          className={`${item?.status === 'Completed' ? '' : 'pointer-events-none opacity-25'} text-brand_blue font-medium cursor-pointer`}
        >
          View/Print
        </div>
      </div>
    </div>
  );
};

const Item72 = ({ asset, perPage, currentPage }) => {
  const [item, setItem] = useState();
  const dispatch = useDispatch()
  const user = useSelector(state => state.user);
  const status = asset?.status === 0 ? 'notify' : 'stop-notify'

  useEffect(() => {
    if (asset) {
      if (asset?.id === '008a6a42-f546-4180-a53c-4f0c3aa787fb') {
        console.log('asset', asset)
      }
      setItem(asset);
    }
  }, [asset, user]);

  const onStatusChange = (change) => {
    let data = {
      status: change === 'notify' ? '0' : '1',
      // comment: "string",
      // reporterId: user?.id,
      assetId: asset?.id,
      // companyId: user?.company?.id
    }
    dispatch(AssetActions.updateNotifyStatus(data, perPage, currentPage))
  }

  return (
    <div className={`min-w-max flex-shrink-0 px-6 rounded-2xl bg-gray-100`}>
      <div className={`w-full flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        <div className={`text-brand_blue text-lg w-72 flex-shrink-0 overflow-auto`}>
          <span>{item?.asset?.name}</span>
        </div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>N{item?.asset?.price?.toLocaleString()}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.asset?.serialNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.asset?.batchNumber}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.asset?.macAddress}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.asset?.category}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.asset?.packageLevel}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{item?.asset?.numberOfSubUnits ? item?.asset?.numberOfSubUnits : 'No sub units'}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.asset?.expiryDate).toDateString()}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
        <div className={`w-36 flex-shrink-0 overflow-auto`}>
          <select onChange={(e) => onStatusChange(e.target.value)} className='w-28 bg-transparent select-none outline-none focus:outline-none cursor-pointer' name="status" id="status" value={status}>
            <option value="stop-notify">Disabled</option>
            <option value="notify">Enabled</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default AssetTable;
