import http from '../http';

export const retrieveNotifications = async () => {
  return http().get(`/notifications`);
};

export const markAsRead = async id => {
  return http().put(`/notifications/${id}`);
};

const NotificationService = {
  retrieveNotifications,
  markAsRead,
};

export default NotificationService;
