import http from '../http';

export const retrieveSites = async (perPage, page) => {
  return http().get(`/site?perPage=${perPage}&page=${page}`);
};

export const createSite = async data => {
  return http().post(`/site`, data);
};

const SiteService = {
  retrieveSites,
  createSite,
};

export default SiteService;
