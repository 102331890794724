import {
  ONBOARD_ASSETS,
  FETCH_ASSETS,
  UPDATE_ASSETS,
  UPDATE_PACKAGE_TYPES,
  UPDATE_ONBOARD_PHOTO,
  UPDATE_ASSET_ITEM,
  DELETE_ASSET_ITEM,
  UPDATE_SURVEY,
  UPDATE_STICKER,
  RESET_ONBOARD,
  CREATE_SURVEY,
  FETCH_SURVEYS,
  ATTACH_SURVEY_ASSET,
  ATTACH_WAREHOUSE,
  ATTACH_SURVEY_ID,
  FETCH_ASSET_TYPES,
  FETCH_ONBOARD_PROGRESS,
  FETCH_ONBOARD_COMPLETED,
  SET_APP,
  FETCH_STOCK_COUNT_HISTORY,
  FETCH_STOCK_COUNT_BATCH,
  CREATE_STOCK_COUNT,
  RETRIEVE_RFID_TAGS,
  FETCH_FINANCES,
  FETCH_ALARM_LOGS,
  FETCH_LOCATION,
  FETCH_PATH,
  UPDATE_ITEM_MAC_ADDRESS,
} from '../type';

const initialState = {
  assets: [],
  surveys: [],
  onboarding: { assetDetails: [] },
  packageTypes: [],
  assetTypes: [],
  onBoardProgress: [],
  onBoardCompleted: [],
  appName: '',
  stockCount: { history: [], currentBatch: [] },
  rfidTags: [],
  finances: [],
  assetLocation: { path: [] },
};

const assetsReducer = (assets = initialState, action) => {
  const { type, payload } = action;

  let data = '';
  switch (type) {
    case RESET_ONBOARD:
      return {
        ...assets,
        onboarding: {
          assetDetails: [],
        },
      };
    case ONBOARD_ASSETS:
      return { ...assets, onboarding: assets.onboarding };
    case FETCH_ASSETS:
      return { ...assets, assets: payload };
    case UPDATE_ASSETS:
      const m = payload.reduce((p, n) => {
        return [...p, ...n.data];
      }, []);
      data = { ...assets.onboarding, assetDetails: [...assets.onboarding.assetDetails, ...m] };
      return { ...assets, onboarding: data };
    case UPDATE_ITEM_MAC_ADDRESS:
      if (!payload?.asset_id) {
        return assets;
      }

      const m2 = assets.onboarding.assetDetails.map(d => {
        if (d.macAddress === payload?.mac_address) {
          d.macAddress = '';
          d.editted = true;
        }
        if (d.id === payload.asset_id) {
          d.macAddress = payload?.mac_address;
        }
        return d;
      });
      data = { ...assets.onboarding, assetDetails: m2 };
      return { ...assets, onboarding: data };
    case UPDATE_ONBOARD_PHOTO:
      return { ...assets, onboarding: { ...assets.onboarding, photo: payload } };
    case UPDATE_PACKAGE_TYPES:
      return { ...assets, packageTypes: payload };
    case FETCH_ASSET_TYPES:
      return { ...assets, assetTypes: payload };
    case SET_APP:
      localStorage.setItem('app-name', payload.name);
      return { ...assets, appName: payload.name };
    case FETCH_ONBOARD_PROGRESS:
      return { ...assets, onBoardProgress: payload };
    case FETCH_ONBOARD_COMPLETED:
      return { ...assets, onBoardCompleted: payload };
    case UPDATE_ASSET_ITEM:
      data = assets.onboarding.assetDetails.map((d, idx) => {
        if (payload.data.serialNumber === d.serialNumber) {
          return payload.data;
        }
        return d;
      });
      let updateData = { ...assets.onboarding, assetDetails: data };
      return { ...assets, onboarding: updateData };
    case DELETE_ASSET_ITEM:
      data = assets.onboarding.assetDetails.filter((d, idx) => {
        return payload.data.serialNumber !== d.serialNumber;
      });
      let updateDataa = { ...assets.onboarding, assetDetails: data };
      return { ...assets, onboarding: updateDataa };
    case UPDATE_SURVEY:
      data = { ...assets.onboarding, survey: payload };
      return { ...assets, onboarding: data };
    case UPDATE_STICKER:
      data = { ...assets.onboarding, sticker: payload };
      return { ...assets, onboarding: data };
    case CREATE_SURVEY:
      return {
        ...assets,
        onboarding: {
          assetDetails: [],
        },
      };
    case ATTACH_SURVEY_ASSET:
      return {
        ...assets,
        onboarding: {
          assetDetails: [],
        },
      };
    case FETCH_SURVEYS:
      return { ...assets, surveys: payload };
    case ATTACH_WAREHOUSE:
      let update = assets.onboarding.assetDetails.map(d => {
        d.warehouseId = payload.id;
        return d;
      });
      return { ...assets, onboarding: { ...assets.onboarding, assetDetails: update } };
    case ATTACH_SURVEY_ID:
      let update2 = assets.onboarding.assetDetails.map(d => {
        d.surveyId = payload.id;
        return d;
      });
      return { ...assets, onboarding: { ...assets.onboarding, assetDetails: update2 } };
    case FETCH_STOCK_COUNT_HISTORY:
      return { ...assets, stockCount: { ...assets.stockCount, history: payload } };
    case FETCH_STOCK_COUNT_BATCH:
      return { ...assets, stockCount: { ...assets.stockCount, currentBatch: payload } };
    case CREATE_STOCK_COUNT:
      return { ...assets, stockCount: assets.stockCount };
    case RETRIEVE_RFID_TAGS:
      return { ...assets, rfidTags: payload };
    case FETCH_FINANCES:
      return { ...assets, finances: payload };
    case FETCH_ALARM_LOGS:
      return { ...assets, alarmLogs: payload };
    case FETCH_LOCATION:
      let dataL = {};
      if (payload?.device?.lat) {
        dataL.lat = payload?.device?.lat;
        dataL.lng = payload?.device?.lng;
      }
      if (payload?.asset?.site?.latitude) {
        dataL.siteName = payload?.asset?.site?.name;
        dataL.siteLat = payload?.asset?.site?.latitude;
        dataL.siteLng = payload?.asset?.site?.longitude;
      }
      if (payload?.asset?.warehouse?.latitude) {
        dataL.warehouseName = payload?.asset?.warehouse?.name;
        dataL.warehouseLat = payload?.asset?.warehouse?.latitude;
        dataL.warehouseLng = payload?.asset?.warehouse?.longitude;
      }
      return { ...assets, assetLocation: { path: assets.assetLocation.path?.reverse(), ...dataL } };

    case FETCH_PATH:
      let p = [];
      if (payload && payload.length > 0) {
        p = payload.map(d => {
          return { lat: d.latitude, lng: d.longitude, created_at: d.created_at, updated_at: d.updated_at };
        });
      }
      return { ...assets, assetLocation: { ...assets.assetLocation, path: p?.reverse() } };
    default:
      return assets;
  }
};

export default assetsReducer;
