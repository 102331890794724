import React, { useState, useEffect, useRef } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { MdLock, MdEmail } from 'react-icons/md';
import { FaUser, FaPhoneAlt } from 'react-icons/fa';
import { ImOffice } from 'react-icons/im';
import { FiSearch } from 'react-icons/fi';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { ReactComponent as Upload } from '../../../assets/upload-2.svg';
import { REACT_APP_MAP_API_KEY } from '../../../config';

const libraries = ['places'];

const InputBox = ({
  className,
  type,
  placeholder,
  label,
  labelColor,
  variant,
  name,
  value,
  defaultValue,
  onValueChange,
  onSelectChange,
  onPlaceSelected,
  onFocusChange,
  imgValue,
  onImageChage,
  onRemove,
  idx,
  required,
  readOnly,
}) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [typex, setTypex] = useState();
  const imgRef = useRef();
  useEffect(() => {
    setTypex(type);
  }, [type]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: libraries,
    googleMapsApiKey: REACT_APP_MAP_API_KEY,
  });

  const onAutocompleteLoad = autocomplete => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      if (onPlaceSelected) onPlaceSelected(autocomplete.getPlace());
    }
  };

  return (
    <>
      {!variant && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            className={` p-5 rounded-t-lg border-b-2 border-brand_blue_light bg-white bg-opacity-10 text-gray-300 flex items-center space-x-4 overflow-hidden`}
          >
            {placeholder === 'Company Name' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Address' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Country' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Attendee Name' && <FaUser className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'email' && <MdEmail className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'tel' && <FaPhoneAlt className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'password' && <MdLock className={`opacity-50 flex-shrink-0 text-lg`} />}
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              name={name}
              defaultValue={defaultValue ? defaultValue : ''}
              onChange={onValueChange}
              placeholder={placeholder}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'password' && (
              <AiFillEyeInvisible onClick={() => setTypex('textx')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />
            )}
            {typex === 'textx' && <AiFillEye onClick={() => setTypex('password')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 'controlled' && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            className={` p-5 rounded-t-lg border-b-2 border-brand_blue_light bg-white bg-opacity-10 text-gray-300 flex items-center space-x-4 overflow-hidden`}
          >
            {placeholder === 'Company Name' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Address' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Country' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Attendee Name' && <FaUser className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'email' && <MdEmail className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'tel' && <FaPhoneAlt className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'password' && <MdLock className={`opacity-50 flex-shrink-0 text-lg`} />}
            <input
              style={{ WebkitTextFillColor: '#d5d5d5' }}
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              name={name}
              value={value ? value : ''}
              onChange={onValueChange}
              placeholder={placeholder}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'password' && (
              <AiFillEyeInvisible onClick={() => setTypex('textx')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />
            )}
            {typex === 'textx' && <AiFillEye onClick={() => setTypex('password')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 1 && (
        <div className={`${className} w-full p-6 rounded-md shadow bg-white text-gray-600 flex items-center space-x-4 relative`}>
          <div onClick={() => imgRef?.current?.click()} className='w-12 h-12 text-brand_blue cursor-pointer'>
            {imgValue ? <img className='w-full h-full object-cover' src={URL.createObjectURL(imgValue)} alt='' /> : <Upload className='w-full h-full' />}
            <input ref={imgRef} className='hidden' onChange={e => onImageChage(e, idx)} type='file' accept='image/*' />
          </div>
          {idx !== 0 && (
            <div
              onClick={() => onRemove(idx)}
              className='absolute -right-2 -top-2 w-6 text-sm h-6 bg-white shadow text-brand_blue text-center rounded-full flex justify-center items-center cursor-pointer font-medium'
            >
              x
            </div>
          )}
          <input
            name={name}
            onChange={e => onValueChange(e, idx)}
            value={value}
            className={`bg-transparent focus:outline-none w-full h-full`}
            spellCheck={false}
            placeholder={placeholder}
            required={required}
          />
          <div className='border border-gray-200 px-4 py-2 rounded'>
            <select name='level' onChange={e => onSelectChange(e, idx)} className='focus:outline-none cursor-pointer' placeholder='Package Level'>
              <option disabled>Package Level</option>
              {['Primary', 'Secondary', 'Tertiary'].map(d => {
                return <option key={d}>{d}</option>;
              })}
            </select>
          </div>
        </div>
      )}
      {variant === 2 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            style={{ borderColor: '#D7EEFF' }}
            className={`p-4 rounded-t-lg border-b-2 bg-gray-400 bg-opacity-10 text-gray-700 flex items-center space-x-4 overflow-hidden`}
          >
            {placeholder === 'Company Name' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Address' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Country' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Attendee Name' && <FaUser className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'email' && <MdEmail className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'tel' && <FaPhoneAlt className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'password' && <MdLock className={`opacity-50 flex-shrink-0 text-lg`} />}
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              value={value ? value : ''}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'password' && (
              <AiFillEyeInvisible onClick={() => setTypex('textx')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />
            )}
            {typex === 'textx' && <AiFillEye onClick={() => setTypex('password')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 3 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div className={`p-4 rounded-md border bg-white border-gray-300 text-gray-700 flex items-center space-x-4 overflow-hidden`}>
            {placeholder === 'Company Name' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Address' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Country' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Attendee Name' && <FaUser className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'email' && <MdEmail className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'tel' && <FaPhoneAlt className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'password' && <MdLock className={`opacity-50 flex-shrink-0 text-lg`} />}
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              value={value ? value : ''}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'password' && (
              <AiFillEyeInvisible onClick={() => setTypex('textx')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />
            )}
            {typex === 'textx' && <AiFillEye onClick={() => setTypex('password')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 4 && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div className={`p-4 rounded-md bg-gray-100 text-gray-700 flex items-center space-x-4 overflow-hidden`}>
            {placeholder === 'Company Name' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Address' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Country' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Attendee Name' && <FaUser className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'email' && <MdEmail className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'tel' && <FaPhoneAlt className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'password' && <MdLock className={`opacity-50 flex-shrink-0 text-lg`} />}
            <input
              id={label ? label : placeholder}
              className={` bg-transparent focus:outline-none  w-full`}
              spellCheck={false}
              type={typex}
              placeholder={placeholder}
              name={name}
              onChange={onValueChange}
              value={value ? value : ''}
              required={required}
              readOnly={readOnly || false}
              onKeyDown={
                type === 'number' ? evt => (evt.key === 'e' || evt.key === 'E' || evt.key === '+' || evt.key === '-') && evt.preventDefault() : () => {}
              }
            />
            {typex === 'password' && (
              <AiFillEyeInvisible onClick={() => setTypex('textx')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />
            )}
            {typex === 'textx' && <AiFillEye onClick={() => setTypex('password')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />}
            {typex === 'search' && <FiSearch className={`opacity-50 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
      {variant === 'places' && (
        <div className={`${className} space-y-2 w-full`}>
          {label && (
            <label className={`font-semibold ${labelColor}`} htmlFor={label}>
              {label}
            </label>
          )}
          <div
            className={` p-5 rounded-t-lg border-b-2 border-brand_blue_light bg-white bg-opacity-10 text-gray-300 flex items-center space-x-4 overflow-hidden`}
          >
            {placeholder === 'Company Name' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Address' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Company Country' && <ImOffice className={`opacity-50 flex-shrink-0 text-lg`} />}
            {placeholder === 'Attendee Name' && <FaUser className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'email' && <MdEmail className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'tel' && <FaPhoneAlt className={`opacity-50 flex-shrink-0 text-lg`} />}
            {type === 'password' && <MdLock className={`opacity-50 flex-shrink-0 text-lg`} />}
            {isLoaded && (
              <Autocomplete className='w-full' onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                <input
                  placeholder={placeholder}
                  id={label ? label : placeholder}
                  className={` bg-transparent focus:outline-none  w-full`}
                  spellCheck={false}
                  type={typex}
                  autoComplete='new-password'
                  name={name}
                  value={value ? value : ''}
                  onChange={onValueChange}
                  onBlur={onFocusChange}
                  required={required}
                />
              </Autocomplete>
            )}
            {typex === 'password' && (
              <AiFillEyeInvisible onClick={() => setTypex('textx')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />
            )}
            {typex === 'textx' && <AiFillEye onClick={() => setTypex('password')} className={`cursor-pointer opacity-90 select-none flex-shrink-0 text-lg`} />}
          </div>
        </div>
      )}
    </>
  );
};

export default InputBox;
