import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../components/fragments/heading';
import SelectBox from '../../../components/fragments/select-box';
import Button from '../../../components/fragments/button';
import AssetTable from '../../../components/fragments/asset-table/asset-table';
import Logo from '../../../components/fragments/logo/logo';

import { AssetActions } from '../../../states/actions';

const UploadedAssets = () => {
  const assets = useSelector(state => state.assets);
  const dispatch = useDispatch();
  const warehouses = useSelector(state => state.warehouses);
  const [warehouseList, setWarehouseList] = useState([]);
  const [selectedWId, setSelectedWId] = useState();
  const history = useHistory();
  const [currentItems, setCurrentItems] = useState(null);

  useEffect(() => {
    if (selectedWId) {
      dispatch(AssetActions.attachWarehouse(selectedWId));
    }
    // eslint-disable-next-line
  }, [selectedWId]);

  useEffect(() => {
    if (warehouses) {
      let data = warehouses.reduce((p, n) => {
        return [...p, n.name];
      }, []);
      setWarehouseList(data);
    }
  }, [warehouses]);

  useEffect(() => {
    if (assets?.onboarding?.assetDetails?.length > 0) {
      setCurrentItems(assets.onboarding.assetDetails);
    } else {
      history.push('/im/onboarding/get-started');
    } // eslint-disable-next-line
  }, [assets]);

  const onContinue = () => {
    history.push('/im/onboarding/onboard-summary');
  };

  const onWarehouseSelect = e => {
    e.preventDefault();
    let w = warehouses.find(f => e.target.value === f.name);
    setSelectedWId(w.id);
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className={` px-4 sm:px-6 h-full`}>
        <div className={`py-8 sm:py-16 space-y-12 w-full`}>
          <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-between items-start`}>
            <div>
              <Heading className={`text-brand_blue`} title={`Uploaded CSV`} size={2} />
              <SelectBox
                className={`mt-12`}
                labelColor={`text-brand_blue`}
                label={`Attach warehouse to assets`}
                placeholder={`Select warehouse`}
                options={warehouseList}
                onValueChange={onWarehouseSelect}
                variant={3}
              />
            </div>
            {/* <Button className={``} text={`Upload More Data`} size={2} onClick={() => history.push('/im/onboarding/get-started')} /> */}
          </div>
          {currentItems && currentItems.some(d => d.packageLevel === 'Primary') && (
            <>
              <Heading className={`text-brand_blue font-medium`} title={`Primary Level Assets`} size={2} />
              <AssetTable data={currentItems} variant={'primary'} appName={assets.appName} />
            </>
          )}
          {currentItems && currentItems.some(d => d.packageLevel === 'Secondary') && (
            <>
              <Heading className={`text-brand_blue font-medium`} title={`Secondary Level Assets`} size={2} />
              <AssetTable data={currentItems} variant={'secondary'} appName={assets.appName} />
            </>
          )}
          {currentItems && currentItems.some(d => d.packageLevel === 'Tertiary') && (
            <>
              <Heading className={`text-brand_blue font-medium`} title={`Tertiary Level Assets`} size={2} />
              <AssetTable data={currentItems} variant={'tertiary'} appName={assets.appName} />
            </>
          )}
          <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
            <Button text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
            <Button text={`Continue`} onClick={onContinue} />
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default UploadedAssets;
