import {
  ONBOARD_ASSETS,
  FETCH_ASSETS,
  UPDATE_ASSETS,
  UPDATE_PACKAGE_TYPES,
  UPDATE_ONBOARD_PHOTO,
  UPDATE_ASSET_ITEM,
  DELETE_ASSET_ITEM,
  MOVE_ASSETS_TO_WAREHOUSE,
  MOVE_ASSETS,
  UPDATE_SURVEY,
  UPDATE_STICKER,
  RESET_ONBOARD,
  CREATE_SURVEY,
  UPDATE_EXISTING_SURVEY,
  FETCH_SURVEYS,
  ATTACH_SURVEY_ASSET,
  ATTACH_WAREHOUSE,
  ATTACH_SURVEY_ID,
  FETCH_ASSET_TYPES,
  ENABLE_SURVEY,
  DISABLE_SURVEY,
  FETCH_ONBOARD_PROGRESS,
  FETCH_ONBOARD_COMPLETED,
  SET_APP,
  FETCH_STOCK_COUNT_HISTORY,
  FETCH_STOCK_COUNT_BATCH,
  CREATE_STOCK_COUNT,
  RETRIEVE_RFID_TAGS,
  DISABLE_RFID_TAGS,
  FETCH_FINANCES,
  FETCH_ALARM_LOGS,
  FETCH_LOCATION,
  FETCH_PATH,
  UPDATE_ITEM_MAC_ADDRESS,
  UPDATE_NOTIFY_STATUS
} from '../type';

import { notify, loading } from './response';
import { AssetService } from '../../services';
import { UserActions, SurveyActions } from '../actions';

export const onboardAssets = data => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.onboardAsset(data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: ONBOARD_ASSETS,
    });

    dispatch(fetchAssets(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchAssets = (perPage, page) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAsstes(perPage, page);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ASSETS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const fetchAssetsBySite = id => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAsstesBySite(id);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ASSETS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const fetchAssetsByWarehouse = id => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAsstesByWarehouse(id);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ASSETS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const moveAssetsToWarehouse = (id, data) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.moveAssetsToWarehouse(id, data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: MOVE_ASSETS_TO_WAREHOUSE,
    });

    dispatch(fetchAssets(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const moveAssets = (id, data) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.moveAssetsToSite(id, data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: MOVE_ASSETS,
    });

    dispatch(fetchAssets(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const updateAssets = data => async dispatch => {
  dispatch({
    type: UPDATE_ASSETS,
    payload: data,
  });
};

export const updateOnboardPhoto = data => async dispatch => {
  dispatch({
    type: UPDATE_ONBOARD_PHOTO,
    payload: data,
  });
};

export const updatePackageTypes = data => async dispatch => {
  dispatch({
    type: UPDATE_PACKAGE_TYPES,
    payload: data,
  });
};

export const resetOnboard = () => async dispatch => {
  dispatch({
    type: RESET_ONBOARD,
  });
};

export const updateItemMacAddress = data => async dispatch => {
  dispatch({
    type: UPDATE_ITEM_MAC_ADDRESS,
    payload: data,
  });
};

export const updateSurvey = data => async dispatch => {
  dispatch({
    type: UPDATE_SURVEY,
    payload: data,
  });
};

export const updateSticker = data => async dispatch => {
  dispatch({
    type: UPDATE_STICKER,
    payload: data,
  });
};

export const updateItem = (idx, data) => async dispatch => {
  dispatch({
    type: UPDATE_ASSET_ITEM,
    payload: { id: idx, data },
  });
};

export const deleteItem = data => async dispatch => {
  dispatch({
    type: DELETE_ASSET_ITEM,
    payload: { data },
  });
};

export const attachWarehouse = id => async dispatch => {
  dispatch({
    type: ATTACH_WAREHOUSE,
    payload: { id },
  });
};

export const attachSurvey = id => async dispatch => {
  dispatch({
    type: ATTACH_SURVEY_ID,
    payload: { id },
  });
};

export const createSurveys = data => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.createSurvey(data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: CREATE_SURVEY,
      payload: res.data.data,
    });
    dispatch(SurveyActions.resetSurvey());
    dispatch(fetchSurveys(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchSurveys = (perPage, page) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveSurveys(perPage, page);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_SURVEYS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const updateExistingSurvey = (id, data) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.updateSurvey(id, data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: UPDATE_EXISTING_SURVEY,
    });

    dispatch(fetchSurveys(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const attachSurveyAssets = (id, data) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.attachSurveyToAssets(id, data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: ATTACH_SURVEY_ASSET,
      payload: res.data.data,
    });

    dispatch(fetchSurveys(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const enableSurvey = id => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.enableSurvey(id);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: ENABLE_SURVEY,
    });

    dispatch(fetchSurveys(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const disableSurvey = id => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.disableSurvey(id);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: DISABLE_SURVEY,
    });

    dispatch(fetchSurveys(5, 1));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchAssetTypes = (perPage, page) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAssetTypes(perPage, page);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ASSET_TYPES,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const fetchOnboardProgress = (perPage, page) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveOnboardProgress(perPage, page);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ONBOARD_PROGRESS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const fetchOnboardCompleted = id => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveOnboardCompleted(id);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ONBOARD_COMPLETED,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const setApp = name => async dispatch => {
  dispatch({
    type: SET_APP,
    payload: { name },
  });
};

export const fetchStockCountHistory = () => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveStockCountHistory();

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_STOCK_COUNT_HISTORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const fetchStockCountBatch = batchId => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveStockCountBatch(batchId);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_STOCK_COUNT_BATCH,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const createStockCount = data => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.createStockCount(data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: CREATE_STOCK_COUNT,
    });

    dispatch(fetchStockCountHistory());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchRfidTags = () => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveRfidTags();

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: RETRIEVE_RFID_TAGS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const assignRfidTag = (id, macAddress) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.assignRfidTag(id, macAddress);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    // dispatch({
    //   type: DISABLE_RFID_TAGS,
    // });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const disableRfidTags = (oldRfid, newRfid) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.disableRfidTags(oldRfid, newRfid);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: DISABLE_RFID_TAGS,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchFinances = (type, id) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveFinances(type, id);

    dispatch({
      type: FETCH_FINANCES,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};


export const fetchAlarmLogs = (perPage, page) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAlarmLogs(perPage, page);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ALARM_LOGS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const updateNotifyStatus = (data, perPage, currentPage) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.updateNotifyStatus(data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: UPDATE_NOTIFY_STATUS,
    });

    dispatch(fetchAlarmLogs(perPage, currentPage));
    return Promise.resolve(res);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchAssetLocation = id => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAssetLocation(id);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_LOCATION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const fetchAssetPath = macAddress => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AssetService.retrieveAssetPath(macAddress);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_PATH,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

const AssetActions = {
  onboardAssets,
  fetchAssets,
  fetchAssetsBySite,
  fetchAssetsByWarehouse,
  updatePackageTypes,
  updateOnboardPhoto,
  updateAssets,
  updateItemMacAddress,
  deleteItem,
  moveAssetsToWarehouse,
  moveAssets,
  updateSurvey,
  updateSticker,
  updateItem,
  resetOnboard,
  createSurveys,
  updateExistingSurvey,
  fetchSurveys,
  attachSurveyAssets,
  enableSurvey,
  disableSurvey,
  attachWarehouse,
  attachSurvey,
  fetchAssetTypes,
  fetchOnboardProgress,
  fetchOnboardCompleted,
  setApp,
  fetchStockCountHistory,
  fetchStockCountBatch,
  createStockCount,
  fetchRfidTags,
  disableRfidTags,
  assignRfidTag,
  fetchFinances,
  fetchAlarmLogs,
  updateNotifyStatus,
  fetchAssetLocation,
  fetchAssetPath,
};

export default AssetActions;
