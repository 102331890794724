import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Switch } from '@headlessui/react';

import { ReactComponent as Checked } from '../../../assets/radio-checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/radio-unchecked.svg';

import { AssetActions, SurveyActions } from '../../../states/actions';

const SurveyTable = ({ data, variant, nameLink, surveyId, setSurveyId }) => {
  const [surveys, setSurveys] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    if (data) {
      setSurveys(data);
    } // eslint-disable-next-line
  }, [data]);

  // const selectAll = () => {
  //   if (allSelected) {
  //     let selected = surveys.map(survey => {
  //       survey.selected = false;
  //       return survey;
  //     });
  //     setAllSelected(false);
  //     setSurveys(selected);
  //   } else {
  //     let selected = surveys.map(survey => {
  //       survey.selected = true;
  //       return survey;
  //     });
  //     setAllSelected(true);
  //     setSurveys(selected);
  //   }
  // };

  const selectSurvey = index => {
    // setAllSelected(false);
    let items = surveys.map((item, idx) => {
      if (index === idx) {
        item.selected = !item.selected;
        if (setSurveyId) {
          if (item.selected) {
            setSurveyId(item.id);
          } else {
            setSurveyId('');
          }
        } else {
          dispatch(SurveyActions.updateSurvey(item));
          history.push(`/field-report/survey/${item.id}/preview-survey`);
        }
      }
      return item;
    });
    setSurveys(items);
  };

  const onItemSave = (survey, index) => {
    survey.selected = false;
    // dispatch(SurveyActions.updateItem(index, survey));
  };

  return (
    <>
      {!variant && (
        <div className={`overflow-auto min-w-full py-6`}>
          <div className={`min-w-max px-6`}>
            <div className={`min-w-max flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium border-b border-gray-200`}>
              <div className={`w-16 flex-shrink-0`}>S/N</div>
              <div className={`w-48 flex-shrink-0`}>Survey Name</div>
              <div className={`w-48 flex-shrink-0`}>Survey Description</div>
              <div className={`w-48 flex-shrink-0`}>Number of Questions</div>
              <div className={`w-40 flex-shrink-0`}>Created Date</div>
              <div className={`flex-1`}></div>
              <div className={`w-20 flex-shrink-0`}>Active</div>
            </div>
          </div>

          <FadeIn className={`min-w-max flex-shrink-0 space-y-6 pt-6`}>
            {surveys &&
              surveys.map((item, idx) => {
                return <Item key={idx} survey={item} idx={idx} selectSurvey={selectSurvey} onSave={onItemSave} nameLink={nameLink} surveyId={surveyId} />;
              })}
          </FadeIn>
        </div>
      )}
    </>
  );
};

const Item = ({ survey, idx, selectSurvey, onSave, nameLink, surveyId }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState();
  const history = useHistory();

  useEffect(() => {
    if (survey) {
      setItem(survey);
    }
  }, [survey]);

  const handleNameClick = () => {
    if (nameLink) {
      history.push(`/survey-rewards/${idx}`);
    } else {
      selectSurvey(idx);
    }
  };

  const handleSwitch = () => {
    if (item) {
      if (item.isActive) {
        dispatch(AssetActions.disableSurvey(item.id));
      } else {
        dispatch(AssetActions.enableSurvey(item.id));
      }
    }
  };

  return (
    <div
      className={`min-w-max flex-shrink-0 px-6 rounded-2xl ${item?.selected && surveyId && `bg-white border border-brand_blue`} ${
        !item?.selected && surveyId && surveyId !== item?.id ? `pointer-events-none opacity-50 bg-gray-100` : `bg-gray-100`
      }`}
    >
      <div onClick={() => selectSurvey(idx)} className={`w-full cursor-pointer flex flex-shrink-0 items-center justify-start space-x-6 py-6 font-medium`}>
        {surveyId && <div className={`w-6 cursor-pointer`}>{item?.selected ? <Checked /> : <Unchecked />}</div>}
        <div className={`w-16 flex-shrink-0 overflow-auto`}>{idx + 1}</div>
        <div className={`text-brand_blue text-lg w-48 flex-shrink-0 overflow-auto`}>
          <span className={`cursor-pointer`} onClick={handleNameClick}>
            {item?.title}
          </span>
        </div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.content}</div>
        <div className={`w-48 flex-shrink-0 overflow-auto`}>{item?.numberOfQuestions}</div>
        <div className={`w-40 flex-shrink-0 overflow-auto`}>{new Date(item?.created_at).toDateString()}</div>
        <div className={`flex-1`}></div>
        <div className={`w-20 flex-shrink-0 `}>
          <Switch
            checked={item?.isActive}
            onChange={handleSwitch}
            className={`${item?.isActive ? 'bg-brand_blue' : 'bg-gray-200'} relative inline-flex items-center h-6 rounded-full w-11`}
          >
            <span className={`${item?.isActive ? 'translate-x-6' : 'translate-x-1'} inline-block w-4 h-4 transform bg-white rounded-full`} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default SurveyTable;
