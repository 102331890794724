import { FETCH_USER, UPDATE_USER, SIGNOUT } from '../type';
import { User } from '../../models';

const initialState = new User();

const userReducer = (user = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER:
      return { ...user, ...payload };
    case UPDATE_USER:
      return { ...user, ...payload };
    case SIGNOUT:
      localStorage.removeItem('chekkit-act');
      //fjsl
      return initialState;
    default:
      return user;
  }
};

export default userReducer;
