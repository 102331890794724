import http from '../http';

export const createWarehouse = async data => {
  return http().post('/warehouse', data);
};

export const fetchWarehouses = async (perPage, page) => {
  return http().get(`/warehouse?perPage=${perPage}&page=${page}`);
};

const WarehouseService = {
  createWarehouse,
  fetchWarehouses,
};

export default WarehouseService;
