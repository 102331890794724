import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
// import { useDispatch } from 'react-redux';

import Scanner from '../../../../components/fragments/scanner';

// import { AssetActions } from '../../../../states/actions';

const Counter = () => {
  // const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [obj, setObj] = useState();

  useEffect(() => {
    if (obj) {
      if (data.length > 0) {
        let existingItem = data.some(d => obj.name === d.name && obj.packageLevel === d.packageLevel);
        let uniqueSn = false;
        if (existingItem) {
          data.forEach(d => {
            if (d.name === obj.name && obj.packageLevel === d.packageLevel) {
              uniqueSn = d.ids.every(id => {
                return obj.sn !== id;
              });
            }
          });

          if (!uniqueSn) return;

          setData(
            data.map(d => {
              if (d.name === obj.name && obj.packageLevel === d.packageLevel) {
                d.count = d.count + 1;
                d.ids = [...d.ids, obj.sn];
              }
              return d;
            }),
          );
        } else {
          setData([...data, { ...obj, count: 1, ids: [obj.sn] }]);
        }
      } else {
        setData([{ ...obj, count: 1, ids: [obj.sn] }]);
      }
    }
    // eslint-disable-next-line
  }, [obj]);

  const onStop = () => {
    if (data.length > 0) {
      // dispatch();
    }
  };

  return (
    <div className='flex flex-col xl:flex-row h-full w-full'>
      <Scanner onScanned={setObj} onStop={onStop} />

      <FadeIn className={`bg-gray-100 w-full xl:w-96 p-6 py-16`}>
        <div className='mb-8 space-y-2'>
          <div className='font-medium text-lg'>Scan Output</div>
          <div className='text-gray-600'>Your scanned items will show here</div>
        </div>
        <div className='divide-y divide-gray-200'>
          {data &&
            data.length > 0 &&
            data.map((d, i) => {
              return (
                <div className='py-4 flex justify-between items-center space-x-4' key={i}>
                  <div className=''>
                    <div className='text-lg text-brand_blue font-semibold'>{d.name}</div>
                    <div className='font-medium text-gray-600'>{d.packageLevel}</div>
                  </div>
                  <div className='space-x-1'>
                    <span className='font-semibold text-gray-500'>x</span>
                    <span className='text-brand_blue'>{d.count}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </FadeIn>
    </div>
  );
};

export default Counter;
