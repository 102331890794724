// app
export const SET_APP = 'SET_APP';

// filter
export const SET_FILTER = 'SET_FILTER';

// products
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';

// user
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SIGNOUT = 'SIGNOUT';

// Warehouse
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';
export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES';

// Site
export const CREATE_SITE = 'CREATE_SITE';
export const FETCH_SITES = 'FETCH_SITES';

// Hardware
export const CREATE_HARDWARE = 'CREATE_HARDWARE';
export const ADD_RFID_PINS = 'ADD_RFID_PINS';
export const REGISTER_RFID = 'REGISTER_RFID';
export const FETCH_HARDWARES = 'FETCH_HARDWARES';

// Asset
export const ONBOARD_ASSETS = 'ONBOARD_ASSETS';
export const RESET_ONBOARD = 'RESET_ONBOARD';
export const FETCH_ASSETS = 'FETCH_ASSETS';
export const MOVE_ASSETS = 'MOVE_ASSETS';
export const MOVE_ASSETS_TO_WAREHOUSE = 'MOVE_ASSETS_TO_WAREHOUSE';
export const UPDATE_ASSETS = 'UPDATE_ASSETS';
export const UPDATE_PACKAGE_TYPES = 'UPDATE_PACKAGE_TYPES';
export const UPDATE_ONBOARD_PHOTO = 'UPDATE_ONBOARD_PHOTO';
export const UPDATE_ASSET_ITEM = 'UPDATE_ASSET_ITEM';
export const DELETE_ASSET_ITEM = 'DELETE_ASSET_ITEM';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const ENABLE_SURVEY = 'ENABLE_SURVEY';
export const DISABLE_SURVEY = 'DISABLE_SURVEY';
export const UPDATE_STICKER = 'UPDATE_STICKER';
export const ATTACH_WAREHOUSE = 'ATTACH_WAREHOUSE';
export const ATTACH_SURVEY_ID = 'ATTACH_SURVEY_ID';
export const FETCH_ASSET_TYPES = 'FETCH_ASSET_TYPES';
export const FETCH_ONBOARD_PROGRESS = 'FETCH_ONBOARD_PROGRESS';
export const FETCH_ONBOARD_COMPLETED = 'FETCH_ONBOARD_COMPLETED';
export const FETCH_STOCK_COUNT_HISTORY = 'FETCH_STOCK_COUNT_HISTORY';
export const FETCH_STOCK_COUNT_BATCH = 'FETCH_STOCK_COUNT_BATCH';
export const CREATE_STOCK_COUNT = 'CREATE_STOCK_COUNT';
export const RETRIEVE_RFID_TAGS = 'RETRIEVE_RFID_TAGS';
export const DISABLE_RFID_TAGS = 'DISABLE_RFID_TAGS';
export const FETCH_FINANCES = 'FETCH_FINANCES';
export const FETCH_ALARM_LOGS = 'FETCH_ALARM_LOGS';
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_PATH = 'FETCH_PATH';
export const UPDATE_ITEM_MAC_ADDRESS = 'UPDATE_ITEM_MAC_ADDRESS';
export const UPDATE_NOTIFY_STATUS = 'UPDATE_NOTIFY_STATUS';

// survey
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_EXISTING_SURVEY = 'UPDATE_EXISTING_SURVEY';
export const FETCH_SURVEYS = 'FETCH_SURVEYS';
export const ATTACH_SURVEY_ASSET = 'ATTACH_SURVEY_ASSET';

// Plan
export const SUBSCRIBE_TO_PLAN = 'SUBSCRIBE_TO_PLAN';
export const FETCH_PLANS = 'FETCH_PLANS';

// Question
export const SAVE_SURVEY = 'SAVE_QUESTIONS';
export const RESET_SURVEY = 'RESET_SURVEY';
export const ADD_META = 'ADD_META';
export const ADD_QUESTION = 'ADD_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';

// response
export const NOTIFY = 'NOTIFY';
export const LOADING = 'LOADING';

// analytics
export const FETCH_WAREHOUSES_WITH_MOST_ASSETS = 'FETCH_WAREHOUSES_WITH_MOST_ASSETS';
export const FETCH_DAYS_WITH_ASSETS = 'FETCH_DAYS_WITH_ASSETS';
export const FETCH_SURVEY_PERFORMANCE = 'FETCH_SURVEY_PERFORMANCE';
export const FETCH_STOCK_COUNT_REPORTS = 'FETCH_STOCK_COUNT_REPORTS';
export const FETCH_ASSETS_ANALYTICS = 'FETCH_ASSETS_ANALYTICS';

// notifications
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const MARK_AS_READ = 'MARK_AS_READ';
