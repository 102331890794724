import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';

import Placeholder from '../../../assets/placeholder.png';

import { REACT_APP_MAP_API_KEY } from '../../../config';

// import Button from '../../fragments/button';

const libraries = ['places'];

const Map = ({ warehouses, warehouse, assetsInWarehouse, variant, link }) => {
  const [data, setData] = useState();
  const [selected, setSelected] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [center, setCenter] = useState({ lat: 6.5244, lng: 3.3792 });
  const [map, setMap] = useState(null);
  const [isBoundSet, setIsBoundSet] = useState(false);

  useEffect(() => {
    if (selected && assetsInWarehouse && assetsInWarehouse.length > 0) {
      let asset = assetsInWarehouse.find(d => d.id === selected);
      if (asset) setSelectedAsset(asset);
    } else {
      setSelectedAsset(null);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      setData(
        warehouses.map(d => {
          d.activeMarker = false;
          d.position = { lat: d.latitude, lng: d.longitude };
          return d;
        }),
      );
    } else if (warehouse) {
      setData({ ...warehouse, activeMarker: false, position: { lat: warehouse.latitude, lng: warehouse.longitude } });
    } else if (assetsInWarehouse && assetsInWarehouse.length > 0) {
      setData(
        assetsInWarehouse.map(d => {
          d.activeMarker = false;
          d.position = { lat: d.latitude, lng: d.longitude };
          return d;
        }),
      );
    }
  }, [warehouses, warehouse, assetsInWarehouse]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: libraries,
    googleMapsApiKey: REACT_APP_MAP_API_KEY,
  });

  const onLoad = React.useCallback(
    function callback(map) {
      if (data && data.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        data.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
        setCenter(data[0].position);
      } else if (warehouse && data.position) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(data.position);
        map.fitBounds(bounds);
        setCenter(data.position);
      }
      setMap(map);
    },
    // eslint-disable-next-line
    [data],
  );

  useEffect(() => {
    if (map && data && data.length > 0 && !isBoundSet) {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
      if (data.some(d => d.live)) {
        setIsBoundSet(true);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  // useEffect(() => {
  //   if (assetsInWarehouse && assetsInWarehouse.length > 0) {
  //     setInterval(() => {
  //       setData(data =>
  //         data.map((marker, i) => {
  //           if (i === 0) {
  //             marker.position = { lat: marker.position.lat + 0.002, lng: marker.position.lng + 0.002 };
  //           }
  //           return marker;
  //         }),
  //       );
  //     }, 1000);
  //   }
  // }, [assetsInWarehouse]);

  const handleActiveMarker = marker => {
    if (data && data.length > 0) {
      let lt = data.map(d => {
        if (d.id === marker) {
          d.activeMarker = true;
        } else {
          d.activeMarker = false;
        }
        return d;
      });
      setData(lt);
    } else if (data.position) {
      setData({ ...data, activeMarker: !data.activeMarker });
    }
  };

  const setActiveMarkerNone = () => {
    if (data && data.length > 0) {
      let lt = data.map(d => {
        d.activeMarker = false;
        return d;
      });
      setData(lt);
    } else if (data.position) {
      setData({ ...data, activeMarker: false });
    }
  };

  return isLoaded ? (
    <>
      {variant === 1 && (
        <div style={{ height: '600px' }} className={`bg-gray-200 rounded-3xl w-full overflow-hidden relative`}>
          <GoogleMap
            onClick={() => {
              setSelected(null);
              setActiveMarkerNone();
            }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={center}
            zoom={10}
            onLoad={onLoad}
            options={{
              // disableDefaultUI: true,
              // mapTypeControl: false,
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
                // {
                //   featureType: 'transit',
                //   elementType: 'labels.icon',
                //   stylers: [{ visibility: 'off' }],
                // },
              ],
            }}
          >
            {data && data.length > 0 && (
              <MarkerClusterer>
                {clusterer =>
                  data.map(d => (
                    <Marker
                      key={d.id}
                      clusterer={clusterer}
                      animation={window.google.maps.Animation.DROP}
                      // draggable={true}
                      position={d.position}
                      onClick={() => {
                        setSelected(d.id);
                        setCenter(d.position);
                        handleActiveMarker(d.id);
                      }}
                      zIndex={d.activeMarker || selected === d.id ? 2 : 1}
                      onMouseOver={() => {
                        if (selected && selected !== d.id) setSelected(null);
                        handleActiveMarker(d.id);
                      }}
                      // onMouseOut={() => setActiveMarkerNone()}
                      icon={{
                        path: 'M0.375 10.8962C0.375 5.14639 5.17985 0.499023 10.9918 0.499023C16.8201 0.499023 21.625 5.14639 21.625 10.8962C21.625 13.7936 20.5713 16.4836 18.8369 18.7635C16.9235 21.2784 14.5652 23.4696 11.9107 25.1896C11.3031 25.587 10.7548 25.617 10.0881 25.1896C7.41842 23.4696 5.06011 21.2784 3.16313 18.7635C1.42748 16.4836 0.375 13.7936 0.375 10.8962ZM7.49269 11.2205C7.49269 13.1467 9.06448 14.6616 10.9917 14.6616C12.9202 14.6616 14.5071 13.1467 14.5071 11.2205C14.5071 9.30929 12.9202 7.72058 10.9917 7.72058C9.06448 7.72058 7.49269 9.30929 7.49269 11.2205Z',
                        fillColor: d.activeMarker || selected === d.id ? '#5F7D95' : '#46F7AD',
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: d.activeMarker || selected === d.id ? '#46F7AD' : '#5F7D95',
                        scale: d.activeMarker || selected === d.id ? 1.5 : 1,
                        anchor: new window.google.maps.Point(
                          11, // width
                          28, // height
                        ),
                      }}
                    ></Marker>
                  ))
                }
              </MarkerClusterer>
            )}
            {(!assetsInWarehouse || (assetsInWarehouse && assetsInWarehouse.length === 0)) && data && (
              <MapMarker
                id={data.id}
                position={data.position}
                name={data.name}
                address={data.address}
                state={data.state}
                country={data.country}
                activeMarker={data.activeMarker}
                selected={selected}
                setSelected={setSelected}
                handleActiveMarker={handleActiveMarker}
                setActiveMarkerNone={setActiveMarkerNone}
                setCenter={setCenter}
                link={link}
              />
            )}
          </GoogleMap>
          {selectedAsset && (
            <div className='absolute top-6 right-6 p-6 bg-white shadow rounded-md w-full max-w-sm space-y-4 text-brand_blue'>
              <div className='flex justify-end'>
                <div
                  onClick={() => {
                    setSelected(null);
                  }}
                  className='w-6 h-6 bg-gray-100 text-center rounded-full cursor-pointer'
                >
                  x
                </div>
              </div>
              <div className='text-brand_blue font-semibold'>
                <span>You are viewing: </span>
                <span>{selectedAsset.name}</span>
              </div>
              <img className='h-40 w-full object-cover rounded-md' src={Placeholder} alt='' />
              <div>
                <span>Serial Number </span>
                <span className='font-semibold'>{selectedAsset.serialNumber}</span>
              </div>
              <div>
                <span>Asset Name </span>
                <span className='font-semibold'>{selectedAsset.name}</span>
              </div>
              {/* <div>
                <span>Asset Price </span>
                <span className='font-semibold'>{selectedAsset.price}</span>
              </div> */}
              <div>
                <span>Category </span>
                <span className='font-semibold'>{selectedAsset.category}</span>
              </div>
              <div>
                <span>Batch Number </span>
                <span className='font-semibold'>{selectedAsset.batchNumber}</span>
              </div>
              <div>
                <span>Package Level </span>
                <span className='font-semibold'>{selectedAsset.packageLevel}</span>
              </div>
              {selectedAsset.packageLevel !== 'Primary' && (
                <div>
                  <span>Number of Sub Units </span>
                  <span className='font-semibold'>{selectedAsset.numberOfSubUnits}</span>
                </div>
              )}
              {/* <Button className={'w-full'} text={'Move Asset To Site'} /> */}
            </div>
          )}
        </div>
      )}
      {!variant && (
        <div style={{ height: '600px' }} className={`bg-gray-200 rounded-3xl w-full overflow-hidden`}>
          <GoogleMap
            onClick={() => {
              setSelected(null);
              setActiveMarkerNone();
            }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={center}
            zoom={10}
            onLoad={onLoad}
            options={{
              // disableDefaultUI: true,
              // mapTypeControl: false,
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
                // {
                //   featureType: 'transit',
                //   elementType: 'labels.icon',
                //   stylers: [{ visibility: 'off' }],
                // },
              ],
            }}
          >
            {data &&
              data.length > 0 &&
              data.map(({ id, position, name, address, state, country, activeMarker }) => (
                <MapMarker
                  key={id}
                  id={id}
                  position={position}
                  name={name}
                  address={address}
                  state={state}
                  country={country}
                  activeMarker={activeMarker}
                  selected={selected}
                  setSelected={setSelected}
                  handleActiveMarker={handleActiveMarker}
                  setActiveMarkerNone={setActiveMarkerNone}
                  setCenter={setCenter}
                  link={link}
                />
              ))}
          </GoogleMap>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

const MapMarker = ({
  id,
  position,
  name,
  address,
  state,
  country,
  activeMarker,
  selected,
  setSelected,
  handleActiveMarker,
  setActiveMarkerNone,
  setCenter,
  link,
  variant,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (!link) history.push(`/field-configuration/warehouse/${id}`);
  };

  return (
    <>
      {!variant && (
        <Marker
          animation={window.google.maps.Animation.DROP}
          // draggable={true}
          position={position}
          onClick={() => {
            setSelected(id);
            setCenter(position);
            handleActiveMarker(id);
          }}
          zIndex={activeMarker || selected === id ? 2 : 1}
          onMouseOver={() => {
            if (selected && selected !== id) setSelected(null);
            handleActiveMarker(id);
          }}
          // onMouseOut={() => setActiveMarkerNone()}
          icon={{
            path: 'M0.375 10.8962C0.375 5.14639 5.17985 0.499023 10.9918 0.499023C16.8201 0.499023 21.625 5.14639 21.625 10.8962C21.625 13.7936 20.5713 16.4836 18.8369 18.7635C16.9235 21.2784 14.5652 23.4696 11.9107 25.1896C11.3031 25.587 10.7548 25.617 10.0881 25.1896C7.41842 23.4696 5.06011 21.2784 3.16313 18.7635C1.42748 16.4836 0.375 13.7936 0.375 10.8962ZM7.49269 11.2205C7.49269 13.1467 9.06448 14.6616 10.9917 14.6616C12.9202 14.6616 14.5071 13.1467 14.5071 11.2205C14.5071 9.30929 12.9202 7.72058 10.9917 7.72058C9.06448 7.72058 7.49269 9.30929 7.49269 11.2205Z',
            fillColor: activeMarker || selected === id ? '#5F7D95' : '#46F7AD',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: activeMarker || selected === id ? '#46F7AD' : '#5F7D95',
            scale: activeMarker || selected === id ? 1.5 : 1,
            anchor: new window.google.maps.Point(
              11, // width
              28, // height
            ),
          }}
        >
          {activeMarker || selected === id ? (
            <InfoWindow
              onCloseClick={() => {
                setSelected(null);
                setActiveMarkerNone();
              }}
            >
              <div className={`flex-shrink-0 text-brand_blue w-full min-w-40`}>
                <div className={`font-medium text-lg mb-2`}>Warehouse</div>
                <div className='space-y-2'>
                  <div className={`font-medium`}>
                    <span className='text-gray-500'>{`Name: `}</span>
                    <span>{name}</span>
                  </div>
                  <div className={`font-medium`}>
                    <span className='text-gray-500'>{`State: `}</span>
                    <span>{state}</span>
                  </div>
                  <div className={`font-medium`}>
                    <span className='text-gray-500'>{`Country: `}</span>
                    <span>{country}</span>
                  </div>
                  <div className={`font-medium`}>
                    <span className='text-gray-500'>{`Address: `}</span>
                    <span>{address}</span>
                  </div>
                </div>
                {!link && (
                  <div
                    onClick={handleClick}
                    className={`px-4 py-2 rounded text-brand_blue mt-4 w-full bg-brand_green text-center font-medium cursor-pointer shadow`}
                  >
                    View Details
                  </div>
                )}
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      )}
      {variant && (
        <Marker
          animation={window.google.maps.Animation.DROP}
          draggable={true}
          position={position}
          onClick={() => {
            setSelected(id);
            setCenter(position);
            handleActiveMarker(id);
          }}
          zIndex={activeMarker || selected === id ? 2 : 1}
          onMouseOver={() => {
            if (selected && selected !== id) setSelected(null);
            handleActiveMarker(id);
          }}
          // onMouseOut={() => setActiveMarkerNone()}
          icon={{
            path: 'M0.375 10.8962C0.375 5.14639 5.17985 0.499023 10.9918 0.499023C16.8201 0.499023 21.625 5.14639 21.625 10.8962C21.625 13.7936 20.5713 16.4836 18.8369 18.7635C16.9235 21.2784 14.5652 23.4696 11.9107 25.1896C11.3031 25.587 10.7548 25.617 10.0881 25.1896C7.41842 23.4696 5.06011 21.2784 3.16313 18.7635C1.42748 16.4836 0.375 13.7936 0.375 10.8962ZM7.49269 11.2205C7.49269 13.1467 9.06448 14.6616 10.9917 14.6616C12.9202 14.6616 14.5071 13.1467 14.5071 11.2205C14.5071 9.30929 12.9202 7.72058 10.9917 7.72058C9.06448 7.72058 7.49269 9.30929 7.49269 11.2205Z',
            fillColor: activeMarker || selected === id ? '#5F7D95' : '#46F7AD',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: activeMarker || selected === id ? '#46F7AD' : '#5F7D95',
            scale: activeMarker || selected === id ? 1.5 : 1,
            anchor: new window.google.maps.Point(
              11, // width
              28, // height
            ),
          }}
        ></Marker>
      )}
    </>
  );
};

export default Map;
