import { FETCH_USER, UPDATE_USER, SIGNOUT } from '../type';
import { UserService } from '../../services';
import { notify, loading } from './response';

export const fetchUser = () => async dispatch => {
  dispatch(loading({ loading: true }));

  try {
    const res = await UserService.fetchUser();

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_USER,
      payload: { ...res.data.data, isAuthorized: true },
    });
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const updateUser = data => async dispatch => {
  dispatch({
    type: UPDATE_USER,
    payload: data,
  });
};

export const signOut = () => async dispatch => {
  dispatch({
    type: SIGNOUT,
  });
};

const UserActions = {
  fetchUser,
  updateUser,
  signOut,
};

export default UserActions;
