import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { UserActions } from './states/actions';
// import Loader from './components/fragments/loader';

import { REACT_APP_SUPPLY_CHAIN_URL } from './config';

function AsController() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { token } = useParams();
  const [t, setT] = useState('');

  useEffect(() => {
    if (token && token.length > 30) {
      dispatch(UserActions.updateUser({ token }));
      localStorage.setItem('chekkit-act', token);
    } else if (localStorage.getItem('chekkit-act')) {
      let tk = localStorage.getItem('chekkit-act');
      if (tk) {
        dispatch(UserActions.updateUser({ token: tk }));
        setT(tk);
      }
    } else {
      dispatch(UserActions.updateUser({ token: '', isToken: false }));
      setT('');
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (user?.token) {
      setT(user?.token);
    } else {
      setT('');
    }
    // eslint-disable-next-line
  }, [user?.token]);

  return (
    <div>
      <div>loading</div>
      {token && t && <Redirect to={'/setup/warehouse/ctr/true'} />}
      {!token && user?.token && t && <Redirect to={'/setup/warehouse/ctr/true'} />}
      {!token && user?.isToken === false && !t ? window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self') : <></>}
      {token && user?.isToken === false && !t ? window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self') : <></>}
    </div>
  );
}

export default AsController;
