import http from '../http';

export const fetchUser = async () => {
  return http().get('/users/authenticated');
};

const UserService = {
  fetchUser,
};

export default UserService;
