import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import ImageFadeIn from 'react-image-fade-in';
import bwipjs from 'bwip-js';
import { useSelector, useDispatch } from 'react-redux';

import Placeholder from '../../../../assets/placeholder.png';
import BarcodeImage from '../../../../assets/barcode.png';
// import ChargerImage from '../../../../assets/charger.png';
// import { ReactComponent as Survey } from '../../../../assets/document.svg';
import Button from '../../../../components/fragments/button';
import Heading from '../../../../components/fragments/heading';
import Dialog from '../../../../components/fragments/dialog/dialog';
import { ResponseActions } from '../../../../states/actions';
// import { ReactComponent as Polls } from '../../../../assets/poll.svg';
import pollsvg from '../../../../assets/poll.svg';

import AssetMap from '../../../../components/fragments/asset-map/asset-map';

import { AssetActions } from '../../../../states/actions';

const AllAssets = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const { asset_id } = useParams();
  const assets = useSelector(state => state.assets);
  const { assetLocation } = useSelector(state => state.assets);
  const [asset, setAsset] = useState();
  const [codes, setCodes] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (asset_id && assets && assets.assets.length > 0) {
      assets.assets.forEach(a => {
        if (asset_id === a.id) {
          setAsset(a);
        }
      });
    }

    // eslint-disable-next-line
  }, [asset_id, assets]);

  useEffect(() => {
    if (asset_id) {
      dispatch(AssetActions.fetchAssetLocation(asset_id))
        .then(res => {
          if (res?.data?.asset) {
            if (res?.data?.asset?.macAddress) {
              dispatch(AssetActions.fetchAssetPath(res?.data?.asset?.macAddress));
            }
            setAsset(res?.data?.asset);
          }
        })
        .catch(console.log);
      // setTimeout(() => {
      //   dispatch(AssetActions.fetchAssetLocation(asset_id)).then(res => {
      //   if (res?.data?.asset) {
      //     setAsset(res?.data?.asset);
      //   }
      // }).catch(console.log);
      // }, 1000);
    }
    // eslint-disable-next-line
  }, [asset_id]);

  useEffect(() => {
    if (asset) {
      let valid = asset.stickerId?.value;

      if (!valid) {
        return;
      }

      let c = [asset].map(a => {
        let canvas = document.createElement('canvas');
        bwipjs.toCanvas(canvas, {
          bcid: 'qrcode',
          text: a?.stickerId?.value,
          scale: 5,
          height: 15,
          width: 15,
          includetext: true,
          textxalign: 'center',
        });
        return {
          name: a.name,
          serialNumber: a.serialNumber,
          stickerId: a?.stickerId?.value,
          batchNumber: a.batchNumber,
          barcode: canvas.toDataURL('image/png'),
        };
      });
      setCodes(c);
    }
  }, [asset]);

  // useEffect(() => {
  //   if (codes) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // }, [codes]);

  const printAssetSticker = () => {
    let valid = asset.stickerId?.value;

    if (!valid) {
      dispatch(ResponseActions.notify({ title: 'Error', message: 'Asset does not have a sticker ID', type: 'default', loading: false }));
      return;
    }

    let c = [asset].map(a => {
      let canvas = document.createElement('canvas');
      bwipjs.toCanvas(canvas, {
        bcid: 'qrcode',
        text: a?.stickerId?.value,
        scale: 5,
        height: 15,
        width: 15,
        includetext: true,
        textxalign: 'center',
      });
      return {
        name: a.name,
        serialNumber: a.serialNumber,
        stickerId: a?.stickerId?.value,
        batchNumber: a.batchNumber,
        barcode: canvas.toDataURL('image/png'),
      };
    });
    setCodes(c);
    setOpen(true);
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <div className={`flex items-center space-x-12`}>
          <Heading className={`text-brand_blue font-semibold`} title={`General Information`} />
        </div>
        <div className={`flex items-center space-x-6`}>
          <Button className={``} text={`Print Sticker`} onClick={printAssetSticker} type='secondary' />
          {/* <Button className={``} text={`Track Asset`} onClick={() => history.push(`/assets/detail/${asset_id}/tracking`)} type='secondary' /> */}
          {/* <Button className={``} text={`Check Asset Vitals`} onClick={() => history.push(`/assets/detail/${asset_id}/vitals`)} /> */}
        </div>
      </div>

      {asset && (
        <div className='space-y-4'>
          <div className={`bg-gray-100 rounded-2xl flex flex-col 2xl:flex-row items-center`}>
            <div
              className={`border border-gray-400 rounded-2xl p-6 flex space-y-4 lg:space-x-4 lg:space-y-0 justify-center items-center bg-white flex-1 w-full h-96 flex-col lg:flex-row divide-y lg:divide-y-0 lg:divide-x divide-gray-400`}
            >
              <div className={`h-full w-full flex flex-col justify-center items-center space-y-4`}>
                <div className='space-y-4'>
                  <div className='w-40 h-40'>
                    <ImageFadeIn className='w-full h-full object-cover flex-shrink-0 rounded-2xl' src={Placeholder} alt='' opacityTransition={1} />
                  </div>
                  <div className={`flex items-center space-x-4`}>
                    <span className={`text-gray-400`}>Asset Name</span>
                    <span className={`font-semibold`}>{asset.name}</span>
                  </div>
                  <div className={`flex items-center space-x-4`}>
                    <span className={`text-gray-400`}>Asset Price</span>
                    <span className={`font-semibold`}>{asset.price}</span>
                  </div>
                  <div className={`flex items-center space-x-4`}>
                    <span className={`text-gray-400`}>Category</span>
                    <span className={`font-semibold`}>{asset.category}</span>
                  </div>
                </div>
              </div>
              <div className={`h-full w-full flex flex-col justify-center items-center pt-6 lg:pt-0 lg:pl-6 space-y-4`}>
                <div className='space-y-4'>
                  <div className='h-40 flex items-center space-x-6'>
                    <ImageFadeIn
                      className='w-40 h-40 object-cover flex-shrink-0 rounded-2xl'
                      src={codes[0]?.barcode ? codes[0]?.barcode : BarcodeImage}
                      alt=''
                      opacityTransition={1}
                    />
                    {/* <div className='w-40 h-40 flex flex-col space-y-6 justify-center items-center bg-yellow-100 p-4 rounded-2xl'>
                      <Survey />
                      <div className={`font-semibold underline text-brand_blue cursor-pointer`}>View Survey</div>
                    </div> */}
                  </div>
                  <div className={`flex items-center space-x-4`}>
                    <span className={`text-gray-400`}>Sticker ID</span>
                    <span className={`font-semibold`}>{asset?.stickerId?.value}</span>
                  </div>
                  <div className={`flex items-center space-x-4`}>
                    <span className={`text-gray-400`}>Batch Number</span>
                    <span className={`font-semibold`}>{asset.batchNumber}</span>
                  </div>
                  <div className={`flex items-center space-x-4`}>
                    <span className={`text-gray-400`}>Serial Number</span>
                    <span className={`font-semibold`}>{asset.serialNumber}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`flex flex-col items-center justify-center space-y-4 h-full p-6 px-24`}>
              <span>
                Installed By <span className={`font-bold`}>S.S. Alan</span>
              </span>
              <span>
                Date Installed <span className={`font-bold`}>Apr 20, 2021</span>
              </span>
              <div className={`font-semibold flex items-center justify-between space-x-4`}>
                <Button className={`w-full`} text={`Delete`} type={`secondary`} cx={2} size={2} />
                <Button className={`w-full`} cx={2} text={`Edit`} size={2} />
              </div>
            </div> */}
          </div>
          {asset?.name?.toLowerCase().includes('wire') ||
            asset?.name?.toLowerCase().includes('cable') ||
            asset?.name?.toLowerCase().includes('poll') ||
            asset?.name?.toLowerCase().includes('pole') ? (
            <div style={{ height: '600px' }} className={`bg-gray-200 rounded-xl w-full overflow-hidden relative cursor-pointer`}>
              {/* <Polls className='w-full h-full object-fill' /> */}
              <img className='w-full h-full object-cover' src={pollsvg} alt='' />
            </div>
          ) : (
            <AssetMap location={assetLocation} assetName={asset?.name} serialNumber={asset?.serialNumber} />
          )}
        </div>
      )}
      <Dialog open={open} setOpen={setOpen} data={codes} type='codes' />
    </FadeIn>
  );
};

export default AllAssets;
