import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

import Heading from '../../../../components/fragments/heading';
// import InputBox from '../../../../components/fragments/input-box';
import Button from '../../../../components/fragments/button';
import AssetTable from '../../../../components/fragments/asset-table';
import { AssetActions } from '../../../../states/actions';

const MoveAssets = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const assets = useSelector(state => state.assets);
  const { siteId } = useParams();
  // const [phrase, setPhrase] = useState('');
  const [assetsToMove, setAssetsToMove] = useState([]);
  let itemsPerPage = 5;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [currentList, setCurrentList] = useState();

  useEffect(() => {
    dispatch(AssetActions.setApp('as'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (assets.assets?.length > 0 && list.length > 0) {
      let lt = assets.assets.map(a => {
        let item = '';
        list.forEach(b => {
          if (a.id === b.id) {
            item = b;
          }
        });
        if (item) {
          return item;
        } else {
          return a;
        }
      });
      setCurrentList(lt);
    } else {
      setCurrentList(assets.assets);
    }
    // eslint-disable-next-line
  }, [assets.assets]);

  useEffect(() => {
    if (list?.length) {
      let items = list.reduce((p, n) => {
        if (n.selected) {
          if (n.packageLevel === 'Primary') {
            return [...p, { packageLevel: 'Primary', assetId: n.id }];
          } else {
            if (n.moveDetails.newPackageLevel === 'Primary') {
              return [
                ...p,
                {
                  numberOfMovedUnits: Number(n.moveDetails.unitsToMove),
                  packageLevel: n.packageLevel,
                  assetId: n.id,
                  assetDetails: [
                    {
                      name: n.name,
                      price: n.price,
                      serialNumber: n.serialNumber,
                      batchNumber: n.batchNumber,
                      expiryDate: n.expiryDate,
                      warehouseId: n?.warehouseId,
                      category: n.category,
                      macAddress: n.macAddress,
                      packageLevel: n.moveDetails.newPackageLevel,
                    },
                  ],
                },
              ];
            } else {
              if (n.moveDetails.moveFullPackage) {
                return [...p, { packageLevel: n.packageLevel, assetId: n.id }];
              } else {
                return [
                  ...p,
                  {
                    numberOfMovedUnits: Number(n.moveDetails.unitsToMove),
                    packageLevel: n.packageLevel,
                    assetId: n.id,
                    assetDetails: [
                      {
                        name: n.name,
                        price: n.price,
                        serialNumber: n.serialNumber,
                        batchNumber: n.batchNumber,
                        expiryDate: n.expiryDate,
                        warehouseId: n?.warehouseId,
                        category: n.category,
                        numberOfSubUnits: Number(n.moveDetails.unitsInEach),
                        macAddress: n.macAddress,
                        packageLevel: n.moveDetails.newPackageLevel,
                      },
                    ],
                  },
                ];
              }
            }
          }
        } else {
          return p;
        }
      }, []);
      setAssetsToMove(items);
    }
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    dispatch(AssetActions.fetchAssets(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  // const handleInputChange = event => {
  //   const { value } = event.target;
  //   setPhrase(value);
  // };

  const onSelect = assetList => {
    if (list.length > 0 && assetList?.length > 0) {
      let lt = list.map(a => {
        let item = '';
        assetList.forEach(b => {
          if (a.id === b.id) {
            item = b;
          }
        });
        if (item) {
          return item;
        } else {
          return a;
        }
      });
      assetList.forEach(c => {
        let exist = lt.some(d => d.id === c.id);
        if (!exist) lt = [...lt, c];
      });
      setList(lt);
    } else {
      if (assetList?.length) {
        setList(assetList);
      }
    }
  };

  const moveAssets = () => {
    dispatch(AssetActions.moveAssets(siteId, assetsToMove)).then(() => {
      if (list.some(n => n?.moveDetails?.unitsToMove)) {
        history.push('/field-configuration/sites/move-sites/status/move-success/true');
      } else {
        history.push('/field-configuration/sites/move-sites/status/move-success');
      }
    });
  };

  return (
    <FadeIn className={` px-4 sm:px-6  bg-white`}>
      <div className={`py-8 sm:pb-16 space-y-6 w-full`}>
        <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-between items-center`}>
          <Heading className={`text-brand_blue font-semibold`} title={`Select Assets`} />
          <Button className={`${assetsToMove.length > 0 ? `` : `pointer-events-none opacity-25`}`} text={`Move`} onClick={moveAssets} />
          {/* <div className={`w-96`}>
            <InputBox value={phrase} onValueChange={handleInputChange} name={`phrase`} placeholder={`Search...`} variant={4} className={``} type={`search`} />
          </div> */}
        </div>
        <AssetTable data={currentList} variant={3} onSelect={onSelect} moving={true} />
        <div className={`flex justify-center`}>
          <ReactPaginate
            className={`flex items-center space-x-6 mx-auto mb-4`}
            nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
            previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
            activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
            breakLabel='...'
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel='<'
            renderOnZeroPageCount={null}
          />
        </div>
        {/* <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
          <Button text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
          <Button className={`${assetsToMove.length > 0 ? `` : `pointer-events-none opacity-25`}`} text={`Move`} onClick={moveAssets} />
        </div> */}
      </div>
    </FadeIn>
  );
};

export default MoveAssets;
