import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AssetsTable from '../../../../components/fragments/asset-table';
import Button from '../../../../components/fragments/button';

const StockCountBase = () => {
  const history = useHistory();
  const assets = useSelector(state => state.assets);

  const handleClick = item => {
    if (item.count_batch) {
      history.push(`/im/stock-count/counter-result/${item.count_batch}`);
    }
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
      p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4  justify-between items-center'>
        <div></div>
        <Button onClick={() => history.push('/im/stock-count/counter')} className={`flex-shrink-0`} text={`Start Count`} />
      </div>

      {assets?.stockCount && assets?.stockCount?.history.length > 0 && <AssetsTable data={assets.stockCount.history} variant={1.61} onClick={handleClick} />}
    </FadeIn>
  );
};

export default StockCountBase;
