import {
  FETCH_WAREHOUSES_WITH_MOST_ASSETS,
  FETCH_STOCK_COUNT_REPORTS,
  FETCH_DAYS_WITH_ASSETS,
  FETCH_SURVEY_PERFORMANCE,
  FETCH_ASSETS_ANALYTICS,
} from '../type';

import { notify, loading } from './response';
import { AnalyticsService } from '../../services';
import { UserActions } from '../actions';

export const retrieveWarehousesWithTopAssets = count => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AnalyticsService.retrieveWarehousesWithTopAssets(count);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_WAREHOUSES_WITH_MOST_ASSETS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const retrieveDaysWithAssets = (from, to) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AnalyticsService.retrieveDaysWithAssets(from, to);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_DAYS_WITH_ASSETS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const retrieveStockCountReports = (perPage, page, assetName, warehouseId) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AnalyticsService.retrieveStockCountReports(perPage, page, assetName, warehouseId);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_STOCK_COUNT_REPORTS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const retrieveSurveyPerformace = (from, to) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AnalyticsService.retrieveSurveyPerformace(from, to);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_SURVEY_PERFORMANCE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const retrieveAssetsAnalytics = (from, to, assetName, warehouseId) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await AnalyticsService.retrieveAssetsAnalytics(from, to, assetName, warehouseId);

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_ASSETS_ANALYTICS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

const AnalyticsActions = {
  retrieveWarehousesWithTopAssets,
  retrieveDaysWithAssets,
  retrieveStockCountReports,
  retrieveSurveyPerformace,
  retrieveAssetsAnalytics,
};

export default AnalyticsActions;
