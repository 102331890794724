import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AllReports from './all-reports';
import Report from './report';

const Reports = () => {
  return (
    <Switch>
      <Route exact path={'/im/reports'}>
        <AllReports />
      </Route>
      <Route exact path={'/im/reports/:name'}>
        <Report />
      </Route>
      <Redirect to={`/404`} />
    </Switch>
  );
};

export default Reports;
