import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';

import { ReactComponent as Success } from '../../../assets/check.svg';
import Button from '../../../components/fragments/button';
import Heading from '../../../components/fragments/heading';
import Text from '../../../components/fragments/text';
import { AssetActions } from '../../../states/actions';

const OnboardSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AssetActions.resetOnboard());
    dispatch(AssetActions.setApp('im'));
    // eslint-disable-next-line
  }, []);

  return (
    <FadeIn className={`p-6 pb-16 pt-12 w-full min-h-screen flex flex-col justify-center items-center space-y-12`}>
      <div className={`w-80 h-80`}>
        <Success className={`w-80`} />
      </div>
      <div className={`space-y-2`}>
        <Heading className={`font-semibold text-center mt-6`} title={`Asset Onboarded Successfully!`} />
        <Text className={`text-center text-brand_blue`} value={`Awesome. You have successfully onboarded the asset`} />
      </div>
      <Button text={`Monitor Onboarding Progress`} onClick={() => history.push('/onboarding/onboard-progress')} />
      {/* <Text className={`text-center text-brand_blue underline font-medium`} value={`why do you need Sticker IDs?`} /> */}
    </FadeIn>
  );
};

export default OnboardSuccess;
