import React, { useState } from 'react';
import ImageFadeIn from 'react-image-fade-in';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { BsInfoCircle } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Rfid } from '../../../assets/rfid.svg';
import Printer from '../../../assets/Printer.png';

import Text from '../text';
import Dialog from '../dialog';

const AddHardware = ({ hardwares, setHardwares }) => {
  const handleIncrement = idx => {
    let items = hardwares.map((hds, i) => {
      if (i === idx) {
        hds.quantity = Number(hds.quantity) + 1;
        return hds;
      }
      return hds;
    });
    setHardwares(items);
  };

  const handleDecrement = idx => {
    let items = hardwares.map((hds, i) => {
      if (i === idx) {
        if (hds.quantity <= 0) return hds;
        hds.quantity = Number(hds.quantity) - 1;
        return hds;
      }
      return hds;
    });
    setHardwares(items);
  };

  const handleChange = (e, idx) => {
    let items = hardwares.map((hds, i) => {
      if (i === idx) {
        hds.quantity = e.target.value ? Number(e.target.value) : '';
        return hds;
      }
      return hds;
    });
    setHardwares(items);
  };

  return (
    <FadeIn className={`grid grid-cols-1 lg:grid-cols-3 space-y-6 lg:space-y-0 lg:space-x-6 w-full`}>
      {hardwares &&
        hardwares.map((item, idx) => {
          return <Item key={idx} idx={idx} item={item} handleIncrement={handleIncrement} handleDecrement={handleDecrement} handleChange={handleChange} />;
        })}
    </FadeIn>
  );
};

const Item = ({ idx, item, handleIncrement, handleDecrement, handleChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ReactTooltip className='w-72' effect='float' backgroundColor='#5F7D95' textColor='white' />

      <div className={`flex flex-col space-y-6 justify-center items-center py-6 px-12 rounded-3xl bg-blue-100`}>
        <Text className={`text-center font-medium`} value={item.name} />
        <div className={`w-40 h-40 bg-white rounded-3xl overflow-hidden flex justify-center items-center p-6`}>
          {item.name === 'Sticker Printer' && (
            <div className='flex flex-col justify-center items-center'>
              <ImageFadeIn src={Printer} />
              <div data-tip={item?.desc} data-type='info' data-place={'top'}>
                <BsInfoCircle className='relative top-1 text-gray-300 hover:text-gray-700 cursor-pointer' size={20} />
              </div>
            </div>
          )}
          {item.name === 'RFID Tags' && (
            <div className='flex flex-col justify-center items-center'>
              <Rfid />
              <div data-tip={item?.desc} data-type='info' data-place={'left'}>
                <BsInfoCircle className='relative top-2 text-gray-300 hover:text-gray-700 cursor-pointer' size={20} />
              </div>
            </div>
          )}
          {item.name === 'Gateway Reader' && (
            <div className='flex flex-col justify-center items-center'>
              <Rfid />
              <div data-tip={item?.desc} data-type='info' data-place={'right'}>
                <BsInfoCircle className='relative top-2 text-gray-300 hover:text-gray-700 cursor-pointer' size={20} />
              </div>
            </div>
          )}
        </div>
        <div className={`flex space-x-4 items-center`}>
          <span
            onClick={() => handleDecrement(idx)}
            style={{ paddingTop: '2px' }}
            className={`text-white ${
              item.quantity <= 0 ? `bg-gray-300 ` : `bg-brand_blue`
            } w-5 h-5 flex justify-center items-center rounded-md cursor-pointer font-semibold select-none`}
          >
            -
          </span>
          <input
            onChange={e => handleChange(e, idx)}
            style={{ maxWidth: '10rem' }}
            className={`font-bold p-2 text-lg text-center bg-transparent`}
            value={item.quantity}
            type={`number`}
          />
          <span
            onClick={() => handleIncrement(idx)}
            style={{ paddingTop: '2px' }}
            className={`text-white bg-brand_blue w-5 h-5 flex justify-center items-center rounded-md cursor-pointer font-semibold select-none`}
          >
            +
          </span>
        </div>
      </div>
      <Dialog type={'info'} open={open} setOpen={setOpen} data={item} />
    </>
  );
};

export default AddHardware;
