import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as TruckFastBlue } from '../../../../assets/truck-fast.svg';
import { ReactComponent as TruckFastGray } from '../../../../assets/truck-fast-gray.svg';
import { ReactComponent as TruckFastGreen } from '../../../../assets/truck-fast-green.svg';
import { ReactComponent as TruckFastYellow } from '../../../../assets/truck-fast-yellow.svg';
import { ReactComponent as HouseIndigo } from '../../../../assets/house-indigo.svg';
import { ReactComponent as HousesRed } from '../../../../assets/houses-red.svg';
import { ReactComponent as StackBrandBlue } from '../../../../assets/stack-brand-blue.svg';
// import { ReactComponent as StackCyan } from '../../../../assets/stack-cyan.svg';
// import { ReactComponent as HouseGreen } from '../../../../assets/house-green.svg';
// import { ReactComponent as HousesPurple } from '../../../../assets/house-purple.svg';
import { ReactComponent as SurveyYellow } from '../../../../assets/document.svg';

import { ReactComponent as Truck } from '../../../../assets/truck.svg';
import { ReactComponent as Location } from '../../../../assets/location.svg';
import { ReactComponent as CheckGreen } from '../../../../assets/check-green.svg';
import { ReactComponent as CheckGray } from '../../../../assets/check-gray.svg';

import Heading from '../../../../components/fragments/heading';
import Text from '../../../../components/fragments/text';
import Chart from '../../../../components/fragments/chart';
import ChartWithTabs from '../../../../components/fragments/chart-with-tabs';

import { UserActions, AnalyticsActions, AssetActions } from '../../../../states/actions';

const OnboardingSteps = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const assets = useSelector(state => state.assets);
  const filter = useSelector(state => state.filter);
  const analytics = useSelector(state => state.analytics);
  const sites = useSelector(state => state.sites);
  const history = useHistory();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    dispatch(AssetActions.fetchAssets(5, 1));
    dispatch(UserActions.fetchUser());
    dispatch(AssetActions.setApp('as'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filter && filter.from && filter.to) {
      dispatch(AnalyticsActions.retrieveDaysWithAssets(filter.from, filter.to));
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (analytics && analytics.daysWithAssets && analytics.daysWithAssets.length > 0) {
      setChartData(
        analytics.daysWithAssets.map(d => {
          d.assetsCount = Number(d.assetsCount);
          return d;
        }),
      );
    } else {
      setChartData([]);
    } // eslint-disable-next-line
  }, [analytics.daysWithAssets]);

  return (
    <>
      {assets && sites && assets.assets.length > 0 && sites.length > 0 ? (
        <FadeIn className={`px-4 sm:px-6 h-full bg-dots`}>
          <div className={`pb-8 sm:pb-16 space-y-6 w-full bg-white`}>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-6'>
              <Link
                style={{ backgroundColor: '#F2FFFE' }}
                to='/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <TruckFastGreen className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Assets</div>
                  <div style={{ color: '#3FB8AF' }} className='font-semibold text-lg'>
                    {user?.company?.totalAssets}
                  </div>
                </div>
              </Link>
              <Link
                style={{ backgroundColor: '#ECEBFF' }}
                to='/field-configuration/my-warehouses'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <HouseIndigo className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Warehouses</div>
                  <div style={{ color: '#392DCA' }} className='font-semibold text-lg'>
                    {user?.company?.totalWarehouses}
                  </div>
                </div>
              </Link>
              <Link
                style={{ backgroundColor: '#FFEFF2' }}
                to='/field-configuration/my-sites'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <HousesRed className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Sites</div>
                  <div style={{ color: '#E86D81' }} className='font-semibold text-lg'>
                    {user?.company?.totalSites}
                  </div>
                </div>
              </Link>
              <Link
                style={{ backgroundColor: '#EAF6FF' }}
                to='/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <StackBrandBlue className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Asset Type</div>
                  <div style={{ color: '#153853' }} className='font-semibold text-lg'>
                    {assets?.assetTypes?.length}
                  </div>
                </div>
              </Link>

              <Link
                style={{ backgroundColor: '#FFF9EC' }}
                to='/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <TruckFastYellow className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Value Of Assets</div>
                  <div style={{ color: '#F7B046' }} className='font-semibold text-lg'>
                    {0}
                  </div>
                </div>
              </Link>
              {/* <Link
                style={{ backgroundColor: '#EEFDFF' }}
                to='/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <StackCyan className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Asset Type By Quantity</div>
                  <div style={{ color: '#00DFFC' }} className='font-semibold text-lg'>
                    {0}
                  </div>
                </div>
              </Link> */}
              {/* <Link
                style={{ backgroundColor: '#ECFFF7' }}
                to='/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <HouseGreen className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Warehouse By Asset Quantity</div>
                  <div style={{ color: '#46F7AD' }} className='font-semibold text-lg'>
                    {0}
                  </div>
                </div>
              </Link> */}
              {/* <Link
                style={{ backgroundColor: '#F1EBFF' }}
                to='/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <HousesPurple className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Site By Asset Quantity</div>
                  <div style={{ color: '#5C3AA9' }} className='font-semibold text-lg'>
                    {0}
                  </div>
                </div>
              </Link> */}
            </div>
            <div className='flex justify-between flex-col 2xl:flex-row space-y-6 2xl:space-y-0 2xl:space-x-6 pt-12'>
              <div className='flex-1 border border-gray-100 min-h-64 rounded-xl p-4'>
                <ChartWithTabs />
              </div>
            </div>
            <div className='flex justify-between flex-col 2xl:flex-row space-y-6 2xl:space-y-0 2xl:space-x-6 pt-12'>
              <div className='flex-1 border border-gray-100 min-h-64 rounded-xl p-4'>
                {chartData ? (
                  <Chart type={'assets'} data={chartData} />
                ) : (
                  <div className='text-gray-400 h-full flex justify-center items-center text-sm'>Assets chart show up here when available</div>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      ) : (
        <FadeIn className={` px-4 sm:px-6 h-full bg-dots`}>
          <div className={`py-8 sm:py-16 space-y-6 w-full bg-white`}>
            <div className={`flex space-x-1 justify-start items-center`}>
              <Heading className={`text-brand_blue font-semibold`} title={`Onboarding Steps`} />
              {assets.assets.length > 0 && sites.length > 0 && <Heading className={`text-brand_blue font-semibold`} title={`: Completed!`} />}
            </div>
            <div className={`flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 w-full`}>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center  space-x-4`}>
                <div className='bg-blue-100 p-2 rounded-lg w-12 h-12'>
                  <TruckFastBlue className='w-full h-full' />
                </div>
                <div>
                  <div className='text-gray-500'>Total Assets</div>
                  <div className='font-semibold text-lg'>{user?.company?.totalAssets}</div>
                </div>
              </div>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center space-x-4`}>
                <div className='bg-yellow-100 p-2 rounded-lg w-12 h-12'>
                  <SurveyYellow className='w-full h-full' />
                </div>
                <div>
                  <div className='text-gray-500'>Total Surveys</div>
                  <div className='font-semibold text-lg'>{user?.company?.totalSurveys}</div>
                </div>
              </div>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center space-x-4`}>
                <div className='bg-blue-200 p-2 rounded-lg text-blue-900 w-12 h-12'>
                  <TruckFastGray className='w-full h-full' />
                </div>
                <div>
                  <div className='text-gray-500'>Total Chekkit Hardwares</div>
                  <div className='font-semibold text-lg'>{user?.company?.totalHardwares}</div>
                </div>
              </div>
            </div>
            <div className={`flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 w-full`}>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex flex-col items-center justify-center space-y-6`}>
                <Heading className={`font-medium`} title={`Step 1: Asset onboarding`} size={2} />
                <div className={`w-32 md:w-48`}>
                  <Truck className={`w-full h-full`} />
                </div>
                <div className={`bg-white p-4 rounded-2xl flex items-center justify-between space-x-4 w-full`}>
                  {assets.assets.length > 0 ? (
                    <Text className={`text-brand_blue`} value={`Assets has been onboarding successfully!`} />
                  ) : (
                    <Text
                      className={`text-brand_blue font-semibold underline cursor-pointer`}
                      value={`Onboard assets`}
                      onClick={() => history.push('/onboarding/upload-assets')}
                    />
                  )}
                  <div className={`w-8`}>
                    {assets.assets.length > 0 ? <CheckGreen className={`w-full h-full`} /> : <CheckGray className={`w-full h-full`} />}
                  </div>
                </div>
              </div>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex flex-col items-center justify-center space-y-6`}>
                <Heading className={`font-medium`} title={`Step 2: Field configuration`} size={2} />
                <div className={`w-32 md:w-48`}>
                  <Location className={`w-full h-full`} />
                </div>
                <div className={`bg-white p-4 rounded-2xl flex items-center justify-between space-x-4 w-full`}>
                  {sites.length > 0 ? (
                    <Text className={`text-brand_blue`} value={`Field configuration done successfully!`} />
                  ) : (
                    <Text
                      className={`text-brand_blue font-semibold ${assets.assets.length < 1 ? `pointer-events-none opacity-50` : `underline cursor-pointer`}`}
                      value={`Continue onboarding`}
                      onClick={() => history.push('/field-configuration')}
                    />
                  )}
                  <div className={`w-8`}>{sites.length > 0 ? <CheckGreen className={`w-full h-full`} /> : <CheckGray className={`w-full h-full`} />}</div>
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </>
  );
};

export default OnboardingSteps;
