import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as TruckFastBlue } from '../../../../assets/truck-fast.svg';
import { ReactComponent as TruckFastGray } from '../../../../assets/truck-fast-gray.svg';
import { ReactComponent as SurveyYellow } from '../../../../assets/document.svg';

import Chart from '../../../../components/fragments/chart/chart';
import { colors } from '../../../../util';
import { AnalyticsActions } from '../../../../states/actions';

const AllReports = () => {
  const user = useSelector(state => state.user);
  const { assetsAnalytics } = useSelector(state => state.analytics);
  const filter = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState({
    byMonth: [],
    byType: {},
  });

  useEffect(() => {
    if (assetsAnalytics && assetsAnalytics?.asset_by_type?.length > 0) {
      let srcData = assetsAnalytics?.asset_by_type;
      let barColors = {};
      let preData = {};
      let barData = [];
      srcData.forEach((b, i) => {
        if (!barColors[b.name]) {
          barColors[b.name] = i > 3 ? colors[Math.floor(Math.random() * (colors.length - 3) + 3)] : colors[i];
        }
      });
      srcData.forEach((d, i) => {
        if (preData[d.formattedDate]) {
          preData[d.formattedDate] = [...preData[d.formattedDate], srcData[i]];
        } else {
          preData[d.formattedDate] = [srcData[i]];
        }
      });
      Object.keys(preData).forEach((d, i) => {
        let item = { formattedDate: d };
        preData[d].forEach(f => {
          item[f.name] = Number(f.assetsCount);
        });
        barData.push(item);
      });
      setAnalytics(d => {
        return { ...d, byType: { colors: barColors, data: barData } };
      });
    }
    if (assetsAnalytics && assetsAnalytics?.total_assets_by_month?.length > 0) {
      setAnalytics(d => {
        let items = assetsAnalytics?.total_assets_by_month.map(f => {
          f.assetsCount = Number(f.assetsCount);
          return f;
        });
        return { ...d, byMonth: items };
      });
    }
    // eslint-disable-next-line
  }, [assetsAnalytics]);

  useEffect(() => {
    if (filter && filter.from && filter.to) {
      dispatch(AnalyticsActions.retrieveAssetsAnalytics(filter.from, filter.to));
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-12 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 w-full`}>
        <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center  space-x-4`}>
          <div className='bg-blue-100 p-2 rounded-lg w-12 h-12'>
            <TruckFastBlue className='w-full h-full' />
          </div>
          <div>
            <div className='text-gray-500'>Total Assets</div>
            <div className='font-semibold text-lg'>{user?.company?.totalAssets}</div>
          </div>
        </div>

        <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center space-x-4`}>
          <div className='bg-yellow-100 p-2 rounded-lg w-12 h-12'>
            <SurveyYellow className='w-full h-full' />
          </div>
          <div>
            <div className='text-gray-500'>Total Surveys</div>
            <div className='font-semibold text-lg'>{user?.company?.totalSurveys}</div>
          </div>
        </div>

        <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center  space-x-4`}>
          <div className='bg-blue-200 p-2 rounded-lg text-blue-900 w-12 h-12'>
            <TruckFastGray className='w-full h-full' />
          </div>
          <div>
            <div className='text-gray-500'>Total Chekkit Hardwares</div>
            <div className='font-semibold text-lg'>{user?.company?.totalHardwares}</div>
          </div>
        </div>
      </div>

      <div className='w-full space-y-12'>
        {analytics.byMonth && analytics.byMonth.length > 0 && (
          <div className='border border-gray-100 rounded-xl p-4'>
            <Chart type={'single-bar'} data={analytics.byMonth} />
          </div>
        )}
        {analytics.byType && Object.keys(analytics.byType).length > 0 && (
          <div className='border border-gray-100 rounded-xl p-4 w-full flex-1'>
            <Chart title={'Percentage Total Asset'} type={'stacked-bar'} data={analytics.byType} />
          </div>
        )}
      </div>
    </FadeIn>
  );
};

export default AllReports;
