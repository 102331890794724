import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

import InputBox from '../../../../components/fragments/input-box';
import Button from '../../../../components/fragments/button';
import AssetsTable from '../../../../components/fragments/asset-table';

import { AssetActions } from '../../../../states/actions';

const AllAssets = () => {
  const [phrase, setPhrase] = useState('');
  const history = useHistory();

  const handleInputChange = event => {
    const { value } = event.target;
    setPhrase(value);
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <div className={`flex items-center space-x-12`}>
          <div className={`w-80`}>
            <InputBox value={phrase} onValueChange={handleInputChange} name={`phrase`} placeholder={`Search...`} variant={4} className={``} type={`search`} />
          </div>
        </div>
        <Button className={``} text={`Add Assets`} onClick={() => history.push('/im/onboarding/get-started')} />
      </div>
      <ViewAssets />
    </FadeIn>
  );
};

const ViewAssets = () => {
  let itemsPerPage = 5;
  const assets = useSelector(state => state.assets);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AssetActions.fetchAssets(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      <AssetsTable data={assets?.assets} nameLink={true} variant={6.1} appName={assets.appName} />
      <div className={`flex justify-center`}>
        <ReactPaginate
          className={`flex items-center space-x-6 mx-auto mb-4`}
          nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
          breakLabel='...'
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel='<'
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default AllAssets;
