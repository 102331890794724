import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import bwipjs from 'bwip-js';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../../../components/fragments/button';
import Heading from '../../../../components/fragments/heading/heading';
import SelectCode from '../../../../components/fragments/select-code';
import Dialog from '../../../../components/fragments/dialog';
import Logo from '../../../../components/fragments/logo/logo';

import { ResponseActions } from '../../../../states/actions';

const GenerateCode = () => {
  const dispatch = useDispatch();
  const assets = useSelector(state => state.assets);
  const [codeType, setCodeType] = useState([
    { name: 'GS1 Data Matrix Code', image: '', selected: true },
    { name: 'GS1 QR Code', image: '', selected: false },
  ]);
  const [codes, setCodes] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCodeType(
      codeType.map(d => {
        let canvas = document.createElement('canvas');
        bwipjs.toCanvas(canvas, {
          bcid: d.name === 'GS1 Data Matrix Code' ? 'datamatrix' : 'qrcode',
          text: 'Chekkit',
          scale: 5,
          height: d.name === 'GS1 Data Matrix Code' ? 10 : 15,
          includetext: true,
          textxalign: 'center',
        });
        d.image = canvas.toDataURL('image/png');
        return d;
      }),
    );
    // eslint-disable-next-line
  }, []);

  const setProceed = value => {
    let valid = false;
    if (value && assets.onBoardCompleted.length > 0) {
      valid = assets.onBoardCompleted.every(d => d?.stickerId?.value);
    }

    if (!valid) {
      dispatch(ResponseActions.notify({ title: 'Error', message: "Some assets don't have sticker ID's", type: 'default', loading: false }));
      return;
    }

    if (value && assets.onBoardCompleted.length > 0) {
      let c = assets.onBoardCompleted.map(asset => {
        let canvas = document.createElement('canvas');
        bwipjs.toCanvas(canvas, {
          bcid: codeType.find(d => d.selected).name === 'GS1 Data Matrix Code' ? 'datamatrix' : 'qrcode',
          text: asset?.stickerId?.value,
          scale: 5,
          height: codeType.find(d => d.selected).name === 'GS1 Data Matrix Code' ? 10 : 15,
          width: codeType.find(d => d.selected).name === 'GS1 Data Matrix Code' ? 10 : 15,
          includetext: true,
          textxalign: 'center',
        });
        return {
          name: asset.name,
          serialNumber: asset.serialNumber,
          stickerId: asset?.stickerId?.value,
          batchNumber: asset.batchNumber,
          barcode: canvas.toDataURL('image/png'),
        };
      });
      setCodes(c);
    }
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen bg-dots`}>
      <Logo size={180} />
      <FadeIn className='w-full py-12 md:py-32 px-4 sm:px-6 md:px-8 flex flex-col items-center justify-center '>
        {!codes && <SelectCode codeType={codeType} setCodeType={setCodeType} onProceed={setProceed} />}
        {codes && (
          <>
            <div className={`flex flex-col justify-center items-center p-4 w-full max-w-5xl space-y-10`}>
              <Heading className={`text-center text-brand_blue font-semibold`} title={`Sample ${codeType.find(d => d.selected).name}`} />
              <div className={`p-10 rounded-xl bg-gray-100 w-full max-w-5xl text-brand_blue flex flex-col space-y-6 xl:space-y-0 xl:flex-row xl:space-x-6`}>
                <div className={`flex items-center justify-center`}>
                  <img width={150} src={codes[0].barcode} alt='' />
                </div>
                <div className={`space-y-2 w-64 mx-auto font-bold flex flex-col justify-center text-black text-xl`}>
                  <div className={`flex items-center justify-start`}>
                    {/* <span className={`mr-2`}>IT:</span> */}
                    <span>{codes[0].name}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    {/* <span className={`mr-2`}>SN:</span> */}
                    <span>{codes[0].serialNumber}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    {/* <span className={`mr-2`}>ID:</span> */}
                    <span>{codes[0].stickerId}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    {/* <span className={`mr-2`}>BN:</span> */}
                    <span>{codes[0].batchNumber}</span>
                  </div>
                </div>
              </div>
              <div className={`flex flex-col w-full max-w-5xl space-y-6 lg:flex-row lg:space-y-0 lg:space-x-12  justify-center`}>
                <Button text={`Back`} type={`secondary`} onClick={() => setCodes('')} />
                <Button text={`Print Preview`} onClick={() => setOpen(true)} />
              </div>
            </div>

            <Dialog open={open} setOpen={setOpen} data={codes} type='codes' url={'/onboarding/sticker-id-success'} />
          </>
        )}
      </FadeIn>
    </div>
  );
};

export default GenerateCode;
