import React, { useState, useEffect, useRef } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { CgSpinner } from 'react-icons/cg';
import { BsPauseFill, BsStopFill, BsPlayFill } from 'react-icons/bs';

const Scanner = ({ onScanned, onStop }) => {
  const inputRef = useRef();
  const anyRef = useRef();
  const [focus, setFocus] = useState(false);
  const [control, setControl] = useState({
    scanning: false,
    pause: false,
    stop: false,
  });

  useEffect(() => {
    if (control.stop) {
      if (onStop) onStop();
    }
    // eslint-disable-next-line
  }, [control.stop]);

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    } else {
      anyRef.current.click();
    }
  }, [focus]);

  const onInputChange = e => {
    inputRef.current.value = e.target.value;
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (e.target.value) {
        console.log(e.target.value);
        let sn = e?.target?.value?.split('Serial Number: ')[1]?.split('.')[0]?.replace('.', '');
        let name = e?.target?.value?.split('Name: ')[1]?.split('.')[0]?.replace('.', '');
        let packageLevel = e?.target?.value?.split('Package Level: ')[1]?.split(' ')[0]?.replace('.', '');
        let batchNumber = e?.target?.value?.split('Batch Number: ')[1]?.split(' ')[0]?.replace('.', '');
        let expiryDate = e?.target?.value?.split('Expiry Date: ')[1]?.split(' ')[0]?.replace('.', '');

        inputRef.current.value = '';
        if (!sn || !name || !packageLevel || !batchNumber || !expiryDate) return;
        if (packageLevel !== 'Primary' && packageLevel !== 'Secondary' && packageLevel !== 'Tertiary') return;
        let obj = { sn, name, packageLevel, batchNumber, expiryDate };

        if (onScanned) onScanned(obj);
      }
    }, 2500);
  };

  return (
    <FadeIn className={`bg-white flex-1 p-6 py-16 flex flex-col items-center w-full`}>
      <div ref={anyRef} className='font-semibold text-xl text-center text-brand_blue mb-3'>
        {control.scanning && !control.pause ? `Scanning In Progress` : !control.scanning && control.pause ? `Continue scan` : `Start scan`}
      </div>
      <div className='text-center text-gray-600'>
        {control.scanning && !control.pause
          ? `You are currently taking a stock count`
          : !control.scanning && control.pause
          ? `Your scan is paused`
          : `You are about to take a stock count`}
      </div>

      <div className={`h-16 w-auto my-32 flex justify-center items-center`}>
        {!control.scanning && !control.pause && <div>Start scan</div>}
        {!control.scanning && control.pause && <div>Paused</div>}
        <CgSpinner className={`${control.scanning ? `` : `hidden`} text-brand_blue animate-spin`} size={64} />
      </div>

      <div className='space-x-20 flex items-center justify-evenly w-full'>
        <div
          onClick={() => setControl({ ...control, scanning: false, pause: true })}
          className={`${!control.scanning && !control.pause ? 'pointer-events-none opacity-50' : ''} flex flex-col items-center justify-center space-y-2`}
        >
          <div
            className={`${
              control.pause && !control.scanning ? `bg-brand_blue text-white` : `bg-blue-100 text-brand_blue hover:bg-brand_blue hover:text-white`
            } rounded-lg h-20 w-20 flex justify-center items-center cursor-pointer`}
          >
            <BsPauseFill size={40} />
          </div>
          <div className='font-semibold text-base  text-brand_blue'>Pause</div>
        </div>

        <div onClick={() => setFocus(true)} className='flex flex-col items-center justify-center space-y-2'>
          <div
            className={`${
              control.scanning ? `bg-brand_blue text-white` : `bg-blue-100 text-brand_blue hover:bg-brand_blue hover:text-white`
            } rounded-lg h-20 w-20 flex justify-center items-center cursor-pointer`}
          >
            <BsPlayFill size={40} />
          </div>
          <div className='font-semibold text-base  text-brand_blue'>Scan</div>
        </div>

        <div
          onClick={() => setControl({ ...control, scanning: false, pause: false, stop: true })}
          className={`${!control.scanning && !control.pause ? 'pointer-events-none opacity-50' : ''} flex flex-col items-center justify-center space-y-2`}
        >
          <div
            className={`bg-blue-100 text-brand_blue hover:bg-brand_blue hover:text-white rounded-lg h-20 w-20 flex justify-center items-center cursor-pointer`}
          >
            <BsStopFill size={40} />
          </div>
          <div className='font-semibold text-base text-brand_blue'>Stop</div>
        </div>
      </div>
      <input
        style={{ opacity: '0' }}
        ref={inputRef}
        onChange={onInputChange}
        onFocus={() => {
          setControl({ ...control, scanning: true, pause: false, stop: false });
        }}
        onBlur={() => {
          setControl({ scanning: false, pause: true, stop: false });
          setFocus(false);
        }}
      />
    </FadeIn>
  );
};

export default Scanner;
