import {
  FETCH_WAREHOUSES_WITH_MOST_ASSETS,
  FETCH_STOCK_COUNT_REPORTS,
  FETCH_DAYS_WITH_ASSETS,
  FETCH_SURVEY_PERFORMANCE,
  FETCH_ASSETS_ANALYTICS,
} from '../type';

const initialState = { warehousesWithMostAssets: [], daysWithAssets: [], stockCountReports: [], surveyPerformance: [], assetsAnalytics: [] };

const AnalyticsReducer = (analytics = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_WAREHOUSES_WITH_MOST_ASSETS:
      return { ...analytics, warehousesWithMostAssets: payload };
    case FETCH_STOCK_COUNT_REPORTS:
      return { ...analytics, stockCountReports: payload };
    case FETCH_DAYS_WITH_ASSETS:
      return { ...analytics, daysWithAssets: payload };
    case FETCH_SURVEY_PERFORMANCE:
      return { ...analytics, surveyPerformance: payload };
    case FETCH_ASSETS_ANALYTICS:
      return { ...analytics, assetsAnalytics: payload };
    default:
      return analytics;
  }
};

export default AnalyticsReducer;
