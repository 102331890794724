import http from '../http';

export const retrieveAsstes = async (perPage, page) => {
  return http().get(`/asset?perPage=${perPage}&page=${page}`);
};

export const retrieveAsstesBySite = async id => {
  return http().get(`/site/${id}/assets`);
};

export const retrieveAsstesByWarehouse = async id => {
  return http().get(`/warehouse/${id}/assets?perPage=${100}&page=${1}`);
};

export const onboardAsset = async data => {
  return http().post(`/asset`, data);
};

export const moveAssetsToWarehouse = async (id, data) => {
  let ids = { assetIds: data };
  return http().put(`/asset/warehouse/${id}/move`, ids);
};

export const moveAssetsToSite = async (id, data) => {
  let ids = { moveAssetDetails: data };
  return http().put(`/asset/site/${id}/move`, ids);
};

export const createSurvey = async data => {
  return http().post(`/survey`, data);
};

export const updateSurvey = async (id, data) => {
  return http().put(`/survey/${id}`, data);
};

export const retrieveSurveys = async (perPage, page) => {
  return http().get(`/survey?perPage=${perPage}&page=${page}`);
};

export const attachSurveyToAssets = async (id, data) => {
  let ids = { assetIds: data };
  return http().put(`/survey/${id}/attach-to-asset`, ids);
};

export const enableSurvey = async id => {
  return http().put(`/survey/${id}/enable`);
};

export const disableSurvey = async id => {
  return http().put(`/survey/${id}/disable`);
};

export const retrieveAssetTypes = async (perPage, page) => {
  return http().get(`/asset/type?perPage=${perPage}&page=${page}`);
};

export const retrieveOnboardProgress = async (perPage, page) => {
  return http().get(`/asset/progress?perPage=${perPage}&page=${page}`);
};

export const retrieveOnboardCompleted = async id => {
  return http().get(`/asset/completed/${id}`);
};

export const retrieveStockCountHistory = async () => {
  return http().get(`/asset/stock-count-history`);
};

export const retrieveStockCountBatch = async batchId => {
  return http().get(`/asset/stock-count-history/${batchId}`);
};

export const createStockCount = async data => {
  let rds = { records: data };
  return http().post(`/asset/stock-count`, rds);
};

export const retrieveRfidTags = async () => {
  return http().get(`/rfid?perPage=${15}&page=${1}`);
};

export const assignRfidTag = async (id, macAddress) => {
  return http().put(`/asset/${id}/rfid/${macAddress}`);
};

export const disableRfidTags = async oldRfid => {
  return http().delete(`/rfid/assets/de-provision/${oldRfid}`);
};

export const retrieveFinances = async (type, id) => {
  return http().get(`/finance?type=${type}${type === 'site' && id ? `&siteId=${id}` : type === 'warehouse' && id ? `&warehouseId=${id}` : ``}`);
};

export const retrieveAlarmLogs = async (perPage, page) => {
  return http().get(`/rfid/alarm-logs?perPage=${perPage}&page=${page}`);
};

export const updateNotifyStatus = async data => {
  return http().put(`/rfid/${data?.assetId}/${data?.status}/update-alarmlog`, data);
};

export const retrieveAssetLocation = async id => {
  return http().get(`/asset/detail/${id}`);
};

export const retrieveAssetPath = async macAddress => {
  return http().get(`/rfid/detail/${macAddress}`);
};

const AssetService = {
  retrieveAsstes,
  retrieveAsstesBySite,
  retrieveAsstesByWarehouse,
  onboardAsset,
  moveAssetsToWarehouse,
  moveAssetsToSite,
  createSurvey,
  updateSurvey,
  retrieveSurveys,
  attachSurveyToAssets,
  enableSurvey,
  disableSurvey,
  retrieveAssetTypes,
  retrieveOnboardProgress,
  retrieveOnboardCompleted,
  retrieveStockCountHistory,
  retrieveStockCountBatch,
  createStockCount,
  retrieveRfidTags,
  disableRfidTags,
  assignRfidTag,
  retrieveFinances,
  retrieveAlarmLogs,
  updateNotifyStatus,
  retrieveAssetLocation,
  retrieveAssetPath,
};

export default AssetService;
