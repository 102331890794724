import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link, Route } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { IoNotifications } from 'react-icons/io5';

import Logo from '../logo';
import Heading from '../heading';

import { NotificationActions } from '../../../states/actions';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Notification = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const [notification, setNotification] = useState();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      let n = notifications.find(d => d.id === id);
      if (n) {
        dispatch(NotificationActions.markNotificationAsRead(id));
        setNotification(n);
      }
    }
    // eslint-disable-next-line
  }, [id]);

  const markAllAsRead = () => {
    let notRead = notifications.filter(d => !d.isRead);
    console.log(notRead);
    if (notRead.length > 0) {
      Promise.all(
        notRead.map(d => {
          return dispatch(NotificationActions.markNotificationAsRead(d.id));
        }),
      );
    }
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <div onClick={() => history.goBack()} className='text-brand_blue font-semibold my-6 cursor-pointer'>
        &larr; Back
      </div>
      <Route exact path={'/notifications'}>
        <div className='flex space-x-4 items-center justify-between'>
          <div className='flex items-center space-x-1'>
            <Heading size={2} className={`text-brand_blue font-semibold`} title={`Notifications`} />
            <IoNotifications className='transform rotate-12 text-brand_blue' size={18} />
          </div>
          <div onClick={markAllAsRead} className='cursor-pointer font-medium text-gray-600'>
            Mark all as read
          </div>
        </div>
        <FadeIn className='w-full mt-4 shadow divide-y divide-gray-200'>
          {notifications && notifications.length > 0 ? (
            notifications.map(item => {
              return (
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/notifications/${item.id}`}
                  key={item.id}
                  className={classNames(
                    !item.isRead ? 'bg-gray-50' : 'bg-white',
                    'block hover:bg-gray-100 py-6 px-4 text-sm text-gray-700 focus:text-gray-700 hover:text-gray-700 w-full cursor-pointer',
                  )}
                >
                  <div className='space-y-2'>
                    <div className='flex items-center justify-between space-x-4'>
                      <div className='font-medium'>{item.title}</div>
                      <div className='flex space-x-1 text-xs text-gray-500'>
                        <span>{new Date(item.created_at).toDateString()}</span>
                        <div>•</div>
                        <span>{new Date(item.created_at).toTimeString().slice(0, 5)}</span>
                      </div>
                    </div>
                    <div className='line-clamp-3'>{item.content}</div>
                  </div>
                </Link>
              );
            })
          ) : (
            <div className='text-center p-6 text-gray-500'>No notifications</div>
          )}
        </FadeIn>
      </Route>
      <Route exact path={'/notifications/:id'}>
        {notification && (
          <FadeIn>
            <div className='flex space-x-4 items-center justify-between'>
              <Heading size={2} className={`text-brand_blue font-semibold`} title={notification.title} />
              <div className='flex space-x-1 text-sm text-gray-600'>
                <span>{new Date(notification.created_at).toDateString()}</span>
                <div>•</div>
                <span>{new Date(notification.created_at).toTimeString().slice(0, 5)}</span>
              </div>
            </div>
            <div className='w-full bg-white py-8'>{notification.content}</div>
          </FadeIn>
        )}
      </Route>
    </div>
  );
};

export default Notification;
