import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AssetOnboard from './asset-onboard';
import GetStarted from './get-started';
import UploadAssets from './upload-assets';
import UploadedAssets from './uploaded-assets';
import SetupStickers from './setup-stickers';
import CreateSurvey from './create-survey';
import SurveyEdit from '../../../components/fragments/survey-edit/survey-edit';
import SurveyPreview from '../../../components/fragments/survey-preview/survey-preview';
import EmbedInfo from './embed-info';
import OnboardSummary from './onboard-summary';
import OnboardSuccess from './onboard-success';

const Onboarding = () => {
  const assets = useSelector(state => state.assets);
  const history = useHistory();

  useEffect(() => {
    if (assets?.packageTypes?.length < 1) {
      history.replace('/onboarding/get-started');
    }
    // eslint-disable-next-line
  }, [assets.packageTypes]);

  return (
    <div className='bg'>
      <Switch>
        <Route exact path={['/onboarding', '/onboarding/ctr/:controller']}>
          <AssetOnboard />
        </Route>

        <Route exact path={'/onboarding/get-started'}>
          <GetStarted />
        </Route>

        <Route exact path={'/onboarding/upload-assets'}>
          <UploadAssets />
        </Route>

        <Route exact path={'/onboarding/uploaded-assets'}>
          <UploadedAssets />
        </Route>

        <Route exact path={'/onboarding/setup-stickers'}>
          <SetupStickers />
        </Route>

        <Route exact path={'/onboarding/create-survey'}>
          <CreateSurvey />
        </Route>

        <Route exact path={['/onboarding/create-survey/survey-edit', '/onboarding/create-survey/survey-edit/:controller']}>
          <SurveyEdit />
        </Route>

        <Route exact path={['/onboarding/create-survey/survey-preview', '/onboarding/create-survey/survey-preview/:controller']}>
          <SurveyPreview />
        </Route>

        <Route exact path={'/onboarding/embed-info'}>
          <EmbedInfo />
        </Route>

        <Route exact path={'/onboarding/onboard-summary'}>
          <OnboardSummary />
        </Route>

        <Route exact path={'/onboarding/onboarding-success'}>
          <OnboardSuccess />
        </Route>
        <Redirect to={`/404`} />
      </Switch>
    </div>
  );
};

export default Onboarding;
