import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker, Polyline } from '@react-google-maps/api';
import { REACT_APP_MAP_API_KEY } from '../../../config';
import parseISO from 'date-fns/parseISO';
import { format } from 'date-fns';

import { getTodayDate, getOneDayStartDate, getTwoDayStartDate, getWeeklyStartDate, getTwoWeekStartDate, getOneMonthStartDate, getTwoMonthStartDate } from '../../../util.js'

import SelectBox from '../../fragments/select-box'

const libraries = ['places'];

const AssetMap = ({ location, assetName, serialNumber }) => {
  const [data, setData] = useState();
  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState({
    strokeColor: '#5F7D95',
    fillColor: '#5F7D95',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 10,
  });
  const [allPath, setAllPath] = useState();
  const [path, setPath] = useState([]);
  const [filter, setFilter] = useState('Latest')
  const [dateRange, setDateRange] = useState({
    from: '',
    to: ''
  })
  const [points, setPoints] = useState(4)

  useEffect(() => {
    if (filter === 'Latest') {
      setDateRange({ from: format(new Date(), `yyyy-MM-dd`), to: getTodayDate() })
    }
    if (filter === '1 day ago') {
      setDateRange({ from: getOneDayStartDate(), to: getTodayDate() })
    }
    if (filter === '2 days ago') {
      setDateRange({ from: getTwoDayStartDate(), to: getTodayDate() })
    }
    if (filter === '1 week ago') {
      setDateRange({ from: getWeeklyStartDate(), to: getTodayDate() })
    }
    if (filter === '2 weeks ago') {
      setDateRange({ from: getTwoWeekStartDate(), to: getTodayDate() })
    }
    if (filter === '1 month ago') {
      setDateRange({ from: getOneMonthStartDate(), to: getTodayDate() })
    }
    if (filter === '2 months ago') {
      setDateRange({ from: getTwoMonthStartDate(), to: getTodayDate() })
    }
  }, [filter])

  useEffect(() => {
    if (dateRange.from && dateRange.to && allPath) {
      let fromDate = parseISO(dateRange.from);
      const toDate = parseISO(dateRange.to);

      const filteredPathByRange = allPath.filter(item => {
        const itemDate = parseISO(item.created_at);
        return itemDate >= fromDate && itemDate <= toDate;
      });

      if (filter === 'Latest') {
        fromDate = toDate
      } else {
        fromDate = parseISO(format(fromDate, 'yyyy-MM-dd'))
        fromDate.setDate(fromDate.getDate() + 1);
      }
      const filteredPath = allPath.filter(item => {
        const itemDate = parseISO(item.created_at);
        return itemDate <= fromDate
      });

      console.log('allPath', allPath)
      console.log('filteredPath', filteredPath);
      console.log('filteredPathByRange', filteredPathByRange);

      setPath(filteredPath.slice(filteredPath.length - points, filteredPath.length))
    }
    // eslint-disable-next-line
  }, [dateRange, allPath, points])

  useEffect(() => {
    if (loaded && window.google) {
      setOptions({
        ...options,
        icons: [
          {
            icon: {
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            },
            offset: '100%',
          },
        ],
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  useEffect(() => {
    if (location && location.lat && location.lng) {
      if (location.path && location.path.length > 0) {
        let lt = location.path.map((d, idx) => {
          if (idx === location.path.length - 1) {
            d.activeMarker = true;
          } else {
            d.activeMarker = false;
          }
          return d;
        });
        setAllPath(lt)
      }
      let lat = parseFloat(location.lat);
      let lng = parseFloat(location.lng);
      if (!isNaN(lat) && !isNaN(lng)) {
        setData({ ...location, activeMarker: true, locationActiveMarker: false, position: { lat, lng } });
      } else {
        setData({ ...location, locationActiveMarker: false });
      }
    } else if (location) {
      setData({ ...location, locationActiveMarker: false });
    }
  }, [location]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: libraries,
    googleMapsApiKey: REACT_APP_MAP_API_KEY,
  });

  const onLoad = React.useCallback(
    function callback(map) {
      setLoaded(true);

      if (location && data?.position) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(data?.position);
        if (data.warehouseLat && data.warehouseLng) {
          bounds.extend({ lat: data?.warehouseLat, lng: data?.warehouseLng });
        }
        if (data.siteLat && data.siteLng) {
          bounds.extend({ lat: data?.siteLat, lng: data?.siteLng });
        }
        map.fitBounds(bounds);
      }
    },
    // eslint-disable-next-line
    [data],
  );

  const handleActiveMarker = (marker, type) => {
    if (path && path.length > 0 && !type) {
      let lt = path.map((d, idx) => {
        if (idx === marker) {
          d.activeMarker = true;
        } else {
          d.activeMarker = false;
        }
        return d;
      });
      setData({ ...data, path: lt });
    }
    if (data.position && type) {
      if (type === 'asset') {
        setData({ ...data, activeMarker: !data.activeMarker, locationActiveMarker: false });
      } else {
        setData({ ...data, activeMarker: false, locationActiveMarker: !data.activeMarker });
      }
    }
  };

  const setActiveMarkerNone = () => {
    if (path && path.length > 0) {
      let lt = path.map(d => {
        d.activeMarker = false;
        return d;
      });
      setData({ ...data, path: lt });
    }
    if (data.position) {
      setData({ ...data, activeMarker: false, locationActiveMarker: false });
    }
  };

  return isLoaded ? (
    <div className='space-y-6 p-6 bg-white border rounded-2xl'>
      <div className='flex items-center space-x-4 justify-between'>
        <div className='font-semibold text-lg text-brand_blue'>{`Location Information`}</div>
        <div className='flex flex-col sm:flex-row items-center gap-4'>
          <div className='w-36 cursor-pointer'>
            <SelectBox
              defaultValue={'1 week ago'}
              onValueChange={e => setFilter(e.target.value)}
              value={filter}
              name={'filter'}
              options={['Latest', '1 day ago', '2 days ago', '1 week ago', '2 weeks ago', '1 month ago', '2 months ago']}
              variant={2.5}
              placeholder={'Select filter'}
            />
          </div>
          <div className='flex items-center space-x-1'>
            <div className='text-xs text-blueGray-400'>View</div>
            <select onChange={e => setPoints(Number(e.target.value))} className='bg-blueGray-100 p-1 rounded-md w-12 font-semibold text-xs' name="points" id="points" value={points}>
              {[4, 8, 16, 32].map((d, i) => {
                return (
                  <option key={i} value={d}>{d}</option>
                )
              })}
            </select>
            <div className='text-xs text-blueGray-400'>points</div>
          </div>
        </div>
      </div>
      <div style={{ height: '600px' }} className={`bg-gray-200 rounded-xl w-full overflow-hidden relative`}>
        <GoogleMap
          onClick={() => {
            setActiveMarkerNone();
          }}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={data?.position ? data?.position : { lat: 6.5244, lng: 3.3792 }}
          zoom={10}
          onLoad={onLoad}
          options={{
            // disableDefaultUI: true,
            // mapTypeControl: false,
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
              // {
              //   featureType: 'transit',
              //   elementType: 'labels.icon',
              //   stylers: [{ visibility: 'off' }],
              // },
            ],
          }}
        >
          {data && data?.siteLat ? (
            <MapMarker2
              id={data?.siteLat}
              position={{ lat: data?.siteLat, lng: data?.siteLng }}
              name={data.siteName}
              locationType={'Site'}
              address={data.address}
              activeMarker={data?.locationActiveMarker}
              handleActiveMarker={handleActiveMarker}
              setActiveMarkerNone={setActiveMarkerNone}
            />
          ) : (
            data &&
            data?.warehouseLat && (
              <MapMarker2
                id={data?.warehouseLat}
                position={{ lat: data?.warehouseLat, lng: data?.warehouseLng }}
                name={data.warehouseName}
                locationType={'Warehouse'}
                address={data.address}
                activeMarker={data?.locationActiveMarker}
                handleActiveMarker={handleActiveMarker}
                setActiveMarkerNone={setActiveMarkerNone}
              />
            )
          )}
          {/* {data && path?.length <= 0 && (
            <MapMarker
              id={data.lat}
              position={data.position ? data.position : null}
              name={assetName}
              serialNumber={serialNumber}
              // address={data.address}
              activeMarker={data?.activeMarker}
              handleActiveMarker={handleActiveMarker}
              setActiveMarkerNone={setActiveMarkerNone}
            />
          )} */}
          {path &&
            path.length > 0 &&
            path.map((d, idx) => {
              return (
                <MapMarker3
                  key={idx}
                  id={idx}
                  path={path}
                  position={d ? d : null}
                  name={assetName}
                  serialNumber={serialNumber}
                  activeMarker={d?.activeMarker}
                  handleActiveMarker={handleActiveMarker}
                  setActiveMarkerNone={setActiveMarkerNone}
                />
              );
            })}
          {path &&
            path.map((d, idx) => {
              return path[idx + 1] ? (
                path[idx + 2] ? (
                  <Polyline key={idx} path={[d, path[idx + 1]]} options={options} />
                ) : (
                  <Polyline key={idx} path={[d, path[idx + 1]]} options={{ ...options, strokeColor: '#46F7AD', fillColor: '#46F7AD' }} />
                )
              ) : (
                <div key={idx}></div>
              );
            })}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <></>
  );
};

// const MapMarker = ({ id, position, name, serialNumber, activeMarker, handleActiveMarker, setActiveMarkerNone }) => {
//   return (
//     <Marker
//       animation={window.google.maps.Animation.BOUNCE}
//       // draggable={true}
//       position={position}
//       onClick={() => {
//         handleActiveMarker(id, 'asset');
//       }}
//       zIndex={activeMarker ? 6 : 4}
//       // onMouseOver={() => {
//       //   handleActiveMarker(id, 'asset');
//       // }}
//       // onMouseOut={() => setActiveMarkerNone()}
//       icon={{
//         path: 'M0.375 10.8962C0.375 5.14639 5.17985 0.499023 10.9918 0.499023C16.8201 0.499023 21.625 5.14639 21.625 10.8962C21.625 13.7936 20.5713 16.4836 18.8369 18.7635C16.9235 21.2784 14.5652 23.4696 11.9107 25.1896C11.3031 25.587 10.7548 25.617 10.0881 25.1896C7.41842 23.4696 5.06011 21.2784 3.16313 18.7635C1.42748 16.4836 0.375 13.7936 0.375 10.8962ZM7.49269 11.2205C7.49269 13.1467 9.06448 14.6616 10.9917 14.6616C12.9202 14.6616 14.5071 13.1467 14.5071 11.2205C14.5071 9.30929 12.9202 7.72058 10.9917 7.72058C9.06448 7.72058 7.49269 9.30929 7.49269 11.2205Z',
//         fillColor: '#46F7AD',
//         fillOpacity: 1,
//         strokeWeight: 1,
//         strokeColor: activeMarker ? '#46F7AD' : '#5F7D95',
//         scale: activeMarker ? 1.5 : 1,
//         anchor: new window.google.maps.Point(
//           11, // width
//           28, // height
//         ),
//       }}
//     >
//       {activeMarker ? (
//         <InfoWindow
//           position={position}
//           onCloseClick={() => {
//             setActiveMarkerNone();
//           }}
//         >
//           <div className='w-full pl-1 space-y-2'>
//             <div className='text-center'>Last known location</div>
//             <div className='space-y-1'>
//               <div className='flex items-center space-x-1'>
//                 <span className='font-medium capitalize'>{name}</span>
//               </div>
//               <div className='flex items-center space-x-1'>
//                 <span className='font-medium'>SN:</span>
//                 <span>{serialNumber}</span>
//               </div>
//             </div>
//           </div>
//         </InfoWindow>
//       ) : null}
//     </Marker>
//   );
// };

const MapMarker2 = ({ id, position, name, locationType, activeMarker, handleActiveMarker, setActiveMarkerNone }) => {
  return (
    <Marker
      animation={window.google.maps.Animation.DROP}
      // draggable={true}
      position={position}
      onClick={() => {
        handleActiveMarker(id, locationType);
      }}
      zIndex={activeMarker ? 2 : 1}
      // onMouseOver={() => {
      //   handleActiveMarker(id, locationType);
      // }}
      // onMouseOut={() => setActiveMarkerNone()}
      icon={{
        path: 'M20.833 20.833m-15.625 0a15.625 15.625 0 1 0 31.25 0a15.625 15.625 0 1 0 -31.25 0',
        fillColor: activeMarker ? '#46F7AD' : '#46F7AD',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: activeMarker ? '#46F7AD' : '#5F7D95',
        scale: activeMarker ? 1.5 : 1,
        anchor: new window.google.maps.Point(
          11, // width
          28, // height
        ),
      }}
    >
      {activeMarker ? (
        <InfoWindow
          position={position}
          onCloseClick={() => {
            setActiveMarkerNone();
          }}
        >
          <div className='w-full pl-1'>
            <div className='space-y-1'>
              <div className='flex items-center'>
                <span className='font-medium capitalize'>{name}</span>
              </div>
              <div className='flex items-center space-x-1'>
                <span className='font-medium'>Location type:</span>
                <span>{locationType}</span>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

const MapMarker3 = ({ id, position, name, serialNumber, activeMarker, handleActiveMarker, path }) => {
  return (
    <Marker
      animation={id === path.length - 1 ? window.google.maps.Animation.BOUNCE : window.google.maps.Animation.DROP}
      visible={id === path.length - 1 ? true : false}
      position={position}
      onClick={() => {
        handleActiveMarker(id);
      }}
      zIndex={activeMarker ? 6 : 4}
      // onMouseOver={() => {
      //   handleActiveMarker(id);
      // }}
      icon={{
        path: 'M0.375 10.8962C0.375 5.14639 5.17985 0.499023 10.9918 0.499023C16.8201 0.499023 21.625 5.14639 21.625 10.8962C21.625 13.7936 20.5713 16.4836 18.8369 18.7635C16.9235 21.2784 14.5652 23.4696 11.9107 25.1896C11.3031 25.587 10.7548 25.617 10.0881 25.1896C7.41842 23.4696 5.06011 21.2784 3.16313 18.7635C1.42748 16.4836 0.375 13.7936 0.375 10.8962ZM7.49269 11.2205C7.49269 13.1467 9.06448 14.6616 10.9917 14.6616C12.9202 14.6616 14.5071 13.1467 14.5071 11.2205C14.5071 9.30929 12.9202 7.72058 10.9917 7.72058C9.06448 7.72058 7.49269 9.30929 7.49269 11.2205Z',
        fillColor: id === path.length - 1 ? '#46F7AD' : '#5F7D95',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: id === path.length - 1 ? (activeMarker ? '#46F7AD' : '#5F7D95') : activeMarker ? '#46F7AD' : '#d5d5d5',
        scale: id === path.length - 1 ? (activeMarker ? 1.5 : 1) : 1,
        anchor: new window.google.maps.Point(
          11, // width
          28, // height
        ),
      }}
    >
      {activeMarker ? (
        <InfoWindow
          position={position}
          onCloseClick={() => {
            // setActiveMarkerNone();
          }}
        >
          {id === path.length - 1 ? (
            <div className='w-full pl-1 space-y-2'>
              <div>Last known location</div>
              {/* <div className={`flex-shrink-0 text-center bg-brand_blue text-brand_green px-2 py-1 rounded-2xl w-full h-full`}>
                <div className='space-y-2'>
                  <div className={`font-medium`}>
                    <span>{id + 1}</span>
                  </div>
                </div>
              </div> */}
              <div className='space-y-1'>
                <div className='flex items-center space-x-1'>
                  <span className='font-medium'>{name}</span>
                </div>
                {/* <div className='flex items-center space-x-1'>
                  <span className='font-medium'>SN:</span>
                  <span>{serialNumber}</span>
                </div> */}
              </div>
            </div>
          ) : (
            <div className='w-full pl-1 space-y-2'>
              <div>Location</div>
              <div className={`flex-shrink-0 text-center bg-brand_blue text-brand_green px-2 py-1 rounded-2xl w-full h-full`}>
                <div className='space-y-2'>
                  <div className={`font-medium`}>
                    <span>{id}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InfoWindow>
      ) : null}

      {/* {activeMarker && id === path.length - 1 ? (
        <InfoWindow>
          <div className='w-full pl-1 space-y-2'>
            <div>Last known location</div>
            <div className={`flex-shrink-0 text-center bg-brand_blue text-brand_green px-2 py-1 rounded-2xl w-full h-full`}>
              <div className='space-y-2'>
                <div className={`font-medium`}>
                  <span>{id + 1}</span>
                </div>
              </div>
            </div>
            <div className='space-y-1'>
              <div className='flex items-center space-x-1'>
                <span className='font-medium'>{name}</span>
              </div>
              <div className='flex items-center space-x-1'>
                <span className='font-medium'>SN:</span>
                <span>{serialNumber}</span>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : activeMarker ? (
        <InfoWindow>
          <div className='w-full pl-1 space-y-2'>
            <div>Location</div>
            <div className={`flex-shrink-0 text-center bg-brand_blue text-brand_green px-2 py-1 rounded-2xl w-full h-full`}>
              <div className='space-y-2'>
                <div className={`font-medium`}>
                  <span>{id}</span>
                </div>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : null} */}
    </Marker>
  );
};

export default AssetMap;
