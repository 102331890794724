import { SET_FILTER } from '../type';

export const setFilter = data => async dispatch => {
  dispatch({
    type: SET_FILTER,
    payload: data,
  });
};

const FilterActions = {
  setFilter,
};

export default FilterActions;
