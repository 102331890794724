import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import bwipjs from 'bwip-js';

import { formatDate } from '../../../../util';

import Placeholder from '../../../../assets/placeholder.png';
import Button from '../../../../components/fragments/button/button';
import Scanner from '../../../../components/fragments/scanner';

const ScanAssets = () => {
  const [obj, setObj] = useState();

  useEffect(() => {
    if (obj && !obj.barcode) {
      let canvas = document.createElement('canvas');
      bwipjs.toCanvas(canvas, {
        bcid: 'qrcode',
        text: `
          Name: ${obj.name}
          Serial Number: ${obj.sn}
          Package Level: ${obj.packageLevel}
          Batch Number: ${obj.batchNumber}
          Expiry Date: ${formatDate(new Date(obj.expiryDate), 'yyMMdd')}
          `,
        scale: 5,
        height: 15,
        width: 15,
        includetext: true,
        textxalign: 'center',
      });
      setObj({ ...obj, barcode: canvas.toDataURL('image/png') });
    }
  }, [obj]);

  // useEffect(() => {
  //   if (!obj) {
  //     setTimeout(() => {
  //       let obj = { sn: '000212', name: 'Ampiclox 250mg', packageLevel: 'Primary', batchNumber: '01-00212224', expiryDate: '2023-08-23' };
  //       setObj(obj);
  //     }, 5000);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <div className={` px-4 sm:px-6 h-full bg-white py-8 sm:pb-16 `}>
      {obj && obj.name ? (
        <div className={`w-full flex flex-col space-y-12 2xl:space-y-0 2xl:space-x-12 2xl:flex-row`}>
          <FadeIn className='flex-1 w-full space-y-4'>
            <div className='font-semibold text-brand_blue'>Product Information</div>
            <img className='2xl:w-full h-72 object-cover rounded-xl' src={Placeholder} alt='asset' />
          </FadeIn>
          <FadeIn className='flex-1 w-full space-y-4 flex flex-col space-y-6 justify-between'>
            <div className='space-y-4 w-full'>
              <div className='font-semibold text-brand_blue'>Other Information</div>
              <div className='flex space-x-4'>
                <div className='h-48 w-48'>{obj.barcode && <img className='h-full w-full object-cover rounded-xl' src={obj.barcode} alt='asset' />}</div>
                <div className={`space-y-2 font-bold`}>
                  <div className={`flex items-center justify-start`}>
                    <span className={`text-gray-400 mr-2`}>Asset Name</span>
                    <span>{obj.name}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    <span className={`text-gray-400 mr-2`}>Serial Number</span>
                    <span>{obj.sn}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    <span className={`text-gray-400 mr-2`}>Package Level</span>
                    <span>{obj.packageLevel}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    <span className={`text-gray-400 mr-2`}>Batch Number</span>
                    <span>{obj.batchNumber}</span>
                  </div>
                  <div className={`flex items-center justify-start`}>
                    <span className={`text-gray-400 mr-2`}>Expiry Date</span>
                    <span>{obj.expiryDate}</span>
                  </div>
                </div>
              </div>
            </div>
            <Button className={'w-96 2xl:w-full'} text={'Check Out'} />
          </FadeIn>
        </div>
      ) : (
        <FadeIn className={`space-y-6 w-full`}>
          <Scanner onScanned={setObj} />
        </FadeIn>
      )}
    </div>
  );
};

export default ScanAssets;
