import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Switch, Route } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

import WarehouseMap from '../../../../components/fragments/warehouse-map';
import Button from '../../../../components/fragments/button';
import Heading from '../../../../components/fragments/heading';
import AssetsTable from '../../../../components/fragments/asset-table';
import { colors } from '../../../../util';

import { AssetActions } from '../../../../states/actions';

const Warehouse = () => {
  const warehouses = useSelector(state => state.warehouses);
  const [assetsInWarehouse, setAssetsInWarehouse] = useState([]);
  const [apiAssets, setApiAssets] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [warehouse, setWarehouse] = useState();
  const [assetIds, setAssetIds] = useState([]);
  const [assetCount, setAssetCount] = useState(0);

  useEffect(() => {
    if (id && warehouse) {
      dispatch(AssetActions.fetchAssetsByWarehouse(id))
        .then(res => {
          setApiAssets(
            res.data.map(d => {
              return { latitude: warehouse.latitude, longitude: warehouse.longitude, live: false, ...d };
            }),
          );
        })
        .catch(console.log);
    }
    // eslint-disable-next-line
  }, [id, warehouse]);

  useEffect(() => {
    if (id && warehouses && warehouses.length > 0) {
      warehouses.forEach(w => {
        if (w.id === id) {
          setWarehouse(w);
        }
      });
    }
  }, [warehouses, id]);

  useEffect(() => {
    if (apiAssets.length > 0) {
      setAssetsInWarehouse(apiAssets);
    }
  }, [apiAssets]);

  useEffect(() => {
    if (assetsInWarehouse.length > 0) {
      setAssetCount(assetsInWarehouse.length);
    }
  }, [assetsInWarehouse]);

  const move = () => {
    if (id && assetIds.length > 0) {
      dispatch(AssetActions.moveAssetsToWarehouse(id, assetIds)).then(() => {
        setAssetIds([]);
        history.push(`/field-configuration/warehouse/${id}`);
      });
    }
  };

  return (
    <Switch>
      <Route exact path={'/field-configuration/warehouse/:id'}>
        <ViewWarehouse warehouse={warehouse} assetsInWarehouse={assetsInWarehouse} assetCount={assetCount} />
      </Route>
      <Route exact path={`/field-configuration/warehouse/:id/move-in-assets`}>
        <FadeIn
          className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
        >
          <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
            <Heading className={`text-brand_blue font-semibold`} title={`Select Assets`} />
            <Button className={`${assetIds.length <= 0 ? `opacity-50 pointer-events-none` : ``}`} text={`Move`} onClick={move} />
          </div>
          <ViewAssets setAssetIds={setAssetIds} />
        </FadeIn>
      </Route>
    </Switch>
  );
};

const ViewAssets = ({ setAssetIds }) => {
  let itemsPerPage = 5;
  const assets = useSelector(state => state.assets);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [currentList, setCurrentList] = useState();

  useEffect(() => {
    if (assets.assets?.length > 0 && list.length > 0) {
      let lt = assets.assets.map(a => {
        let item = '';
        list.forEach(b => {
          if (a.id === b.id) {
            item = b;
          }
        });
        if (item) {
          return item;
        } else {
          return a;
        }
      });
      setCurrentList(lt);
    } else {
      setCurrentList(assets.assets);
    }
    // eslint-disable-next-line
  }, [assets.assets]);

  useEffect(() => {
    if (list?.length) {
      let ids = list.reduce((p, n) => {
        if (n.selected) {
          return [...p, n.id];
        } else {
          return p;
        }
      }, []);
      setAssetIds(ids);
    }
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    dispatch(AssetActions.fetchAssets(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  const onSelect = assetList => {
    if (list.length > 0 && assetList?.length > 0) {
      let lt = list.map(a => {
        let item = '';
        assetList.forEach(b => {
          if (a.id === b.id) {
            item = b;
          }
        });
        if (item) {
          return item;
        } else {
          return a;
        }
      });
      assetList.forEach(c => {
        let exist = lt.some(d => d.id === c.id);
        if (!exist) lt = [...lt, c];
      });
      setList(lt);
    } else {
      if (assetList?.length) {
        setList(assetList);
      }
    }
  };

  return (
    <>
      <AssetsTable data={currentList} nameLink={true} variant={3} onSelect={onSelect} />
      <div className={`flex justify-center`}>
        <ReactPaginate
          className={`flex items-center space-x-6 mx-auto mb-4`}
          nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
          breakLabel='...'
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel='<'
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

const ViewWarehouse = ({ warehouse, assetsInWarehouse, assetCount }) => {
  const history = useHistory();
  const [mapper, setMapper] = useState({});
  // const [show, setShow] = useState(false);

  useEffect(() => {
    if (assetsInWarehouse.length > 0) {
      let r = { ...mapper };
      assetsInWarehouse.forEach((d, i) => {
        if (!r[d.name]) {
          r = { ...r, [d.name]: colors[i] };
        }
      });
      setMapper(r);
    }
    // eslint-disable-next-line
  }, [assetsInWarehouse]);

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex flex-col items-center justify-between space-y-6 xl:space-y-0 xl:flex-row xl:space-x-6 mb-2'>
        <div></div>
        <Button text={'Move In Assets'} onClick={() => history.push(`/field-configuration/warehouse/${warehouse.id}/move-in-assets`)} />
      </div>

      {warehouse && (
        <div className='flex items-start flex-col space-y-6 2xl:space-y-0 2xl:flex-row 2xl:space-x-6'>
          <div className='space-y-4 p-4 border border-brand_blue rounded-md 2xl:max-w-sm w-full'>
            <div className=''>
              <div className='text-gray-400 font-medium'>Name</div>
              <div className='text-brand_blue font-medium'>{warehouse.name}</div>
            </div>
            <div className=''>
              <div className='text-gray-400 font-medium'>Location</div>
              <div className='text-brand_blue font-medium'>
                {warehouse.address}, {warehouse.country}
              </div>
            </div>
            <div className=''>
              <div className='text-gray-400 font-medium'>Number Of Assets</div>
              <div className='text-brand_blue font-medium'>{assetCount}</div>
            </div>
            <div className=''>
              <div className='text-gray-400 font-medium mb-1'>Type Of Assets</div>
              {Object.keys(mapper).length > 0 && (
                <>
                  {Object.keys(mapper).map(key => {
                    return (
                      <div key={key} className='text-brand_blue font-medium mb-1 flex items-center space-x-2'>
                        <div style={{ backgroundColor: `${mapper[key]}` }} className='w-4 h-4'></div>
                        <div>{key}</div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
          {/* <div className='w-full'>
            <img onClick={() => setShow(!show)} className='w-full' src={!show ? Ware1 : Ware2} alt='' />
          </div> */}

          {assetsInWarehouse && assetsInWarehouse.length > 0 ? (
            <WarehouseMap variant={1} assetsInWarehouse={assetsInWarehouse} link={true} />
          ) : (
            <WarehouseMap warehouse={warehouse} />
          )}
        </div>
      )}
    </FadeIn>
  );
};

export default Warehouse;
