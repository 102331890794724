import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useSelector, useDispatch } from 'react-redux';

import { colors } from '../../../util';
import { AnalyticsActions } from '../../../states/actions';

const ChartWithTabs = () => {
  const [type, setType] = useState('all-asset');
  const { assetsAnalytics } = useSelector(state => state.analytics);
  const filter = useSelector(state => state.filter);
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState({
    byMonth: [],
    byType: {},
  });

  useEffect(() => {
    if (assetsAnalytics && assetsAnalytics?.asset_by_type?.length > 0) {
      let srcData = assetsAnalytics?.asset_by_type;
      let barColors = {};
      let preData = {};
      let barData = [];
      srcData.forEach((b, i) => {
        if (!barColors[b.name]) {
          barColors[b.name] = i > 3 ? colors[Math.floor(Math.random() * (colors.length - 3) + 3)] : colors[i];
        }
      });
      srcData.forEach((d, i) => {
        if (preData[d.formattedDate]) {
          preData[d.formattedDate] = [...preData[d.formattedDate], srcData[i]];
        } else {
          preData[d.formattedDate] = [srcData[i]];
        }
      });
      Object.keys(preData).forEach((d, i) => {
        let item = { formattedDate: d };
        preData[d].forEach(f => {
          item[f.name] = Number(f.assetsCount);
        });
        barData.push(item);
      });
      setAnalytics(d => {
        return { ...d, byType: { colors: barColors, data: barData } };
      });
    }
    if (assetsAnalytics && assetsAnalytics?.total_assets_by_month?.length > 0) {
      setAnalytics(d => {
        let items = assetsAnalytics?.total_assets_by_month.map(f => {
          f.assetsCount = Number(f.assetsCount);
          return f;
        });
        return { ...d, byMonth: items };
      });
    }
    // eslint-disable-next-line
  }, [assetsAnalytics]);

  useEffect(() => {
    if (filter && filter.from && filter.to) {
      dispatch(AnalyticsActions.retrieveAssetsAnalytics(filter.from, filter.to));
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <div className={`space-y-4`}>
      <div className='font-semibold text-2xl text-brand_blue'>Total Assets</div>
      <div className='flex items-center space-x-8'>
        <div
          onClick={() => setType('all-asset')}
          className={`px-4 py-2 cursor-pointer rounded-2xl font-medium ${type === 'all-asset' ? `text-white bg-brand_blue` : `text-gray-600`}`}
        >
          All Asset
        </div>
        <div
          onClick={() => setType('by-type')}
          className={`px-4 py-2 cursor-pointer rounded-2xl font-medium ${type === 'by-type' ? `text-white bg-brand_blue` : `text-gray-600`}`}
        >
          By Asset Type
        </div>
      </div>
      {type === 'all-asset' && analytics?.byMonth?.length > 0 && (
        <div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Volume</div>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={analytics.byMonth}>
                  <XAxis dataKey='formattedDate' />
                  <YAxis dataKey='assetsCount' />
                  <Tooltip content={<CustomTooltip type='assets' />} />

                  <Bar barSize={80} dataKey='assetsCount' fill='#46F7AD' />
                </BarChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Date</div>
            </div>
          </div>
        </div>
      )}
      {type === 'by-type' && analytics?.byType?.data?.length > 0 && (
        <div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={analytics.byType.data}>
                  <XAxis dataKey='formattedDate' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip type='by-type' />} />

                  {Object.keys(analytics.byType.colors).map((d, i) => {
                    return <Bar key={i} barSize={80} stackId='a' dataKey={d} fill={analytics.byType.colors[d]} />;
                  })}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CustomTooltip = ({ active, payload, type }) => {
  if (active && payload && payload.length) {
    return (
      <>
        {type === 'by-type' && (
          <div className='bg-white rounded-lg px-8 py-2 shadow'>
            {payload &&
              payload.map((d, i) => {
                return (
                  <div key={i} className='flex space-x-2 items-center'>
                    <div className='text-gray-600 text-sm'>{d.name}</div>
                    <div className='font-semibold text-brand_blue'>{d.value}</div>
                  </div>
                );
              })}
          </div>
        )}
        {type !== 'by-type' && (
          <div className='text-center bg-white rounded-lg px-8 py-2 shadow'>
            <div className='font-semibold text-brand_blue'>{`${type === 'temp' || type === 'humid' ? `${payload[0].value + '˚C'}` : payload[0].value}`}</div>
            <div className='text-gray-600 text-sm'>{`${type === 'temp' || type === 'humid' ? 'Temp' : type === 'assets' ? 'Assets' : 'Responses'}`}</div>
          </div>
        )}
      </>
    );
  }

  return null;
};

export default ChartWithTabs;
