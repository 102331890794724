import http from '../http';

export const createHardware = async data => {
  return http().post('/hardware/order', data);
};

export const retrieveHardwares = async (perPage, page) => {
  return http().get(`/hardware/order?perPage=${perPage}&page=${page}`);
};

export const registerRfid = async data => {
  return http().post(`/rfid`, data);
};

const HardwareService = {
  createHardware,
  retrieveHardwares,
  registerRfid,
};

export default HardwareService;
