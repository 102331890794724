import React, { Fragment, useState, useEffect, useRef, forwardRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import Button from '../button';
import InputBox from '../input-box';
import SelectBox from '../select-box';
import Heading from '../heading';
import { ReactComponent as LogoSVG } from '../../../assets/logo.svg';
import Placeholder from '../../../assets/placeholder.png';

import { ResponseActions } from '../../../states/actions';

const initialInviteData = { email: '', companyRole: '' };

const AppDialog = ({ open, setOpen, type, title, action, data, url, onMoveDetailsAdd }) => {
  const history = useHistory();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [inviteData, setInviteData] = useState(initialInviteData);
  const [assetsToMove, setAssetsToMove] = useState({
    current: {},
    assetsToMove: [],
  });
  const [moveFullPackage, setMoveFullPackage] = useState(false);

  useEffect(() => {
    if (open) {
      setMoveFullPackage(false);
    }
  }, [open]);

  useEffect(() => {
    if (data && type === 'move-assets') {
      setAssetsToMove({
        current: data[0],
        assetsToMove: data.map(d => {
          d.moveDetails = {
            newPackageLevel: '',
            unitsToMove: 0,
            unitsInEach: 0,
            moveFullPackge: false,
          };
          return d;
        }),
      });
    }
    // eslint-disable-next-line
  }, [data]);

  const onPackageLevelChange = e => {
    if (e.target.value === assetsToMove.current.packageLevel) {
      setMoveFullPackage(true);
    } else {
      setMoveFullPackage(false);
    }
    setAssetsToMove({
      current: {
        ...assetsToMove.current,
        moveDetails: {
          ...assetsToMove.current.moveDetails,
          newPackageLevel: e.target.value,
          moveFullPackage: e.target.value === assetsToMove.current.packageLevel,
        },
      },
      assetsToMove: assetsToMove.assetsToMove.map((d, i) => {
        if (i === 0) {
          d.moveDetails.newPackageLevel = e.target.value;
          d.moveDetails.moveFullPackage = e.target.value === assetsToMove.current.packageLevel;
        }
        return d;
      }),
    });
  };

  const onUnitsChange = e => {
    if (Number(e.target.value) > assetsToMove.current?.numberOfSubUnits) {
      return;
    }
    setAssetsToMove({
      current: { ...assetsToMove.current, moveDetails: { ...assetsToMove.current.moveDetails, unitsToMove: e.target.value } },
      assetsToMove: assetsToMove.assetsToMove.map((d, i) => {
        if (i === 0) {
          d.moveDetails.unitsToMove = e.target.value;
        }
        return d;
      }),
    });
  };

  const onUnitsInEachChange = e => {
    setAssetsToMove({
      current: { ...assetsToMove.current, moveDetails: { ...assetsToMove.current.moveDetails, unitsInEach: e.target.value } },
      assetsToMove: assetsToMove.assetsToMove.map((d, i) => {
        if (i === 0) {
          d.moveDetails.unitsInEach = e.target.value;
        }
        return d;
      }),
    });
  };

  const moveFull = () => {
    setMoveFullPackage(true);
    setAssetsToMove({
      current: {
        ...assetsToMove.current,
        moveDetails: { ...assetsToMove.current.moveDetails, newPackageLevel: assetsToMove.current.packageLevel, moveFullPackage: true },
      },
      assetsToMove: assetsToMove.assetsToMove.map((d, i) => {
        if (i === 0) {
          d.moveDetails.newPackageLevel = assetsToMove.current.packageLevel;
          d.moveDetails.moveFullPackage = true;
        }
        return d;
      }),
    });
  };

  const onDone = () => {
    const valid = assetsToMove.assetsToMove.every(d => d.moveDetails.newPackageLevel && (d.moveDetails.moveFullPackage || d.moveDetails.unitsToMove));

    let valid2 = true;

    assetsToMove.assetsToMove.forEach(d => {
      if (d.moveDetails.newPackageLevel === 'Secondary' && !d.moveDetails.moveFullPackage && !d.moveDetails.unitsInEach) {
        valid2 = false;
      }

      if (d.moveDetails.newPackageLevel === 'Tertiary' && !d.moveDetails.moveFullPackage && !d.moveDetails.unitsInEach) {
        valid2 = false;
      }
    });

    if (!valid || !valid2) {
      dispatch(ResponseActions.notify({ title: 'Error', message: 'Incomplete data', type: 'danger' }));
      return;
    }

    onMoveDetailsAdd(assetsToMove.assetsToMove);
  };

  const onSubmit = e => {
    e.preventDefault();
    if (action) {
      if (type === 'user-invite') {
        let send = inviteData.email && inviteData.companyRole;
        if (send) {
          action(inviteData);
          setInviteData(initialInviteData);
          setOpen(false);
        } else {
          dispatch(ResponseActions.notify({ title: 'Error', message: 'Incomplete data', type: 'danger' }));
        }
      }
    }
  };

  const closeDialog = () => {
    setInviteData(initialInviteData);
    setOpen(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setInviteData({ ...inviteData, [name]: value });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span> */}
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div>
              {type === 'user-invite' && (
                <form
                  onSubmit={onSubmit}
                  className='inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-lg w-full p-6'
                >
                  <div className={`w-full`}>
                    <div className='mt-3 text-center sm:mt-5 w-full'>
                      <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                        <Heading className={`text-brand_blue`} title={title} size={2} />
                      </Dialog.Title>
                      <div style={{ minWidth: '300px' }} className='mt-6 space-y-4'>
                        <InputBox
                          placeholder={`Company Email`}
                          name={`email`}
                          type={'email'}
                          value={inviteData.email}
                          onValueChange={handleInputChange}
                          variant={3}
                          required={true}
                        />
                        <InputBox
                          placeholder={`Company Role`}
                          type={'text'}
                          onValueChange={handleInputChange}
                          value={inviteData.companyRole}
                          name={`companyRole`}
                          variant={3}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='mt-5 sm:mt-6 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense w-full'>
                    <Button className={`h-14 w-full`} onClick={closeDialog} text={`Cancel`} type={`secondary`} cx={2} />
                    <Button type={`submit`} className={`h-14 w-full`} text={`Invite`} cx={2} />
                  </div>
                </form>
              )}
              {type === 'transaction' && (
                <div className='inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-lg w-full p-6'>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900 flex justify-center'>
                    <Heading className={`text-brand_blue`} title={title} size={2} />
                  </Dialog.Title>
                  <div className={`w-full my-12 flex justify-center text-gray-500`}>Kind wait while we verify and activate your subscription</div>
                  <div className='mt-5 sm:mt-6 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-flow-row-dense w-full'>
                    <Button className={`h-14 w-full`} onClick={closeDialog} text={`Close`} type={`secondary`} cx={2} />
                  </div>
                </div>
              )}
              {type === 'invoice' && (
                <div className='bg-white pb-12 inline-block overflow-auto shadow-xl transform transition-all mb-8 mt-20 align-middle w-full max-w-3xl space-y-6'>
                  {/*  */}

                  <Invoice ref={componentRef} data={data} />

                  {/*  */}
                  <Button onClick={handlePrint} className={`mx-auto`} text={`Print Now`} />

                  <div onClick={() => history.push(url)} className='underline cursor-pointer'>
                    Done
                  </div>
                </div>
              )}
              {type === 'incomplete-rfid-tags' && (
                <div className='bg-white pb-12 inline-block overflow-auto shadow-xl transform transition-all mb-8 mt-20 align-middle w-full max-w-xl space-y-6'>
                  {/*  */}
                  <h1 className='text-lg font-semibold text-brand_blue mt-8'>Notice</h1>

                  <div className='mt-8'>Some assets don't have tags</div>

                  {/*  */}
                  <Button onClick={() => history.push(url)} className={`mx-auto`} text={`Continue`} />

                  <div onClick={() => history.push('/setup/hardware')} className='underline cursor-pointer'>
                    Order tags
                  </div>
                </div>
              )}
              {type === 'codes' && (
                <div className='bg-white pb-12 inline-block overflow-auto shadow-xl transform transition-all mb-8 mt-20 align-middle space-y-6'>
                  {/*  */}

                  <Stickers ref={componentRef} codes={data} />

                  {/*  */}

                  <Button onClick={handlePrint} className={`mx-auto`} size={2} text={`Print Now`} />

                  {url && (
                    <div onClick={() => history.push(url)} className='underline cursor-pointer'>
                      Done
                    </div>
                  )}
                </div>
              )}
              {type === 'move-assets' && (
                <div className='inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all mb-8 mt-32 align-middle max-w-5xl w-full p-10'>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    <Heading className={`text-brand_blue`} title={'Move Assets'} size={2} />
                  </Dialog.Title>
                  <div className='flex flex-col xl:flex-row w-full my-8 space-y-4 xl:space-y-0 xl:space-x-4'>
                    <div className={`flex-1  w-full`}>
                      <SelectBox
                        options={['', assetsToMove?.current?.packageLevel]}
                        onValueChange={() => {}}
                        value={assetsToMove?.current?.packageLevel ? assetsToMove?.current?.packageLevel : 'Select option'}
                        label={`Current Package Level`}
                        labelColor='text-brand_blue'
                        placeholder={'Select option'}
                        variant={2}
                        disabled={true}
                      />
                    </div>
                    <div className={`flex-1  w-full`}>
                      <SelectBox
                        options={
                          assetsToMove?.current?.packageLevel === 'Secondary'
                            ? ['Primary', 'Secondary']
                            : assetsToMove?.current?.packageLevel === 'Tertiary'
                            ? ['Primary', 'Secondary', 'Tertiary']
                            : []
                        }
                        onValueChange={onPackageLevelChange}
                        value={assetsToMove?.current?.moveDetails?.newPackageLevel ? assetsToMove?.current?.moveDetails?.newPackageLevel : 'Select option'}
                        label={`New Package Level`}
                        labelColor='text-brand_blue'
                        placeholder={'Select option'}
                        variant={2}
                        disabled={!assetsToMove.current?.packageLevel}
                      />
                    </div>
                  </div>
                  <div className='flex justify-between items-center'>
                    <div className={`text-gray-700`}>Select asset and input number of units to move</div>
                    <Button
                      className={`${moveFullPackage || !assetsToMove.current?.packageLevel ? 'opacity-25 pointer-events-none' : ''} h-14`}
                      onClick={moveFull}
                      text={`Move Full Package`}
                      cx={2}
                    />
                  </div>

                  <ul className='my-6 divide-y divide-gray-200'>
                    <div className='flex items-center py-4 space-x-6'>
                      <div className='w-14'></div>
                      <div className='w-40 text-brand_blue'>Asset Name</div>
                      <div className='w-40 text-brand_blue'>No of Sub Units</div>
                      <div className='flex-1'></div>
                      {!moveFullPackage && <div className='w-40 text-brand_blue'>Units To Move</div>}
                      {assetsToMove?.current?.moveDetails?.newPackageLevel === 'Secondary' && !moveFullPackage && (
                        <div className='w-48 text-brand_blue'>Units In Each Package</div>
                      )}
                    </div>
                    {assetsToMove.assetsToMove &&
                      assetsToMove.assetsToMove.length > 0 &&
                      assetsToMove.assetsToMove.map(item => {
                        return (
                          <li key={item.id} className={`pl-4 flex items-center py-4 space-x-4 bg-white shadow'`}>
                            <div className='w-14 h-14 rounded-lg overflow-hidden'>
                              <img className='w-full h-full object-cover' src={Placeholder} alt='' />
                            </div>
                            <div className='w-40 text-brand_blue'>{item?.name}</div>
                            <div className='w-40 text-brand_blue'>{item?.numberOfSubUnits}</div>
                            <div className='flex-1'></div>
                            {!moveFullPackage && (
                              <div className='w-40 text-brand_blue'>
                                <div className={`flex space-x-4 items-center`}>
                                  <input
                                    onChange={onUnitsChange}
                                    className={`text-brand_blue font-semibold p-2 text-lg text-center w-24 bg-transparent border-2 border-gray-200 rounded-lg`}
                                    value={item?.moveDetails?.unitsToMove}
                                    type={`number`}
                                  />
                                </div>
                              </div>
                            )}
                            {assetsToMove?.current?.moveDetails?.newPackageLevel === 'Secondary' && !moveFullPackage && (
                              <div className='w-48 text-brand_blue'>
                                <div className={`flex space-x-4 items-center`}>
                                  <input
                                    onChange={onUnitsInEachChange}
                                    className={`text-brand_blue font-semibold p-2 text-lg text-center w-24 bg-transparent border-2 border-gray-200 rounded-lg`}
                                    value={item?.moveDetails?.unitsInEach}
                                    type={`number`}
                                  />
                                </div>
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                  <div className='flex justify-between'>
                    <Button className={`h-14`} onClick={closeDialog} text={`Cancel`} type='secondary' cx={2} />
                    <Button
                      className={`${
                        assetsToMove.assetsToMove.every(d => d.moveDetails.newPackageLevel && (d.moveDetails.moveFullPackage || d.moveDetails.unitsToMove))
                          ? ''
                          : 'opacity-25 pointer-events-none'
                      } h-14`}
                      onClick={onDone}
                      text={`Done`}
                      cx={2}
                    />
                  </div>
                </div>
              )}

              {type === 'info' && (
                <div className='bg-white pb-12 inline-block overflow-auto shadow-xl transform transition-all mb-8 mt-20 align-middle space-y-6 w-full max-w-md p-6'>
                  <div className='font-semibold text-brand_blue text-lg'>Info</div>
                  <div>{data?.desc}</div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const Stickers = forwardRef(({ codes }, ref) => {
  return (
    <div style={{ zIndex: '-10', width: '192px' }} ref={ref} className='mx-auto top-0'>
      {codes &&
        codes.length > 0 &&
        codes.map((code, idx) => {
          return (
            <div key={idx} style={{ height: '96px', width: '192px', marginTop: '-2.7px' }} className={`w-full h-full text-brand_blue flex pl-4 space-x-2`}>
              <div className={`flex items-center justify-center`}>
                <img width={60} src={code.barcode} alt='' />
              </div>
              <div style={{ fontSize: '7px' }} className={`font-bold flex flex-col justify-center items-start text-black space-y-1 h-full flex-1`}>
                <div className='w-full text-left'>{code.name}</div>
                <div className='w-full text-left'>{code.serialNumber}</div>
                <div className='w-full text-left'>{code.stickerId}</div>
                <div className='w-full text-left'>{code.batchNumber}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
});

const Invoice = forwardRef(({ data }, ref) => {
  const user = useSelector(state => state.user);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data && data.length > 0) {
      let tp = data.reduce((p, n) => {
        return p + n.quantity * n.cost;
      }, 0);
      setTotal(tp);
    }
  }, [data]);

  return (
    <div ref={ref} className='bg-white p-12 space-y-12 overflow-auto'>
      {/*  */}

      <div className='flex justify-between w-full space-x-4 text-sm'>
        <div className='space-y-2 text-left'>
          <div className='uppercase text-blue-600 font-semibold'>Hardware Invoice</div>
          <div className=' text-blue-600'>#258942</div>
        </div>
        <div className='space-y-2 text-right'>
          <div className=''>
            <span className='text-gray-400'>Date: </span>
            <span className='text-gray-400 font-bold'>{format(new Date(), 'dd.MM.yyyy')}</span>
          </div>
          <div className=''>
            <span className='text-gray-400'>Due Date: </span>
            <span className='text-gray-400 font-bold'>
              {format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7), 'dd.MM.yyyy')}
            </span>
          </div>
        </div>
      </div>

      {/*  */}

      <div className='flex justify-between w-full space-x-4 text-sm'>
        <div className='space-y-2 text-left'>
          <div className='uppercase text-gray-400 text-base'>BILL FROM:</div>
          <div className='w-40'>
            <LogoSVG className='w-full' />
          </div>
          <div style={{ maxWidth: '180px' }} className=' text-gray-400 capitalize'>
            9b Onikoyi Lane Parkview Estate, Ikoyi 101223, Lagos.
          </div>
        </div>

        <div className='space-y-4 text-left'>
          <div className='uppercase text-gray-400 text-base'>BILL TO:</div>
          <div className=' text-gray-800 text-2xl capitalize font-medium'>{user?.company?.name}</div>
          <div style={{ maxWidth: '180px' }} className=' text-gray-400 capitalize'>
            {user?.company?.address}, {user?.company?.country}
          </div>
        </div>
      </div>

      {/*  */}

      <div className='space-y-4'>
        <div className='flex text-blue-600 font-medium text-left text-sm'>
          <div className='flex-1'>ITEM</div>
          <div className='w-32'>UNIT COST</div>
          <div className='w-20'>UNIT</div>
          <div className='w-20'>TOTAL</div>
        </div>
        {data.map(d => {
          return (
            <div key={d.name} className='flex text-gray-800 font-medium text-left text-sm'>
              <div className='flex-1'>{d.name}</div>
              <div className='w-32'>{`N ${d.cost}`}</div>
              <div className='w-20'>{d.quantity}</div>
              <div className='w-20'>{`N ${d.cost * d.quantity}`}</div>
            </div>
          );
        })}
        <div style={{ height: '.4px' }} className='bg-gray-100'></div>
        <div className='flex justify-between space-x-4 capitalize text-blue-600'>
          <div>Hardware Total</div>
          <div className='font-semibold'>{`N ${total}`}</div>
        </div>
      </div>

      {/*  */}
    </div>
  );
});

export default AppDialog;
