import http from '../http';

export const retrieveWarehousesWithTopAssets = async count => {
  return http().get(`/analytics/warehouse-with-most-assets?count=${count}`);
};

export const retrieveDaysWithAssets = async (from, to) => {
  return http().get(`/analytics/days-with-assets?from=${from}&to=${to}`);
};

export const retrieveStockCountReports = async (perPage, page, assetName, warehouseId) => {
  return http().get(
    `/analytics/stock-count-reports?perPage=${perPage}&page=${page}${assetName ? `&assetName=${assetName}` : ``}${
      warehouseId ? `&warehouseId=${warehouseId}` : ``
    }`,
  );
};

export const retrieveSurveyPerformace = async (from, to) => {
  return http().get(`/analytics/performance?from=${from}&to=${to}`);
};

export const retrieveAssetsAnalytics = async (from, to, assetName, warehouseId) => {
  return http().get(
    `/analytics/asset-dashboard?${from ? `&from=${from}` : ``}${to ? `&to=${to}` : ``}${assetName ? `&assetName=${assetName}` : ``}${
      warehouseId ? `&warehouseId=${warehouseId}` : ``
    }`,
  );
};

const AnalyticsService = {
  retrieveWarehousesWithTopAssets,
  retrieveDaysWithAssets,
  retrieveStockCountReports,
  retrieveSurveyPerformace,
  retrieveAssetsAnalytics,
};

export default AnalyticsService;
