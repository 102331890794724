import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../../../components/fragments/button';
import SelectBox from '../../../../components/fragments/select-box';

import { AnalyticsActions, ResponseActions } from '../../../../states/actions';

const AllReports = () => {
  const warehouses = useSelector(state => state.warehouses);
  const assets = useSelector(state => state.assets);
  const filter = useSelector(state => state.filter);
  const [warehouseList, setWarehouseList] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetName, setAssetName] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const generateReport = () => {
    if (!assetName && !warehouseId && (!filter.from || !filter.to)) {
      dispatch(ResponseActions.notify({ title: 'No data', message: 'Select an asset or warehouse', type: 'default' }));
      return;
    }

    dispatch(AnalyticsActions.retrieveAssetsAnalytics(filter.from, filter.to, assetName, warehouseId))
      .then(() => {
        history.push(`/im/reports/report`);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (warehouses) {
      let data = warehouses.reduce((p, n) => {
        return [...p, n.name];
      }, []);
      setWarehouseList(data);
    }
  }, [warehouses]);

  useEffect(() => {
    if (assets) {
      let data = assets.assetTypes.reduce((p, n) => {
        return [...p, n.name];
      }, []);
      setAssetTypes(data);
    }
  }, [assets]);

  const onWarehouseSelect = e => {
    e.preventDefault();
    let w = warehouses.find(f => e.target.value === f.name);
    setWarehouseId(w.id);
  };

  const onAssetSelect = e => {
    e.preventDefault();
    setAssetName(e.target.value);
  };

  const getWarehouseName = () => {
    let name = '';
    if (warehouses && warehouses.length > 0) {
      warehouses.forEach(w => {
        if (w.id === warehouseId) {
          name = w.name;
        }
      });
    }
    return name;
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-12 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex flex-col 2xl:flex-row items-start 2xl:items-center bg-white shadow-lg rounded-2xl w-full p-6 space-y-6 2xl:space-y-0 2xl:space-x-6'>
        <div className={`flex-1  w-full`}>
          <SelectBox
            options={assetTypes}
            onValueChange={onAssetSelect}
            value={assetName ? assetName : 'Select asset'}
            // label={`Select asset`}
            // labelColor='text-brand_blue'
            placeholder={`Select asset`}
            variant={2}
          />
        </div>
        <div className={`flex-1  w-full`}>
          <SelectBox
            options={warehouseList}
            onValueChange={onWarehouseSelect}
            value={warehouseId ? getWarehouseName() : 'Select warehouse'}
            // label={`Select warehouse`}
            // labelColor='text-brand_blue'
            placeholder={`Select warehouse`}
            variant={2}
          />
        </div>
        {/* <div className={`flex-1  w-full`}>
          <SelectBox options={['Time Range']} variant={2} value={`Time Range`} />
        </div> */}
        <Button onClick={generateReport} className={`flex-shrink-0`} text={`Generate Report`} />
      </div>

      {/* <Heading className={`text-brand_blue font-semibold pt-6`} title={`Report History`} size={3} />

      {assets?.assets && assets?.assets.length > 0 && <AssetsTable data={assets?.assets.filter((d, i) => i < 4)} variant={1.6} onClick={generateReport} />} */}
    </FadeIn>
  );
};

export default AllReports;
