import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import OnboaardingSteps from './onboaarding-steps';
import ScanAssets from './scan-assets/';

const Home = () => {
  return (
    <Switch>
      <Route exact path={'/im/dashboard'}>
        <OnboaardingSteps />
      </Route>
      <Route exact path={'/im/dashboard/scan-assets'}>
        <ScanAssets />
      </Route>
      <Redirect to={`/404`} />
    </Switch>
  );
};

export default Home;
