import React, { useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';

import SelectBox from '../../../../components/fragments/select-box';
import FinancesMap from '../../../../components/fragments/finances-map';

const AllSites = () => {
  const sites = useSelector(state => state.sites);
  const warehouses = useSelector(state => state.warehouses);
  const [type, setType] = useState('Sites');

  const onItemChange = e => {
    setType(e.target.value);
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <div></div>
        <div className={`flex items-center space-x-6`}>
          <SelectBox options={['Sites', 'Warehouses']} variant={3} onValueChange={onItemChange} value={type} />
        </div>
      </div>
      <FinancesMap items={type === 'Sites' ? sites : warehouses} type={type.toLowerCase()} />
    </FadeIn>
  );
};

export default AllSites;
