import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import SelectBox from '../../../../components/fragments/select-box';
import Button from '../../../../components/fragments/button';
import AssetTable from '../../../../components/fragments/asset-table/asset-table';
import Logo from '../../../../components/fragments/logo/logo';
import Dialog from '../../../../components/fragments/dialog';

import { AssetActions } from '../../../../states/actions';

const UploadedAssets = () => {
  const assets = useSelector(state => state.assets);
  const dispatch = useDispatch();
  const warehouses = useSelector(state => state.warehouses);
  const [warehouseList, setWarehouseList] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const history = useHistory();
  const [currentItems, setCurrentItems] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (warehouses) {
      let data = warehouses.reduce((p, n) => {
        return [...p, n.name];
      }, []);
      setWarehouseList(data);
    }
  }, [warehouses]);

  useEffect(() => {
    if (assets?.onboarding?.assetDetails?.length > 0) {
      setCurrentItems(assets.onboarding.assetDetails);
    } else {
      history.push('/onboarding/get-started');
    }
    if (assets && assets.surveys) {
      let data = assets.surveys.reduce((p, n) => {
        return [...p, n.title];
      }, []);
      setSurveyList(data);
    }
    // eslint-disable-next-line
  }, [assets]);

  const onWarehouseSelect = e => {
    e.preventDefault();
    let w = warehouses.find(f => e.target.value === f.name);
    dispatch(AssetActions.attachWarehouse(w.id));
  };

  const onSurveySelect = e => {
    e.preventDefault();
    let s = assets.surveys.find(f => e.target.value === f.title);
    dispatch(AssetActions.attachSurvey(s.id));
  };

  const onContinue = () => {
    const allTagged = assets.onboarding.assetDetails.every(d => d.macAddress);
    if (allTagged) {
      if (assets?.onboarding?.assetDetails[0]?.surveyId) {
        history.push('/onboarding/onboard-summary');
      } else {
        history.push('/onboarding/setup-stickers');
      }
    } else {
      setOpen(true);
    }
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className={` px-4 sm:px-6 h-full`}>
        <div className={`py-8 sm:py-16 space-y-12 w-full`}>
          <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-between items-start `}>
            <div className='w-full'>
              <Heading className={`text-brand_blue`} title={`Uploaded CSV`} size={2} />
              <div className='flex flex-col justify-between space-y-12 md:flex-row md:space-y-0 md:space-x-4 w-full mt-12'>
                <SelectBox
                  className={`w-max`}
                  labelColor={`text-brand_blue`}
                  label={`Attach warehouse to assets`}
                  placeholder={`Select warehouse`}
                  options={warehouseList}
                  onValueChange={onWarehouseSelect}
                  variant={3}
                />
                <SelectBox
                  className={`w-max`}
                  labelColor={`text-brand_blue`}
                  label={`Attach survey to assets`}
                  placeholder={`Select survey`}
                  options={surveyList}
                  onValueChange={onSurveySelect}
                  variant={3}
                />
              </div>
            </div>
            {/* <Button className={``} text={`Upload More Data`} size={2} onClick={() => history.push('/onboarding/get-started')} /> */}
          </div>
          {currentItems && currentItems.some(d => d.packageLevel === 'Primary') && (
            <>
              <Heading className={`text-brand_blue font-medium`} title={`Primary Level Assets`} size={2} />
              <AssetTable
                data={currentItems}
                variant={'primary'}
                onboarding={true}
                tags={assets.rfidTags.filter(d => !d.status && !d.disabled && d.deviceType === 'BLE_TAG').map(d => d.macAddress)}
                appName={assets.appName}
              />
            </>
          )}
          {currentItems && currentItems.some(d => d.packageLevel === 'Secondary') && (
            <>
              <Heading className={`text-brand_blue font-medium`} title={`Secondary Level Assets`} size={2} />
              <AssetTable
                data={currentItems}
                variant={'secondary'}
                onboarding={true}
                tags={assets.rfidTags.filter(d => !d.status && !d.disabled && d.deviceType === 'BLE_TAG').map(d => d.macAddress)}
                appName={assets.appName}
              />
            </>
          )}
          {currentItems && currentItems.some(d => d.packageLevel === 'Tertiary') && (
            <>
              <Heading className={`text-brand_blue font-medium`} title={`Tertiary Level Assets`} size={2} />
              <AssetTable
                data={currentItems}
                variant={'tertiary'}
                onboarding={true}
                tags={assets.rfidTags.filter(d => !d.status && !d.disabled && d.deviceType === 'BLE_TAG').map(d => d.macAddress)}
                appName={assets.appName}
              />
            </>
          )}
          <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
            <Button text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
            <Button text={`Continue`} onClick={onContinue} />
          </div>
        </div>
      </FadeIn>
      <Dialog
        open={open}
        setOpen={setOpen}
        type='incomplete-rfid-tags'
        url={assets?.onboarding?.assetDetails[0]?.surveyId ? '/onboarding/onboard-summary' : '/onboarding/setup-stickers'}
      />
    </div>
  );
};

export default UploadedAssets;
