import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import SiteMap from '../../../../components/fragments/sites-map';
import Button from '../../../../components/fragments/button';

import { AssetActions } from '../../../../states/actions';
import { colors } from '../../../../util';

const Site = () => {
  const sites = useSelector(state => state.sites);
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [assetsInSite, setAssetsInSite] = useState([]);
  const [liveAssets, setLiveAssets] = useState([]);
  const [apiAssets, setApiAssets] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [site, setSite] = useState();
  const [assetCount, setAssetCount] = useState(0);
  const [mapper, setMapper] = useState({});

  useEffect(() => {
    if (id && site) {
      dispatch(AssetActions.fetchAssetsBySite(id))
        .then(res => {
          setApiAssets(
            res.data.map(d => {
              return { latitude: site.latitude, longitude: site.longitude, live: false, ...d };
            }),
          );
        })
        .catch(console.log);
    }
    // eslint-disable-next-line
  }, [id, site]);

  useEffect(() => {
    let subscription = '';
    if (id) {
      subscription = new window.EventSourcePolyfill(`https://api-supply-chain.chekkit.app/api/v1/asset/${id}/location?type=site`, {
        headers: {
          Authorization: 'Bearer ' + user?.token,
        },
      });

      subscription.addEventListener('message', function (e) {
        let data = JSON.parse(e.data);
        console.log('Live', e);
        if (data && data.length > 0) {
          setLiveAssets(
            data.map(d => {
              return { latitude: Number(d.lat), longitude: Number(d.lng), live: true, ...d.assetDetail };
            }),
          );
        }
      });
    }
    return () => {
      if (subscription) subscription.close();
    };
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id && sites && sites.length > 0) {
      sites.forEach(s => {
        if (s.id === id) {
          setSite(s);
        }
      });
    }
  }, [sites, id]);

  useEffect(() => {
    if (apiAssets.length > 0) {
      setAssetsInSite(apiAssets);
    }
    if (liveAssets.length > 0 && apiAssets.length > 0) {
      let b = '';
      let items = apiAssets.map((a, i) => {
        b = '';
        liveAssets.forEach(c => {
          if (a.id === c.id) {
            b = c;
          }
        });
        if (b) {
          return b;
        } else {
          return a;
        }
      });
      setAssetsInSite(items);
    }
    if (liveAssets.length > 0 && apiAssets.length === 0) {
      setAssetsInSite(liveAssets);
    }
  }, [apiAssets, liveAssets]);

  useEffect(() => {
    if (assetsInSite.length > 0) {
      let r = { ...mapper };
      setAssetCount(assetsInSite.length);
      assetsInSite.forEach((d, i) => {
        if (!r[d.name]) {
          r = { ...r, [d.name]: colors[i] };
        }
      });
      setMapper(r);
    }
    // eslint-disable-next-line
  }, [assetsInSite]);

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex flex-col items-center justify-between space-y-6 xl:space-y-0 xl:flex-row xl:space-x-6 mb-2'>
        <div></div>
        <Button text={'Move In Assets'} onClick={() => history.push(`/field-configuration/site/${site.id}/move-in-assets`)} />
      </div>

      {site && (
        <div className='flex items-start flex-col space-y-6 2xl:space-y-0 2xl:flex-row 2xl:space-x-6'>
          <div className='space-y-4 p-4 border border-brand_blue rounded-md 2xl:max-w-sm w-full'>
            <div className=''>
              <div className='text-gray-400 font-medium'>Name</div>
              <div className='text-brand_blue font-medium'>{site.name}</div>
            </div>
            <div className=''>
              <div className='text-gray-400 font-medium'>Location</div>
              <div className='text-brand_blue font-medium'>
                {site.state}, {site.country}
              </div>
            </div>
            <div className=''>
              <div className='text-gray-400 font-medium'>Number Of Assets</div>
              <div className='text-brand_blue font-medium'>{assetCount}</div>
            </div>
            <div className=''>
              <div className='text-gray-400 font-medium mb-1'>Type Of Assets</div>
              {Object.keys(mapper).length > 0 && (
                <>
                  {Object.keys(mapper).map(key => {
                    return (
                      <div key={key} className='text-brand_blue font-medium mb-1 flex items-center space-x-2'>
                        <div style={{ backgroundColor: `${mapper[key]}` }} className='w-4 h-4'></div>
                        <div>{key}</div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>

          {assetsInSite && assetsInSite.length > 0 ? <SiteMap variant={1} assetsInSite={assetsInSite} link={true} /> : <SiteMap site={site} />}
        </div>
      )}
    </FadeIn>
  );
};

export default Site;
