import OneSignal from 'react-onesignal';
// 963d2dc8-c077-4bf1-aac5-29a738dc6b4c
// 45051752-f11f-401b-aa30-72eb6cd7f0eb
export default async function runOneSignal(user) {
  await OneSignal.init({
    appId: '963d2dc8-c077-4bf1-aac5-29a738dc6b4c',
    allowLocalhostAsSecureOrigin: true
  })
  OneSignal.login(user?.company?.id)
  OneSignal.Slidedown.promptPush();
}
