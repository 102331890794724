import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../heading';
import Button from '../button';
import Logo from '../logo/logo';

import { AssetActions } from '../../../states/actions';

const SurveyPreview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { controller } = useParams();
  const survey = useSelector(state => state.survey);

  const attachSurvey = () => {
    if (!controller) {
      dispatch(AssetActions.updateSurvey(survey));
      history.push('/onboarding/onboard-summary');
    } else {
      dispatch(AssetActions.createSurveys(survey))
      .then(res => {
        history.push('/field-report')
      })
    }
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className={` px-4 sm:px-6  h-full`}>
        <div className={`py-8 sm:py-8  space-y-6 w-full`}>
          <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-between items-center`}>
            <Heading className={`text-brand_blue font-semibold`} title={`Survey Preview`} size={2} />
            <div className={`flex flex-col lg:flex-row items-center justify-center space-x-4`}>
              {survey && survey.surveyQuestions && (
                <span className={`text-brand_blue_light p-2`}>
                  You added {survey.surveyQuestions.length} question{survey.surveyQuestions.length > 1 ? `s` : ``}
                </span>
              )}
              <span onClick={() => history.goBack()} className={`text-brand_blue bg-blue-200 px-4 py-2 rounded-lg cursor-pointer`}>
                Edit survey
              </span>
            </div>
          </div>
          <FadeIn className={`space-y-6`}>
            {survey &&
              survey.surveyQuestions &&
              survey.surveyQuestions.map((item, idx) => {
                return <Item key={idx} item={item} />;
              })}
          </FadeIn>
          <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
            <Button text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
            <Button text={`${controller ? `Create Survey` : `Attach Survey`}`} onClick={attachSurvey} />
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <div className={`bg-gray-100 p-6 rounded-xl space-y-8`}>
      <Heading className={`text-gray-600`} title={item?.question} size={2} />
      <FadeIn className={`grid grid-cols-2 lg:grid-cols-4 gap-6 items-center justify-between w-full`}>
        {item &&
          item.choices &&
          item.choices.map((ops, idx) => {
            return (
              <div className={`flex items-center space-x-2 text-gray-500`} key={idx}>
                <div className={`p-3 w-24 border border-gray-300 rounded-xl text-right font-bold`}>{idx + 1}</div>
                <div>{ops}</div>
              </div>
            );
          })}
      </FadeIn>
    </div>
  );
};

export default SurveyPreview;
