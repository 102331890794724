import { SAVE_SURVEY, ADD_META, ADD_QUESTION, UPDATE_QUESTION, DELETE_QUESTION, RESET_SURVEY, UPDATE_SURVEY } from '../type';

export const saveSurvey = data => async dispatch => {
  dispatch({
    type: SAVE_SURVEY,
    payload: data,
  });
};

export const updateSurvey = data => async dispatch => {
  dispatch({
    type: UPDATE_SURVEY,
    payload: data,
  });
};

export const resetSurvey = () => async dispatch => {
  dispatch({
    type: RESET_SURVEY,
  });
};

export const addMeta = data => async dispatch => {
  dispatch({
    type: ADD_META,
    payload: data,
  });
};

export const addQuestion = data => async dispatch => {
  dispatch({
    type: ADD_QUESTION,
    payload: data,
  });
};

export const updateQuestion = (data, idx) => async dispatch => {
  dispatch({
    type: UPDATE_QUESTION,
    payload: { id: idx, data: data },
  });
};

export const deleteQuestion = idx => async dispatch => {
  dispatch({
    type: DELETE_QUESTION,
    payload: { id: idx },
  });
};

const QuestionActions = {
  saveSurvey,
  resetSurvey,
  updateSurvey,
  addMeta,
  addQuestion,
  updateQuestion,
  deleteQuestion,
};

export default QuestionActions;
