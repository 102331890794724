import React, { useState, useEffect } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

import InputBox from '../../../../components/fragments/input-box';
import Button from '../../../../components/fragments/button';
import AssetsTable from '../../../../components/fragments/asset-table';
import SurveyTable from '../../../../components/fragments/survey-table';

import { AssetActions } from '../../../../states/actions';

const AllAssets = () => {
  const [phrase, setPhrase] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [assetIds, setAssetIds] = useState([]);
  const [surveyId, setSurveyId] = useState('');

  const handleInputChange = event => {
    const { value } = event.target;
    setPhrase(value);
  };

  const attachSurvey = () => {
    if (surveyId && assetIds.length > 0) {
      dispatch(AssetActions.attachSurveyAssets(surveyId, assetIds)).then(() => {
        setAssetIds([]);
        setSurveyId('');
        history.push('/assets');
      });
    }
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <div className={`flex items-center space-x-12`}>
          <div className={`w-80`}>
            <InputBox value={phrase} onValueChange={handleInputChange} name={`phrase`} placeholder={`Search...`} variant={4} className={``} type={`search`} />
          </div>
        </div>
        <Route exact path={'/assets'}>
          <div className={`flex items-center space-x-6`}>
            <Button
              type={`secondary`}
              className={`${assetIds.length <= 0 ? `opacity-50 pointer-events-none` : ``}`}
              text={`Attach Survey`}
              onClick={() => history.push('/assets/attach-survey')}
            />
            <Button className={``} text={`Onboard Assets`} onClick={() => history.push('/onboarding/get-started')} />
          </div>
        </Route>
        <Route exact path={'/assets/attach-survey'}>
          <div className={`flex items-center space-x-6`}>
            <Button className={`${!surveyId ? `opacity-50 pointer-events-none` : ``}`} text={`Attach`} onClick={attachSurvey} />
          </div>
        </Route>
      </div>
      <Switch>
        <Route exact path={'/assets'}>
          <ViewAssets setAssetIds={setAssetIds} />
        </Route>
        <Route exact path={'/assets/attach-survey'}>
          <AttachSurvey surveyId={surveyId} setSurveyId={setSurveyId} />
        </Route>
      </Switch>
    </FadeIn>
  );
};

const ViewAssets = ({ setAssetIds }) => {
  let itemsPerPage = 5;
  const assets = useSelector(state => state.assets);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [currentList, setCurrentList] = useState();

  useEffect(() => {
    if (assets.assets?.length > 0 && list.length > 0) {
      let lt = assets.assets.map(a => {
        let item = '';
        list.forEach(b => {
          if (a.id === b.id) {
            item = b;
          }
        });
        if (item) {
          return item;
        } else {
          return a;
        }
      });
      setCurrentList(lt);
    } else {
      setCurrentList(assets.assets);
    }
    // eslint-disable-next-line
  }, [assets.assets]);

  useEffect(() => {
    if (list?.length) {
      let ids = list.reduce((p, n) => {
        if (n.selected) {
          return [...p, n.id];
        } else {
          return p;
        }
      }, []);
      setAssetIds(ids);
    }
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    dispatch(AssetActions.fetchAssets(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  const onSelect = assetList => {
    if (list.length > 0 && assetList?.length > 0) {
      let lt = list.map(a => {
        let item = '';
        assetList.forEach(b => {
          if (a.id === b.id) {
            item = b;
          }
        });
        if (item) {
          return item;
        } else {
          return a;
        }
      });
      assetList.forEach(c => {
        let exist = lt.some(d => d.id === c.id);
        if (!exist) lt = [...lt, c];
      });
      setList(lt);
    } else {
      if (assetList?.length) {
        setList(assetList);
      }
    }
  };

  return (
    <>
      <AssetsTable
        data={currentList}
        nameLink={true}
        variant={6}
        onSelect={onSelect}
        appName={assets.appName}
        tags={assets.rfidTags.filter(d => !d.status && !d.disabled).map(d => d)}
      />
      <div className={`flex justify-center`}>
        <ReactPaginate
          className={`flex items-center space-x-6 mx-auto mb-4`}
          nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
          breakLabel='...'
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel='<'
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

const AttachSurvey = ({ surveyId, setSurveyId }) => {
  let itemsPerPage = 5;
  const assets = useSelector(state => state.assets);
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(AssetActions.fetchSurveys(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      <SurveyTable data={assets?.surveys} surveyId={surveyId} setSurveyId={setSurveyId} />
      <div className={`flex justify-center`}>
        <ReactPaginate
          className={`flex items-center space-x-6 mx-auto mb-4`}
          nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
          breakLabel='...'
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel='<'
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default AllAssets;
