import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker, Autocomplete } from '@react-google-maps/api';
import { REACT_APP_MAP_API_KEY } from '../../../config';

const libraries = ['places'];

const SiteMap = ({ sites, onSelected }) => {
  const [data, setData] = useState();
  const [selected, setSelected] = useState();
  const [autocomplete, setAutocomplete] = useState(null);
  const [center, setCenter] = useState({ lat: 6.5244, lng: 3.3792 });

  useEffect(() => {
    if (onSelected) onSelected(selected);
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      setData(
        sites.map(d => {
          d.activeMarker = false;
          d.position = { lat: d.latitude, lng: d.longitude };
          return d;
        }),
      );
    }
  }, [sites]);

  useEffect(() => {
    if (sites && sites.length > 0) {
      setInterval(() => {
        setData(data =>
          data.map(marker => {
            marker.position = { lat: marker.position.lat + 0.002, lng: marker.position.lng + 0.002 };
            return marker;
          }),
        );
      }, 1000);
    }
  }, [sites]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: libraries,
    googleMapsApiKey: REACT_APP_MAP_API_KEY,
  });

  const onLoad = React.useCallback(
    function callback(map) {
      if (data && data.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        data.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
        setCenter(data[0].position);
      }
    },
    // eslint-disable-next-line
    [data],
  );

  const handleActiveMarker = marker => {
    if (data && data.length > 0) {
      let lt = data.map(d => {
        if (d.id === marker) {
          d.activeMarker = true;
        } else {
          d.activeMarker = false;
        }
        return d;
      });
      setData(lt);
    }
  };

  const setActiveMarkerNone = () => {
    if (data && data.length > 0) {
      let lt = data.map(d => {
        d.activeMarker = false;
        return d;
      });
      setData(lt);
    }
  };

  const onAutocompleteLoad = autocomplete => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      console.log(autocomplete.getPlace());
    }
  };

  return isLoaded ? (
    <div style={{ height: '600px' }} className={`bg-gray-200 rounded-3xl w-full overflow-hidden`}>
      <GoogleMap
        onClick={() => {
          setSelected(null);
          setActiveMarkerNone();
        }}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={10}
        onLoad={onLoad}
        options={{
          // disableDefaultUI: true,
          // mapTypeControl: false,
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            // {
            //   featureType: 'transit',
            //   elementType: 'labels.icon',
            //   stylers: [{ visibility: 'off' }],
            // },
          ],
        }}
      >
        <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
          <input
            type='text'
            placeholder='Customized your placeholder'
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: 'absolute',
              left: '50%',
              marginLeft: '-120px',
            }}
          />
        </Autocomplete>
        {data &&
          data.length > 0 &&
          data.map(({ id, position, name, address, state, email, phoneNumber, country, activeMarker }) => (
            <MapMarker
              key={id}
              id={id}
              position={position}
              name={name}
              email={email}
              phoneNumber={phoneNumber}
              address={address}
              state={state}
              country={country}
              activeMarker={activeMarker}
              selected={selected}
              setSelected={setSelected}
              handleActiveMarker={handleActiveMarker}
              setActiveMarkerNone={setActiveMarkerNone}
              setCenter={setCenter}
            />
          ))}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

const MapMarker = ({
  id,
  position,
  name,
  email,
  phoneNumber,
  address,
  state,
  country,
  activeMarker,
  selected,
  setSelected,
  handleActiveMarker,
  setActiveMarkerNone,
  setCenter,
}) => {
  return (
    <Marker
      animation={window.google.maps.Animation.DROP}
      draggable={true}
      position={position}
      onClick={() => {
        setSelected(id);
        setCenter(position);
        handleActiveMarker(id);
      }}
      zIndex={activeMarker || selected === id ? 2 : 1}
      onMouseOver={() => {
        if (selected && selected !== id) setSelected(null);
        handleActiveMarker(id);
      }}
      onMouseOut={() => setActiveMarkerNone()}
      icon={{
        path: 'M0.375 10.8962C0.375 5.14639 5.17985 0.499023 10.9918 0.499023C16.8201 0.499023 21.625 5.14639 21.625 10.8962C21.625 13.7936 20.5713 16.4836 18.8369 18.7635C16.9235 21.2784 14.5652 23.4696 11.9107 25.1896C11.3031 25.587 10.7548 25.617 10.0881 25.1896C7.41842 23.4696 5.06011 21.2784 3.16313 18.7635C1.42748 16.4836 0.375 13.7936 0.375 10.8962ZM7.49269 11.2205C7.49269 13.1467 9.06448 14.6616 10.9917 14.6616C12.9202 14.6616 14.5071 13.1467 14.5071 11.2205C14.5071 9.30929 12.9202 7.72058 10.9917 7.72058C9.06448 7.72058 7.49269 9.30929 7.49269 11.2205Z',
        fillColor: activeMarker || selected === id ? '#5F7D95' : '#46F7AD',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: activeMarker || selected === id ? '#46F7AD' : '#5F7D95',
        scale: activeMarker || selected === id ? 1.5 : 1,
        anchor: new window.google.maps.Point(
          11, // width
          28, // height
        ),
      }}
    >
      {activeMarker || selected === id ? (
        <InfoWindow
          onCloseClick={() => {
            setSelected(null);
            setActiveMarkerNone();
          }}
        >
          <div className={`flex-shrink-0 text-brand_blue w-full min-w-40`}>
            <div className={`font-medium text-lg mb-2`}>Site</div>
            <div className='space-y-2'>
              <div className={`font-medium`}>
                <span className='text-gray-500'>{`Name: `}</span>
                <span>{name}</span>
              </div>
              <div className={`font-medium`}>
                <span className='text-gray-500'>{`Email: `}</span>
                <span>{email}</span>
              </div>
              <div className={`font-medium`}>
                <span className='text-gray-500'>{`Phone number: `}</span>
                <span>{phoneNumber}</span>
              </div>
              <div className={`font-medium`}>
                <span className='text-gray-500'>{`State: `}</span>
                <span>{state}</span>
              </div>
              <div className={`font-medium`}>
                <span className='text-gray-500'>{`Country: `}</span>
                <span>{country}</span>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default SiteMap;
