import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'rsuite';

import { formatDate, getToday } from '../../../util';

const DateRange = ({ onChange }) => {
  const [value, setValue] = useState([new Date('2022-01-01'), new Date(getToday())]);

  useEffect(() => {
    if (value && value[0] && value[1]) {
      const from = formatDate(value[0], 'yyyy-MM-dd');
      const to = formatDate(value[1], 'yyyy-MM-dd');
      if (onChange) onChange({ from, to });
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div className={`border border-gray-600 rounded-lg overflow-hidden border-opacity-40 inline-block`}>
      <DateRangePicker appearance='subtle' value={value} onChange={setValue} placeholder='Select Date Range' placement='bottomEnd' />
    </div>
  );
};

export default DateRange;
