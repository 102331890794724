import { FETCH_NOTIFICATIONS, MARK_AS_READ } from '../type';

const initialState = [];

const NotificationsReducer = (notifications = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NOTIFICATIONS:
      return payload;
    case MARK_AS_READ:
      return notifications;
    default:
      return notifications;
  }
};

export default NotificationsReducer;
