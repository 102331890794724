import React from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';

import Placeholder from '../../../assets/placeholder.png';

import Logo from '../logo';
import Heading from '../heading';

const Profile = () => {
  const user = useSelector(state => state.user);

  const history = useHistory();
  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <div onClick={() => history.goBack()} className='text-brand_blue font-semibold my-6 cursor-pointer'>
        &larr; Back
      </div>
      <FadeIn className='space-y-4'>
        <Heading className={`text-brand_blue font-semibold`} title={`Profile Page`} />
        <div className='flex space-x-4 items-center'>
          <div className='w-16 h-16 rounded-full overflow-hidden'>
            <img className='w-full h-full object-cover' src={Placeholder} alt='' />
          </div>
          <div>
            <div className='space-y-1'>
              <div className='text-brand_blue font-medium text-lg'>{user.name}</div>
              <div className='text-brand_blue_light text-sm capitalize'>{user?.companyRole}</div>
            </div>
          </div>
        </div>
        <FadeIn className='mx-6 my-12 space-y-8'>
          <div className='text-brand_blue font-medium text-lg'>Company Details</div>

          <div className='flex items-center space-x-4 max-w-xl w-full'>
            <div className='flex-1 w-full'>
              <div className='text-brand_blue_light'>BUSINESS NAME</div>
              <div className='text-gray-700'>{user.company.name}</div>
            </div>
            <div className='flex-1 w-full'>
              <div className='text-brand_blue_light'>BUSINESS TYPE</div>
              <div className='text-gray-700'>{user.company.identifier}</div>
            </div>
          </div>

          <div className='flex items-center space-x-4 max-w-xl w-full'>
            <div className='flex-1 w-full'>
              <div className='text-brand_blue_light'>BUSINESS ADDRESS</div>
              <div className='text-gray-700'>{user.company.address}</div>
            </div>
            <div className='flex-1 w-full'>
              <div className='text-brand_blue_light'>BUSINESS COUNTRY</div>
              <div className='text-gray-700'>{user.company.country}</div>
            </div>
          </div>
        </FadeIn>
      </FadeIn>
    </div>
  );
};

export default Profile;
