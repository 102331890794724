export default class Site {
  constructor(siteName = '', siteManagerName = '', phoneNumber = '', emailAddress = '', address = '', state = '', country = '', latitude = '', longitude = '') {
    this.siteName = siteName;
    this.siteManagerName = siteManagerName;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.address = address;
    this.state = state;
    this.country = country;
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
