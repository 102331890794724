import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import GetStarted from './get-started';

const FieldConfiguration = () => {
  return (
    <Switch>
      <Route exact path={['/im/warehouses', '/warehouses/ctr/:controller']}>
        <GetStarted />
      </Route>
      <Redirect to={`/404`} />
    </Switch>
  );
};

export default FieldConfiguration;
