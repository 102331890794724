import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import SelectBox from '../../../../components/fragments/select-box';

import Heading from '../../../../components/fragments/heading';
import AssetTable from '../../../../components/fragments/asset-table';

import { AssetActions } from '../../../../states/actions';

const SiteFinance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { site_id } = useParams();
  const sites = useSelector(state => state.sites);
  const assets = useSelector(state => state.assets);
  const [site, setSite] = useState();

  useEffect(() => {
    if (site_id) {
      dispatch(AssetActions.fetchFinances('site', site_id));
    }
    if (site_id && sites) {
      sites.forEach(site => {
        if (site.id === site_id) {
          setSite(site);
        }
      });
    }
    // eslint-disable-next-line
  }, [site_id]);

  const onSiteChange = e => {
    let si = sites.find(d => d.name === e.target.value);
    if (si) {
      history.push(`/finances/sites/${si.id}`);
    }
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <Heading className={`text-brand_blue font-semibold`} title={`Asset in ${site?.state}, ${site?.country}`} size={2} />
        <div className={`flex items-center space-x-6`}>
          <SelectBox options={sites?.map(d => d?.name)} variant={3} value={site?.name} onValueChange={onSiteChange} />
        </div>
      </div>
      <AssetTable data={assets?.finances} variant={1} />
    </FadeIn>
  );
};

export default SiteFinance;
