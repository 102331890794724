import { combineReducers } from 'redux';
import response from './response';
import user from './user';
import warehouses from './warehouses';
import sites from './sites';
import hardwares from './hardwares';
import assets from './assets';
import survey from './survey';
import plans from './plans';
import products from './products';
import analytics from './analytics';
import filter from './filter';
import notifications from './notifications';

export default combineReducers({
  response,
  user,
  warehouses,
  sites,
  hardwares,
  assets,
  survey,
  plans,
  products,
  analytics,
  filter,
  notifications,
});
