import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useSelector, useDispatch } from 'react-redux';

import Placeholder from '../../../../assets/placeholder.png';

import { ReactComponent as TruckFastBlue } from '../../../../assets/truck-fast.svg';
import { ReactComponent as TruckFastGreen } from '../../../../assets/truck-fast-green.svg';
import { ReactComponent as HouseIndigo } from '../../../../assets/house-indigo.svg';
import { ReactComponent as StackBrandBlue } from '../../../../assets/stack-brand-blue.svg';

import { ReactComponent as Truck } from '../../../../assets/truck.svg';
import { ReactComponent as CheckGreen } from '../../../../assets/check-green.svg';
import { ReactComponent as CheckGray } from '../../../../assets/check-gray.svg';

import Heading from '../../../../components/fragments/heading';
import Text from '../../../../components/fragments/text';
import Chart from '../../../../components/fragments/chart';

import { UserActions, AnalyticsActions, AssetActions } from '../../../../states/actions';

const OnboardingSteps = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const filter = useSelector(state => state.filter);
  const assets = useSelector(state => state.assets);
  const analytics = useSelector(state => state.analytics);
  const history = useHistory();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    dispatch(AssetActions.fetchAssets(5, 1));
    dispatch(UserActions.fetchUser());
    dispatch(AssetActions.setApp('im'));
    dispatch(AnalyticsActions.retrieveWarehousesWithTopAssets(5));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filter && filter.from && filter.to) {
      dispatch(AnalyticsActions.retrieveDaysWithAssets(filter.from, filter.to));
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (analytics && analytics.daysWithAssets && analytics.daysWithAssets.length > 0) {
      setChartData(
        analytics.daysWithAssets.map(d => {
          d.assetsCount = Number(d.assetsCount);
          return d;
        }),
      );
    } else {
      setChartData([]);
    } // eslint-disable-next-line
  }, [analytics.daysWithAssets]);

  return (
    <>
      {assets && assets.assets.length > 0 ? (
        <FadeIn className={` px-4 sm:px-6 h-full bg-dots`}>
          <div className={`pb-8 sm:pb-16 space-y-6 w-full bg-white`}>
          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'>
              <Link
                style={{ backgroundColor: '#F2FFFE' }}
                to='/im/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <TruckFastGreen className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Assets</div>
                  <div style={{ color: '#3FB8AF' }} className='font-semibold text-lg'>
                    {user?.company?.totalAssets}
                  </div>
                </div>
              </Link>
              <Link
                style={{ backgroundColor: '#ECEBFF' }}
                to='/im/warehouses'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <HouseIndigo className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Warehouses</div>
                  <div style={{ color: '#392DCA' }} className='font-semibold text-lg'>
                    {user?.company?.totalWarehouses}
                  </div>
                </div>
              </Link>
              <Link
                style={{ backgroundColor: '#EAF6FF' }}
                to='/im/assets'
                className={`p-6 lg:p-10 flex flex-col space-y-2 flex-1  w-full cursor-pointer hover:no-underline rounded-lg overflow-hidden`}
              >
                <div className='bg-blue-100 rounded-lg w-12 h-12'>
                  <StackBrandBlue className='w-full h-full' />
                </div>
                <div className='space-y-2'>
                  <div className='text-gray-600 font-semibold'>Total Asset Type</div>
                  <div style={{ color: '#153853' }} className='font-semibold text-lg'>
                    {assets?.assetTypes?.length}
                  </div>
                </div>
              </Link>
              </div>
            <div className='flex justify-between flex-col 2xl:flex-row space-y-6 2xl:space-y-0 2xl:space-x-6 pt-12'>
              <div className='flex-1 border border-gray-100 rounded-xl p-4'>
                {chartData ? (
                  <Chart type={'assets'} data={chartData} />
                ) : (
                  <div className='text-gray-400 h-full flex justify-center items-center text-sm'>Assets chart show up here when available</div>
                )}
              </div>
              <div className='w-full 2xl:w-2/5'>
                <div className='font-semibold text-2xl text-brand_blue capitalize'>Top Warehouses with most products</div>
                <div className={`flex flex-col justify-between items-center py-12 px-6 space-y-6 `}>
                  {analytics &&
                    analytics.warehousesWithMostAssets &&
                    analytics.warehousesWithMostAssets.length > 0 &&
                    analytics.warehousesWithMostAssets.map(wh => {
                      return (
                        <div key={wh.id} className='flex space-x-4 items-center justify-between text-sm w-full'>
                          <div className='flex space-x-4 items-center'>
                            <div className='w-16 h-16 rounded-xl overflow-hidden'>
                              <img className='w-full h-full object-cover' src={Placeholder} alt='' />
                            </div>
                            <div className='flex flex-col space-y-2 text-brand_blue'>
                              <div className='font-semibold'>{wh.name}</div>
                              <div>{`${wh.assetsCount} Items`}</div>
                            </div>
                          </div>
                          <div className='bg-green-50 p-2 rounded-2xl'>
                            <div className='text-green-500'>{wh.contribution}%</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      ) : (
        <FadeIn className={` px-4 sm:px-6 h-full bg-dots`}>
          <div className={`py-8 sm:py-16 space-y-6 w-full bg-white`}>
            <div className={`flex space-x-1 justify-start items-center`}>
              <Heading className={`text-brand_blue font-semibold`} title={`Onboarding Steps`} />
              {assets.assets.length > 0 && <Heading className={`text-brand_blue font-semibold`} title={`: Completed!`} />}
            </div>
            <div className={`flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 w-full`}>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex items-center  space-x-4`}>
                <div className='bg-blue-100 p-2 rounded-lg w-12 h-12'>
                  <TruckFastBlue className='w-full h-full' />
                </div>
                <div>
                  <div className='text-gray-500'>Total Assets</div>
                  <div className='font-semibold text-lg'>{user?.company?.totalAssets}</div>
                </div>
              </div>
            </div>
            <div className={`flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 w-full`}>
              <div className={`bg-gray-100 flex-1 rounded-lg p-6 lg:p-10 flex flex-col items-center justify-center space-y-6`}>
                <Heading className={`font-medium`} title={`Step 1: Asset onboarding`} size={2} />
                <div className={`w-32 md:w-48`}>
                  <Truck className={`w-full h-full`} />
                </div>
                <div className={`bg-white p-4 rounded-2xl flex items-center justify-between space-x-4 w-full`}>
                  {assets.assets.length > 0 ? (
                    <Text className={`text-brand_blue`} value={`Assets has been onboarding successfully!`} />
                  ) : (
                    <Text
                      className={`text-brand_blue font-semibold underline cursor-pointer`}
                      value={`Onboard assets`}
                      onClick={() => history.push('/im/onboarding/upload-assets')}
                    />
                  )}
                  <div className={`w-8`}>
                    {assets.assets.length > 0 ? <CheckGreen className={`w-full h-full`} /> : <CheckGray className={`w-full h-full`} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      )}
    </>
  );
};

export default OnboardingSteps;
