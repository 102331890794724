import { CREATE_HARDWARE, ADD_RFID_PINS, REGISTER_RFID, FETCH_HARDWARES } from '../type';
import { HardwareService } from '../../services';
import { notify, loading } from './response';

import { UserActions } from '../actions';

export const createHardware = data => async dispatch => {
  dispatch(loading({ loading: true }));
  let list = {
    hardwares: data,
  };
  try {
    const res = await HardwareService.createHardware(list);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: CREATE_HARDWARE,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

export const fetchHardwares = (perPage, page) => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await HardwareService.retrieveHardwares(perPage, page);

    // dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch(notify({ loading: false }));

    dispatch({
      type: FETCH_HARDWARES,
      payload: res.data.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    // dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    dispatch(notify({ loading: false }));
    return Promise.reject(err);
  }
};

export const addRfidPins = data => async dispatch => {
  dispatch({
    type: ADD_RFID_PINS,
    payload: data,
  });
};

export const registerRfid = data => async dispatch => {
  dispatch(loading({ loading: true }));
  try {
    const res = await HardwareService.registerRfid(data);

    dispatch(notify({ title: res.data.status, message: res.data.message, type: 'success', loading: false }));

    dispatch({
      type: REGISTER_RFID,
    });

    return Promise.resolve(res);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(UserActions.signOut());
    }
    dispatch(notify({ title: err.name, message: err.response?.data?.error || err.message, type: 'danger', loading: false }));
    return Promise.reject(err);
  }
};

const HardwareActions = {
  createHardware,
  addRfidPins,
  registerRfid,
  fetchHardwares,
};

export default HardwareActions;
