import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '../../../components/fragments/heading';
import AssetUploadList from '../../../components/fragments/asset-upload-list';
import DragDropZone from '../../../components/fragments/drag-drop-zone';
import Text from '../../../components/fragments/text';
import Button from '../../../components/fragments/button';
import Logo from '../../../components/fragments/logo/logo';
import { formatDate } from '../../../util';
// import { parseISO } from 'date-fns';

import { ResponseActions, AssetActions } from '../../../states/actions';

const UploadAssets = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const assets = useSelector(state => state.assets);
  const [file, setFile] = useState('');
  const [passed, setPassed] = useState(true);
  const [current, setCurrent] = useState(0);
  const [uploadList, setUploadList] = useState();

  useEffect(() => {
    if (assets.packageTypes) {
      setUploadList(assets.packageTypes);
    }
  }, [assets.packageTypes]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text);
      };

      reader.readAsText(file);
    } // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (uploadList) {
      uploadList.forEach((d, i) => {
        if (i === current && d.file && d.data) {
          setFile(d.file);
        }

        if (i === current && !d.file && !d.data) {
          setFile(null);
        }
      });
    }
    // eslint-disable-next-line
  }, [current, uploadList]);

  const toCamel = s => {
    return s.replace(/([-_][a-z])/gi, $1 => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });
  };

  const validateDate = date => {
    if (date) {
      let arr = date.split('-');
      if (arr.length !== 3) return false;
      if (arr[0].length !== 4 || isNaN(arr[0])) return false;
      if ((arr[2].length !== 1 && arr[2].length !== 2) || isNaN(arr[1])) return false;
      if ((arr[2].length !== 1 && arr[2].length !== 2) || isNaN(arr[2])) return false;
      return true;
    }
  };

  const processCSV = (str, delim = ',') => {
    setPassed(true);
    let hds = str.slice(0, str.indexOf('\n')).split(delim);
    hds = hds.map(d => {
      let r = d.replaceAll(/(\r\n|\n|\r)/gm, '');
      return r;
    });
    const headers = hds.map(h => toCamel(h.split(' ').join('_').toLowerCase()));

    let rows = str.slice(str.indexOf('\n') + 1).split('\n');

    rows = rows
      .map(d => {
        let r = d.replaceAll(/(\r\n|\n|\r)/gm, '');
        return r;
      })
      .filter(d => {
        return d.replaceAll(',', '');
      })
      .filter(d => {
        return d.split(',').every(e => e);
      });

    console.table('rows', rows);

    const newArray = rows
      .filter(n => n)
      .map(row => {
        const values = row.split(delim);
        const eachObject = headers.reduce((obj, header, i) => {
          if (header === 'price') {
            obj[header] = Number(values[i]);
            return obj;
          }

          if (header === 'numberOfSubUnits') {
            obj[header] = Number(values[i]);
            return obj;
          }

          if (header === 'expiryDate') {
            if (validateDate(values[i])) {
              obj[header] = formatDate(Date.parse(values[i]), 'yyyy-MM-dd');
              return obj;
            } else {
              try {
                let d = formatDate(new Date(Date.parse(values[i])), 'yyyy-MM-dd');
                if (validateDate(d)) {
                  obj[header] = formatDate(d, 'yyyy-MM-dd');
                  return obj;
                } else {
                  dispatch(ResponseActions.notify({ title: 'Invalid Date Format', message: 'Use a YYYY-MM-DD format', type: 'default' }));
                  setPassed(false);
                }
              } catch (error) {
                // dispatch(ResponseActions.notify({ title: 'Invalid Date Format', message: 'Use a YYYY-MM-DD format', type: 'default' }));
                // setPassed(false);
              }
            }
          }
          if (header === 'packageLevel') {
            const valid = values[i] === 'Primary' || values[i] === 'Secondary' || values[i] === 'Tertiary';
            if (valid) {
              obj[header] = values[i];
              return obj;
            } else {
              dispatch(ResponseActions.notify({ title: 'Invalid Package Level', message: 'Use Primary, Secondary or Tertiary', type: 'default' }));
              setPassed(false);
            }
          }
          obj[header] = values[i].replaceAll(/(\r\n|\n|\r)/gm, '');
          return obj;
        }, {});
        return eachObject;
      });
    let a = uploadList.map((b, i) => {
      if (i === current) {
        b.data = newArray;
        b.file = file;
      }
      return b;
    });
    setUploadList(a);
  };

  const onProceed = () => {
    // Validate CSV
    const valid = uploadList.every(d => d.file && d.data);
    if (!valid) {
      dispatch(ResponseActions.notify({ title: 'Empty', message: 'Upload a valid CSV', type: 'default', loading: false }));
      return;
    }
    let validCsv = true;
    uploadList.forEach((d, i) => {
      let keys = Object.keys(d.data[0]);
      if (
        !keys.includes('name') ||
        !keys.includes('price') ||
        !keys.includes('batchNumber') ||
        !keys.includes('serialNumber') ||
        !keys.includes('expiryDate') ||
        !keys.includes('category') ||
        !keys.includes('packageLevel')
      ) {
        validCsv = false;
      }
    });

    if (!validCsv) {
      dispatch(ResponseActions.notify({ title: 'Invalid CSV', message: 'Use a valid template', type: 'default', loading: false }));
      return;
    }

    if (!passed) {
      dispatch(ResponseActions.notify({ title: 'Invalid Data', message: 'Invalid data in csv', type: 'default', loading: false }));
      return;
    }

    dispatch(AssetActions.updateAssets(uploadList));
    history.push('/im/onboarding/uploaded-assets');
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className={` px-4 sm:px-6  h-full`}>
        <div className={`py-8 sm:py-16 space-y-6`}>
          <Heading className={`font-medium text-brand_blue`} title={`Select asset type and upload`} size={2} />
          <div className={`space-y-12`}>
            <AssetUploadList current={current} setCurrent={setCurrent} data={assets?.packageTypes} />
            <div className={`flex flex-col items-center justify-center`}>
              {/* <div className='flex justify-end w-full max-w-md'>
                <div
                  onClick={() => {
                    setFile(null);
                    setUploadList(null);
                  }}
                  className='px-1 text-xs bg-gray-50 cursor-pointer hover:bg-gray-100 border m-1 rounded-full'
                >
                  clear
                </div>
              </div> */}
              <DragDropZone file={file} onChange={setFile}>
                <Text className={`text-lg font-medium text-brand_blue underline`} value={file && file.name ? file.name : `Select CSV file`} />
                <Text className={`text-sm text-gray-400`} value={`or drop your file here`} />
                <Text className={`text-sm text-gray-400`} value={`(.csv file)`} />
              </DragDropZone>
            </div>
            <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
              <Button text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
              <Button text={`Continue`} onClick={onProceed} />
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default UploadAssets;
