import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import SelectBox from '../../../../components/fragments/select-box';

import Heading from '../../../../components/fragments/heading';
import AssetTable from '../../../../components/fragments/asset-table';

import { AssetActions } from '../../../../states/actions';

const WarehouseFinance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { warehouse_id } = useParams();
  const warehouses = useSelector(state => state.warehouses);
  const assets = useSelector(state => state.assets);
  const [warehouse, setWarehouse] = useState();

  useEffect(() => {
    if (warehouse_id) {
      dispatch(AssetActions.fetchFinances('warehouse', warehouse_id));
    }
    if (warehouse_id && warehouses) {
      warehouses.forEach(warehouse => {
        if (warehouse.id === warehouse_id) {
          setWarehouse(warehouse);
        }
      });
    }
    // eslint-disable-next-line
  }, [warehouse_id]);

  const onWarehouseChange = e => {
    let wh = warehouses.find(d => d.name === e.target.value);
    if (wh) {
      history.push(`/finances/warehouses/${wh.id}`);
    }
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <Heading className={`text-brand_blue font-semibold`} title={`Asset in ${warehouse?.state}, ${warehouse?.country}`} size={2} />
        <div className={`flex items-center space-x-6`}>
          <SelectBox options={warehouses?.map(d => d?.name)} variant={3} value={warehouse?.name} onValueChange={onWarehouseChange} />
        </div>
      </div>
      <AssetTable data={assets?.finances} variant={1} />
    </FadeIn>
  );
};

export default WarehouseFinance;
