import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { MdMenu, MdClose } from 'react-icons/md';
import { Link, useLocation, useHistory, Route } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { RiArrowDownSLine } from 'react-icons/ri';
import { IoNotifications } from 'react-icons/io5';

import Logo from '../fragments/logo';
import Heading from '../fragments/heading';
import Placeholder from '../../assets/placeholder.png';
import DateRange from '../fragments/date-range/date-range';
import { AssetActions, FilterActions } from '../../states/actions';

import { ReactComponent as dashboardIcon } from '../../assets/dashboard.svg';
import { ReactComponent as assetsIcon } from '../../assets/truck-fast-gray.svg';
import { ReactComponent as fieldsIcon } from '../../assets/location-gray.svg';
// import { ReactComponent as surveysIcon } from '../../assets/document-gray.svg';
import { ReactComponent as reportsIcon } from '../../assets/reports-gray.svg';

import Content from './im-content';

import { REACT_APP_SUPPLY_CHAIN_URL } from '../../config';

const navigation = [
  { name: 'Dashboard', href: '/im/dashboard', icon: dashboardIcon, current: false },
  { name: 'Assets', href: '/im/assets', icon: assetsIcon, current: false },
  { name: 'Warehouses', href: '/im/warehouses', icon: fieldsIcon, current: false },
  // { name: 'Stock Count', href: '/im/stock-count', icon: surveysIcon, current: false },
  { name: 'Reports', href: '/im/reports', icon: reportsIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ImBase = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const filter = useSelector(state => state.filter);
  const notifications = useSelector(state => state.notifications);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navItems, setNavItems] = useState(navigation);
  const [currentItem, setCurrentItem] = useState('');

  useEffect(() => {
    dispatch(AssetActions.setApp('im'));
    let arr = location.pathname.split('/');
    let str = arr[arr.length - 1];
    let finalStr = str.replace('-', ' ');
    setCurrentItem({ name: finalStr });
    setNavItems(
      navItems.map(item => {
        item.current = item.name.replace(' ', '-').replace('&', '').replace(' ', '').toLocaleLowerCase().includes(arr[2].toLocaleLowerCase());
        return item;
      }),
      setSidebarOpen(false),
    ); // eslint-disable-next-line
  }, [location]);

  const leave = () => {
    localStorage.removeItem('chekkit-act');
    window.open(REACT_APP_SUPPLY_CHAIN_URL, '_self');
  };

  const onFilterChange = data => {
    dispatch(FilterActions.setFilter(data));
  };

  return (
    <div className='h-screen flex overflow-hidden'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 flex z-40 md:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full bg-gray-100'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    type='button'
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <MdClose className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-1 h-0 pt-6 pb-4 overflow-y-auto'>
                <div className='flex items-center flex-shrink-0 px-6'>
                  <Logo size={150} url='/im' showAppName={true} />
                </div>
                <FadeIn className='mt-4  flex-1 bg-gray-100 space-y-12 flex flex-col'>
                  {navItems.map(item => (
                    <Link
                      style={{ textDecoration: 'none' }}
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-brand_blue text-white' : 'text-gray-400 hover:bg-brand_blue hover:opacity-25 hover:text-white',
                        'group flex items-center p-6 mr-6 text-sm font-medium rounded-r-3xl focus:text-white hover:text-white',
                      )}
                    >
                      <item.icon
                        className={classNames(item.current ? 'text-white' : 'text-gray-400 group-hover:text-white', 'mr-4 flex-shrink-0 h-6 w-6')}
                        aria-hidden='true'
                      />
                      {item.name}
                    </Link>
                  ))}
                </FadeIn>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14'>{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      <div className='hidden md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          <div className='flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-100'>
            <div className='flex-1 flex flex-col pt-6 pb-4 overflow-y-auto'>
              <div className='flex items-center flex-shrink-0 px-6'>
                <Logo size={150} url='/im' showAppName={true} />
              </div>
              <FadeIn className='mt-4 flex-1 bg-gray-100 space-y-10 flex flex-col'>
                {navItems.map(item => (
                  <Link
                    style={{ textDecoration: 'none' }}
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-brand_blue text-white' : 'text-gray-400 hover:bg-brand_blue hover:opacity-25 hover:text-white',
                      'group flex items-center p-6 mr-6 text-sm font-medium rounded-r-3xl focus:text-white hover:text-white',
                    )}
                  >
                    <item.icon
                      className={classNames(item.current ? 'text-white' : 'text-gray-400 group-hover:text-white', 'mr-4 flex-shrink-0 h-6 w-6')}
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-0 flex-1 overflow-hidden'>
        <div className='md:hidden pl-1 pb-2 sm:pl-3 sm:pt-3 flex items-center'>
          <button
            type='button'
            className='-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none '
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <MdMenu className='h-6 w-6' aria-hidden='true' />
          </button>
          <Logo size={150} url='/im' />
        </div>
        <main className='flex-1 relative z-0 overflow-y-auto focus:outline-none bg-dash'>
          {/* Content */}
          <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4  justify-between items-center p-6  w-full bg-white'>
            <div className='flex items-center space-x-8'>
              {location.pathname.split('/').length > 3 && (
                <div onClick={() => history.goBack()} className='text-brand_blue font-semibold my-6 cursor-pointer text-base'>
                  &larr; Back
                </div>
              )}
              {!(currentItem?.name?.length > 30) && <Heading className={`text-brand_blue font-semibold capitalize`} title={`${currentItem?.name}`} />}
            </div>
            <div className='space-x-2 flex items-center'>
              <Route exact path={['/im/dashboard', '/im/reports']}>
                <div className={`flex items-center mr-2`}>
                  <DateRange onChange={onFilterChange} rangeFilter={filter} />
                </div>
              </Route>

              <Menu as='div' className='relative hidden lg:block'>
                <Menu.Button className='bg-gray-100 text-gray-400 p-2 rounded-md cursor-pointer relative'>
                  <IoNotifications className='transform rotate-12' size={18} />
                  {notifications && notifications.filter(d => !d.isRead).length > 0 && (
                    <div className='absolute -top-2 -right-2 w-4 h-4 p-3 flex items-center justify-center font-medium rounded-full bg-red-400 text-white text-xs'>
                      {notifications.filter(d => !d.isRead).length}
                    </div>
                  )}
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items
                    style={{ width: '30rem', maxHeight: '37rem' }}
                    className='origin-top-right absolute right-0 mt-2 rounded-md shadow-lg overflow-auto bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50'
                  >
                    <div className='p-4 flex items-center justify-between space-x-4 border-b border-gray-100'>
                      <div className='font-medium text-gray-500 text-base'>Notifications</div>
                    </div>
                    {notifications && notifications.length > 0 ? (
                      notifications.slice(0, 6).map(item => {
                        return (
                          <Menu.Item key={item.id}>
                            {({ active }) => (
                              <Link
                                style={{ textDecoration: 'none' }}
                                to={`/notifications/${item.id}`}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  !item.isRead ? 'bg-gray-50' : '',
                                  'block p-4 text-sm text-gray-700 focus:text-gray-700 hover:text-gray-700',
                                )}
                              >
                                <div className='space-y-2'>
                                  <div className='flex items-center justify-between space-x-4'>
                                    <div className='font-medium'>{item.title}</div>
                                    <div className='flex space-x-1 text-xs text-gray-500'>
                                      <span>{new Date(item.created_at).toDateString()}</span>
                                      <div>•</div>
                                      <span>{new Date(item.created_at).toTimeString().slice(0, 5)}</span>
                                    </div>
                                  </div>
                                  <div className='line-clamp-1'>{item.content}</div>
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        );
                      })
                    ) : (
                      <div className='text-center p-6 text-gray-500'>No notifications</div>
                    )}
                    {notifications && notifications.length > 0 && (
                      <Link to={'/notifications'} className='block p-4 bg-gray-50 text-brand_blue font-medium text-center cursor-pointer'>
                        View All
                      </Link>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>

              <Menu as='div' className='relative'>
                <Menu.Button className='max-w-xs bg-white rounded-full flex items-center text-sm p-2 space-x-4'>
                  <img className='h-10 w-10 rounded-lg object-cover' src={Placeholder} alt='' />
                  <span className='hidden ml-3 text-gray-500 text-sm font-medium lg:block'>
                    <span className='sr-only'>Open user menu for </span>
                    {user?.name}
                  </span>
                  <RiArrowDownSLine className='hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block' aria-hidden='true' />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                    {/* <Menu.Item>
                      {({ active }) => (
                        <Link
                          style={{ textDecoration: 'none' }}
                          to='/im/dashboard/scan-assets'
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 focus:text-gray-700 hover:text-gray-700')}
                        >
                          Scan
                        </Link>
                      )}
                    </Menu.Item> */}
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          style={{ textDecoration: 'none' }}
                          to='/profile'
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 focus:text-gray-700 hover:text-gray-700')}
                        >
                          Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          style={{ textDecoration: 'none' }}
                          to='/setup/hardware'
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 focus:text-gray-700 hover:text-gray-700')}
                        >
                          Order hardware
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          style={{ textDecoration: 'none' }}
                          to='/onboarding/onboard-progress'
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 focus:text-gray-700 hover:text-gray-700')}
                        >
                          Onboarding progress
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <span onClick={leave} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}>
                          Exit
                        </span>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <Content />
          {/* /End of Content */}
        </main>
      </div>
    </div>
  );
};

export default ImBase;
