import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useSelector } from 'react-redux';

import { ReactComponent as Truck } from '../../../../assets/truck.svg';

import Heading from '../../../../components/fragments/heading';
import Text from '../../../../components/fragments/text';
import Button from '../../../../components/fragments/button';

const AssetOnboard = () => {
  const history = useHistory();
  const { controller } = useParams();
  const assets = useSelector(state => state.assets);
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (assets && assets.assets.length > 0 && controller === 'true') {
      history.push(`/field-configuration/ctr/true`);
    }
    // eslint-disable-next-line
  }, [assets]);

  return (
    <div className='w-full h-screen py-12 px-4 sm:px-6 md:px-8'>
      <FadeIn className={`w-full h-full flex justify-center items-center max-w-4xl mx-auto space-x-6`}>
        <div className={`flex-1 flex flex-col justify-center items-start space-y-6`}>
          <Heading className={`font-medium`} title={`Let's Get You Started`} />
          <Text value={`Hi ${user?.name?.split(' ')[0]}, welcome to your dashboard. To get started lets onboard the assets you have in your warehouse.`} />
          <div className='flex space-x-4 items-center'>
            <Button className={`w-52`} text={`Get Started`} onClick={() => history.push('/onboarding/get-started')} />
            <div
              onClick={() => history.push('/dashboard')}
              className='h-16 flex justify-center items-center text-center text-brand_blue  px-4 py-2 rounded-md hover:underline cursor-pointer'
            >
              Skip &rarr;
            </div>
          </div>
        </div>
        <div className={`hidden flex-1 lg:flex flex-col justify-center items-center p-6`}>
          <Truck className={`h-96 w-96`} />
        </div>
      </FadeIn>
    </div>
  );
};

export default AssetOnboard;
