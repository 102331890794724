import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FadeIn from 'react-fade-in/lib/FadeIn';

import Heading from '../../../components/fragments/heading';
import Text from '../../../components/fragments/text';
import Button from '../../../components/fragments/button';
import AddHardware from '../../../components/fragments/add-hardware';
import Dialog from '../../../components/fragments/dialog';

import { setupData as data } from '../../../app-data';

import { HardwareActions } from '../../../states/actions';

const SetUpHardware = () => {
  const [hardwareData, setHardwareData] = useState([
    {
      name: 'RFID Tags',
      quantity: 0,
      cost: 350,
      desc: 'RFID Tags are used for tagging assets to track',
    },
    {
      name: 'Sticker Printer',
      quantity: 0,
      cost: 350,
      desc: 'Sticker Printers are used to print serialized sticker labels',
    },
    {
      name: 'Gateway Reader',
      quantity: 0,
      cost: 450000,
      desc: 'Gateway Readers are used to track the tags on the asset',
    },
  ]);

  const hardwares = useSelector(state => state.hardwares);
  const dispatch = useDispatch();
  const { controller } = useParams();
  const history = useHistory();
  const [ordered, setOrdered] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (ordered) {
      setOpen(true);
    }
  }, [ordered, open]);

  useEffect(() => {
    if (hardwares && hardwares.length > 0 && controller === 'true' && !ordered) {
      history.push(`/dashboard`);
    }
    // eslint-disable-next-line
  }, [hardwares]);

  const createHardware = () => {
    let hardwareList = hardwareData.filter(hardware => hardware.quantity > 0);

    if (hardwareList.length < 1) {
      history.push(`/onboarding/ctr/true`);
      return;
    }

    dispatch(HardwareActions.createHardware(hardwareList))
      .then(() => {
        setOrdered(true);
      })
      .catch(console.log);
  };

  return (
    <FadeIn className={`flex flex-col space-y-6 p-6 pb-16 min-h-screen w-full mx-auto`}>
      <div
        className={`w-full space-y-6`}
        style={{
          opacity: '.99',
          display: 'grid',
          gridTemplateColumns: '100%',
          gridTemplateRows: '200px 200px 1fr',
        }}
      >
        <div style={{ borderRadius: '80px' }} className={`col-span-full row-start-1 row-end-3 space-y-6 h-full bg-brand_blue p-6 md:p-12 text-center`}>
          <Heading className={`font-semibold text-center text-gray-100 mt-6 slate`} title={data.setupHardware.title} />
          <Text className={`text-center text-gray-100`} value={data.setupHardware.description} />
        </div>
        <div className={`col-span-full w-full row-start-2 row-end-4 z-50 p-6 md:px-16 md:pb-0`}>
          <AddHardware hardwares={hardwareData} setHardwares={setHardwareData} />
        </div>
      </div>
      <Button className={`mx-auto w-96 relative left-3`} text={'Generate Invoice'} onClick={createHardware} />
      {ordered && (
        <Dialog open={open} setOpen={setOpen} type={`invoice`} data={hardwareData.filter(hardware => hardware.quantity > 0)} url={'/onboarding/ctr/true'} />
      )}
    </FadeIn>
  );
};

export default SetUpHardware;
