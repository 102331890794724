import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../logo/logo';

import Button from '../button';
import InputBox from '../input-box';
import Heading from '../heading';

import QuestionList from '../question-list';
import { SurveyActions, ResponseActions } from '../../../states/actions';

const SurveyEdit = () => {
  const dispatch = useDispatch();
  const { controller } = useParams();
  const survey = useSelector(state => state.survey);
  const history = useHistory();
  const [data, setData] = useState({ title: '', content: '' });
  const [isMetaSet, setMeta] = useState(false);

  useEffect(() => {
    if (survey.title) {
      setData({ title: survey.title, content: survey.content });
    }
  }, [survey]);

  const onSurveyChange = e => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onDone = () => {
    if (!isMetaSet) {
      if (data.title) {
        dispatch(SurveyActions.addMeta(data));
        setMeta(true);
      } else {
        dispatch(ResponseActions.notify({ title: 'Error', message: 'No survey title', type: 'danger' }));
      }
    } else if (controller) {
      history.push('/onboarding/create-survey/survey-preview/true');
    } else {
      history.push('/onboarding/create-survey/survey-preview');
    }
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className='w-full px-4 sm:px-6 md:px-8 flex flex-col justify-center min-h-screen bg-dots'>
        <Heading className={`font-semibold text-brand_blue text-center mb-12`} title={`Create Survey`} />
        <div className={`flex flex-col justify-center items-center bg-gray-100 rounded-xl p-4 w-full mx-auto max-w-3xl divide-y divide-gray-300 space-y-6`}>
          {!isMetaSet ? (
            <>
              <FadeIn className={`w-full space-y-6 max-w-md my-12`}>
                <InputBox
                  label={`Survey Title`}
                  name={`title`}
                  value={data?.title}
                  onValueChange={onSurveyChange}
                  labelColor={`text-gray-500`}
                  placeholder={`Type title here...`}
                  variant={3}
                />
                <InputBox
                  label={`Survey Description (optional)`}
                  name={`content`}
                  value={data.content ? data.content : survey.content}
                  onValueChange={onSurveyChange}
                  labelColor={`text-gray-500`}
                  placeholder={`Type content here...`}
                  variant={3}
                />
              </FadeIn>
            </>
          ) : (
            <QuestionList />
          )}
          <div className={`w-full flex justify-between items-end pt-6`}>
            <div>{isMetaSet && <Button text={`Back`} type={`secondary`} size={2} onClick={() => setMeta(false)} />}</div>
            <div className={`flex items-end lg:items-center flex-col space-y-6 lg:flex-row lg:space-y-0  lg:space-x-6`}>
              <div className={`flex items-center space-x-6`}>
                <MdDelete className={`text-gray-600 cursor-pointer`} size={20} />
              </div>
              <Button text={`${!isMetaSet ? `Continue` : `Done`}`} size={2} onClick={onDone} />
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default SurveyEdit;
