import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';

import Heading from '../../../components/fragments/heading';
import Logo from '../../../components/fragments/logo';
import AssetsTable from '../asset-table/asset-table';

import { AssetActions } from '../../../states/actions';

const OnboardProgress = () => {
  let itemsPerPage = 10;
  const assets = useSelector(state => state.assets);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(AssetActions.fetchOnboardProgress(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    setTimeout(() => {
      dispatch(AssetActions.fetchOnboardProgress(itemsPerPage, currentPage))
        .then(res => {
          setPageCount(res.paginationMetaData.numberOfPages);
        })
        .catch(console.log);
    }, 5000);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto bg min-h-screen`}>
      <Logo size={180} />
      <div onClick={() => history.goBack()} className='text-brand_blue font-semibold my-6 cursor-pointer'>
        &larr; Back
      </div>
      <div>
        <Heading className={`text-brand_blue font-semibold`} title={`Onboarding Progress`} />
        <AssetsTable data={assets?.onBoardProgress} variant={7.1} />
        <div className={`flex justify-center`}>
          <ReactPaginate
            className={`flex items-center space-x-6 mx-auto mb-4`}
            nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
            previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
            activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
            breakLabel='...'
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel='<'
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardProgress;
