import { SET_FILTER } from '../type';
import { getToday } from '../../util';

const initialState = { from: `${new Date().getFullYear()}-01-01`, to: getToday() };

const filterReducer = (filter = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTER:
      return { ...filter, ...payload };
    default:
      return filter;
  }
};

export default filterReducer;
