import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Route, Switch } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector, useDispatch } from 'react-redux';
import { MdDelete } from 'react-icons/md';

import Heading from '../../../../components/fragments/heading';
import Button from '../../../../components/fragments/button';
import InputBox from '../../../../components/fragments/input-box';
import QuestionList from '../../../../components/fragments/question-list';

import { AssetActions, SurveyActions, ResponseActions } from '../../../../states/actions';

const SurveyPreview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const survey = useSelector(state => state.survey);
  const [data, setData] = useState({ title: '', content: '' });
  const [isMetaSet, setMeta] = useState(false);

  useEffect(() => {
    if (survey.title) {
      setData({ title: survey.title, content: survey.content });
    }
  }, [survey]);

  const onSurveyChange = e => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onDone = () => {
    if (!isMetaSet) {
      if (data.title) {
        dispatch(SurveyActions.addMeta(data));
        setMeta(true);
      } else {
        dispatch(ResponseActions.notify({ title: 'Error', message: 'No survey title', type: 'danger' }));
      }
    } else {
      dispatch(AssetActions.updateExistingSurvey(id, survey)).then(res => {
        history.push('/field-report');
      });
    }
  };

  return (
    <Switch>
      <Route exact path={'/field-report/survey/:id/preview-survey'}>
        <FadeIn className={` px-4 sm:px-6  h-full bg-white`}>
          <div className={`pb-8 sm:pb-8  space-y-6 w-full`}>
            <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-between items-center`}>
              <div></div>
              <div className={`flex flex-col lg:flex-row items-center justify-center space-x-4`}>
                {survey && survey.surveyQuestions && (
                  <span className={`text-brand_blue_light p-2`}>
                    Survey has {survey.surveyQuestions.length} question{survey.surveyQuestions.length > 1 ? `s` : ``}
                  </span>
                )}
                <span
                  onClick={() => history.push(`/field-report/survey/${id}/update-survey`)}
                  className={`text-brand_blue bg-blue-200 px-4 py-2 rounded-lg cursor-pointer`}
                >
                  Edit survey
                </span>
              </div>
            </div>
            <FadeIn className={`space-y-6`}>
              {survey &&
                survey.surveyQuestions &&
                survey.surveyQuestions.map((item, idx) => {
                  return <Item key={idx} item={item} />;
                })}
            </FadeIn>
          </div>
          {/* <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-between items-center`}>
            <Heading className={`text-brand_blue font-semibold`} title={`Survey Responses`} size={2} />
          </div> */}
        </FadeIn>
      </Route>
      <Route exact path={'/field-report/survey/:id/update-survey'}>
        <FadeIn className={` px-4 sm:px-6 pb-8 w-full space-y-12 bg-white h-full`}>
          <div className={`flex flex-col justify-center items-center bg-gray-100 rounded-xl p-4 w-full mx-auto divide-y divide-gray-300 space-y-6`}>
            {!isMetaSet ? (
              <>
                <FadeIn className={`w-full space-y-6 max-w-md my-12`}>
                  <InputBox
                    label={`Survey Title`}
                    name={`title`}
                    value={data?.title}
                    onValueChange={onSurveyChange}
                    labelColor={`text-gray-500`}
                    placeholder={`Type title here...`}
                    variant={3}
                  />
                  <InputBox
                    label={`Survey Description (optional)`}
                    name={`content`}
                    value={data.content ? data.content : survey.content}
                    onValueChange={onSurveyChange}
                    labelColor={`text-gray-500`}
                    placeholder={`Type content here...`}
                    variant={3}
                  />
                </FadeIn>
              </>
            ) : (
              <QuestionList />
            )}
            <div className={`w-full flex justify-between items-end pt-6`}>
              <div>{isMetaSet && <Button text={`Back`} type={`secondary`} size={2} onClick={() => setMeta(false)} />}</div>
              <div className={`flex items-end lg:items-center flex-col space-y-6 lg:flex-row lg:space-y-0  lg:space-x-6`}>
                <div className={`flex items-center space-x-6`}>
                  <MdDelete className={`text-gray-600 cursor-pointer`} size={20} />
                </div>
                <Button text={`${!isMetaSet ? `Continue` : `Done`}`} size={2} onClick={onDone} />
              </div>
            </div>
          </div>
        </FadeIn>
      </Route>
    </Switch>
  );
};

const Item = ({ item }) => {
  return (
    <div className={`bg-gray-100 p-6 rounded-xl space-y-8`}>
      <Heading className={`text-gray-600`} title={item?.question} size={2} />
      <FadeIn className={`grid grid-cols-2 lg:grid-cols-4 gap-6 items-center justify-between w-full`}>
        {item &&
          item.choices &&
          item.choices.map((ops, idx) => {
            return (
              <div className={`flex items-center space-x-2 text-gray-500`} key={idx}>
                <div className={`p-3 w-24 border border-gray-300 rounded-xl text-right font-bold`}>{idx + 1}</div>
                <div>{ops}</div>
              </div>
            );
          })}
      </FadeIn>
    </div>
  );
};

export default SurveyPreview;
