import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AssetOnboard from './asset-onboard';
import GetStarted from './get-started';
import UploadAssets from './upload-assets';
import UploadedAssets from './uploaded-assets';
import OnboardSummary from './onboard-summary';
import OnboardSuccess from './onboard-success';

const Onboarding = () => {
  const assets = useSelector(state => state.assets);
  const history = useHistory();

  useEffect(() => {
    if (assets?.packageTypes?.length < 1) {
      history.replace('/im/onboarding/get-started');
    }
    // eslint-disable-next-line
  }, [assets.packageTypes]);
  return (
    <div className='bg'>
      <Switch>
        <Route exact path={['/im/onboarding', '/im/onboarding/ctr/:controller']}>
          <AssetOnboard />
        </Route>
        <Route exact path={'/im/onboarding/get-started'}>
          <GetStarted />
        </Route>
        <Route exact path={'/im/onboarding/upload-assets'}>
          <UploadAssets />
        </Route>
        <Route exact path={'/im/onboarding/uploaded-assets'}>
          <UploadedAssets />
        </Route>
        <Route exact path={'/im/onboarding/onboard-summary'}>
          <OnboardSummary />
        </Route>
        <Route exact path={'/im/onboarding/onboarding-success'}>
          <OnboardSuccess />
        </Route>
        <Redirect to={`/404`} />
      </Switch>
    </div>
  );
};

export default Onboarding;
