import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useSelector } from 'react-redux';

import Heading from '../../../../components/fragments/heading';
import Chart from '../../../../components/fragments/chart';
import { colors } from '../../../../util';

const Report = () => {
  const { assetsAnalytics } = useSelector(state => state.analytics);
  const [analytics, setAnalytics] = useState({
    byMonth: [],
    byType: {},
  });

  useEffect(() => {
    if (assetsAnalytics && assetsAnalytics?.asset_by_type?.length > 0) {
      let srcData = assetsAnalytics?.asset_by_type;
      let barColors = {};
      let preData = {};
      let barData = [];
      srcData.forEach((b, i) => {
        if (!barColors[b.name]) {
          barColors[b.name] = i > 3 ? colors[Math.floor(Math.random() * (colors.length - 3) + 3)] : colors[i];
        }
      });
      srcData.forEach((d, i) => {
        if (preData[d.formattedDate]) {
          preData[d.formattedDate] = [...preData[d.formattedDate], srcData[i]];
        } else {
          preData[d.formattedDate] = [srcData[i]];
        }
      });
      Object.keys(preData).forEach((d, i) => {
        let item = { formattedDate: d };
        preData[d].forEach(f => {
          item[f.name] = Number(f.assetsCount);
        });
        barData.push(item);
      });
      setAnalytics(d => {
        return { ...d, byType: { colors: barColors, data: barData } };
      });
    }
    if (assetsAnalytics && assetsAnalytics?.total_assets_by_month?.length > 0) {
      setAnalytics(d => {
        let items = assetsAnalytics?.total_assets_by_month.map(f => {
          f.assetsCount = Number(f.assetsCount);
          return f;
        });
        return { ...d, byMonth: items };
      });
    }
    // eslint-disable-next-line
  }, [assetsAnalytics]);

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-12 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4  justify-between items-center'>
        <Heading className={`text-brand_blue font-semibold`} title={`Hey, your report is ready`} />
      </div>

      <div className='w-full space-y-12'>
        {analytics.byMonth && analytics.byMonth.length > 0 && (
          <div className='border border-gray-100 rounded-xl p-4'>
            <Chart type={'single-bar'} data={analytics.byMonth} />
          </div>
        )}
        {analytics.byType && Object.keys(analytics.byType).length > 0 && (
          <div className='border border-gray-100 rounded-xl p-4 w-full flex-1'>
            <Chart title={'Percentage Total Asset'} type={'stacked-bar'} data={analytics.byType} />
          </div>
        )}
      </div>
    </FadeIn>
  );
};

export default Report;
