let inititalChoices = [];

for (let i = 1; i <= 2; i++) {
  inititalChoices.push('');
}

export default class Question {
  constructor(question = '', choices = inititalChoices) {
    this.question = question;
    this.choices = choices;
  }
}
