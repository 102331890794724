import React from 'react';
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { CSVLink } from 'react-csv';

import Button from '../button';

const Chart = ({ data, type, title }) => {
  return (
    <>
      {type === 'survey' && (
        <div>
          <div className='font-semibold text-2xl text-brand_blue'>Top Survey Performance</div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Number of engagement</div>
            <div style={{ width: '100%', height: 600 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <AreaChart data={data}>
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey='title' />
                  <YAxis dataKey='numberOfResponse' />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    strokeLinecap='round'
                    type='monotone'
                    dataKey='numberOfResponse'
                    stroke='#153853'
                    strokeWidth='4'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                    activeDot={{ r: 6 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Surveys</div>
            </div>
          </div>
        </div>
      )}
      {type === 'assets' && (
        <div>
          <div className='font-semibold text-2xl text-brand_blue'>Asset Quantity</div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Number of Assets</div>
            <div style={{ width: '100%', height: '500px' }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <AreaChart data={data}>
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey='date' />
                  <YAxis dataKey='assetsCount' />
                  <Tooltip content={<CustomTooltip type={'assets'} />} />
                  <Area
                    strokeLinecap='round'
                    type='monotone'
                    dataKey='assetsCount'
                    stroke='#153853'
                    strokeWidth='4'
                    fillOpacity={1}
                    fill='url(#colorUv)'
                    activeDot={{ r: 6 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Date</div>
            </div>
          </div>
        </div>
      )}
      {type === 'temp' && (
        <div>
          <div className='font-semibold text-2xl text-brand_blue'>Temperature Chart</div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Temp ˚C</div>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={data}>
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey='day' />
                  <YAxis dataKey='temp' />
                  <Tooltip content={<CustomTooltip type='temp' />} />
                  <Bar type='monotone' dataKey='temp' fillOpacity={1} fill='url(#colorUv)' />
                </BarChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Day</div>
            </div>
          </div>
        </div>
      )}
      {type === 'humid' && (
        <div>
          <div className='font-semibold text-2xl text-brand_blue'>Humidity Chart</div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Humidity ˚C</div>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={data}>
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#153853' stopOpacity={0.8} />
                      <stop offset='95%' stopColor='#153853' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey='day' />
                  <YAxis dataKey='humid' />
                  <Tooltip content={<CustomTooltip type='humid' />} />
                  <Bar type='monotone' dataKey='humid' fillOpacity={1} fill='url(#colorUv)' />
                </BarChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Day</div>
            </div>
          </div>
        </div>
      )}
      {type === 'single-bar' && (
        <div>
          <div className='flex items-center space-x-4 justify-between'>
            <div className='font-semibold text-2xl text-brand_blue'>Total Assets</div>
            <CSVLink
              className='flex-shrink-0'
              headers={[
                { label: 'FORMATTED MONTH', key: 'formattedDate' },
                { label: 'ASSET VOLUME', key: 'assetsCount' },
              ]}
              data={data}
            >
              <Button size={2} className={`flex-shrink-0`} text={`Download CSV`} />
            </CSVLink>
          </div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Volume</div>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={data}>
                  <XAxis dataKey='formattedDate' />
                  <YAxis dataKey='assetsCount' />
                  <Tooltip content={<CustomTooltip type='assets' />} />

                  <Bar barSize={80} dataKey='assetsCount' fill='#46F7AD' />
                </BarChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Date</div>
            </div>
          </div>
        </div>
      )}
      {type === 'double-bar' && (
        <div>
          <div className='font-semibold text-2xl text-brand_blue'>Asset Chart</div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div className='vertical-lr transform rotate-180 font-medium text-lg text-brand_blue'>Volume</div>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={data}>
                  <XAxis dataKey='month' />
                  <YAxis dataKey='checkedIn' />
                  <YAxis dataKey='checkedOut' />
                  <Tooltip content={<CustomTooltip type='double-bar' />} />
                  {/* <Legend className='bg-red-300' strokeLinejoin='round' strokeLinecap='round' /> */}

                  <Bar barSize={80} dataKey='checkedIn' fill='#46F7AD' />
                  <Bar barSize={80} dataKey='checkedOut' fill='#153853' />
                </BarChart>
              </ResponsiveContainer>
              <div className='font-medium text-lg text-brand_blue'>Months</div>
            </div>
          </div>
        </div>
      )}
      {type === 'stacked-bar' && (
        <div>
          <div className='flex items-center space-x-4 justify-between'>
            <div className='font-semibold text-2xl text-brand_blue'>{title}</div>
            <CSVLink
              className='flex-shrink-0'
              headers={Object.keys(data.data[0]).map(d => {
                return { label: d.toUpperCase(), key: d };
              })}
              data={data.data}
            >
              <Button size={2} className={`flex-shrink-0`} text={`Download CSV`} />
            </CSVLink>
          </div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <BarChart data={data.data}>
                  <XAxis dataKey='formattedDate' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip type='stacked-bar' />} />

                  {Object.keys(data.colors).map((d, i) => {
                    return <Bar key={i} barSize={80} stackId='a' dataKey={d} fill={data.colors[d]} />;
                  })}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
      {type === 'pie' && (
        <div>
          <div className='font-semibold text-2xl text-brand_blue'>{title}</div>
          <div className={`overflow-auto flex 2xl:justify-center items-center py-12`}>
            <div style={{ width: '100%', height: 400 }} className='text-center space-y-6'>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    isAnimationActive={false}
                    data={data}
                    label={renderCustomizedLabel}
                    cx='50%'
                    cy='50%'
                    labelLine={false}
                    outerRadius={150}
                    fill='#8884d8'
                    dataKey='value'
                  >
                    {data.map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                  </Pie>

                  <Tooltip content={<CustomTooltip type='pie' />} />
                  {/* <Legend strokeLinejoin='round' strokeLinecap='round' /> */}
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text className='bg-white' x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltip = ({ active, payload, type }) => {
  if (active && payload && payload.length) {
    return (
      <>
        {type === 'double-bar' && (
          <div className='bg-white rounded-lg px-8 py-2 shadow'>
            <div className='flex space-x-2 items-center'>
              <div className='text-gray-600 text-sm'>Checked In:</div>
              <div className='font-semibold text-brand_blue'>{payload[0].value}</div>
            </div>
            <div className='flex space-x-2 items-center'>
              <div className='text-gray-600 text-sm'>Checked Out:</div>
              <div className='font-semibold text-brand_blue'>{payload[1].value}</div>
            </div>
          </div>
        )}
        {type === 'stacked-bar' && (
          <div className='bg-white rounded-lg px-8 py-2 shadow'>
            {payload &&
              payload.map((d, i) => {
                return (
                  <div key={i} className='flex space-x-2 items-center'>
                    <div className='text-gray-600 text-sm'>{d.name}</div>
                    <div className='font-semibold text-brand_blue'>{d.value}</div>
                  </div>
                );
              })}
          </div>
        )}
        {type === 'pie' && (
          <div className='bg-white rounded-lg px-8 py-2 shadow'>
            <div className='flex space-x-2 items-center'>
              <div className='text-gray-600 text-sm'>{payload[0].name}:</div>
              <div className='font-semibold text-brand_blue'>{payload[0].value}</div>
            </div>
          </div>
        )}
        {type !== 'double-bar' && type !== 'stacked-bar' && type !== 'pie' && (
          <div className='text-center bg-white rounded-lg px-8 py-2 shadow'>
            <div className='font-semibold text-brand_blue'>{`${type === 'temp' || type === 'humid' ? `${payload[0].value + '˚C'}` : payload[0].value}`}</div>
            <div className='text-gray-600 text-sm'>{`${type === 'temp' || type === 'humid' ? 'Temp' : type === 'assets' ? 'Assets' : 'Responses'}`}</div>
          </div>
        )}
      </>
    );
  }

  return null;
};

export default Chart;
