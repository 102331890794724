// export const apiUrl = 'https://api-supply-chain.chekkit.app/api/v1';
export const apiUrl = 'https://supply-chain-backend.azurewebsites.net/api/v1';

export const REACT_APP_MAP_API_KEY = 'AIzaSyBIhKt7YhWM0qAcLZrIAJGmrqnJLs8dT8E';

export const REACT_APP_SUPPLY_CHAIN_URL = 'https://chektrack.com/#';

// export const REACT_APP_SUPPLY_CHAIN_URL = 'http://localhost:3002/#';

export const REACT_APP_CONNECT_STRING =
  'InstrumentationKey=01667578-0def-4cdd-a366-d9b748aef3ae;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';

export const APPINSIGHTS_INSTRUMENTATIONKEY = '96272af3-f429-4f81-9822-ab2d339fd184';
