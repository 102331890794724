import { SAVE_SURVEY, ADD_META, ADD_QUESTION, UPDATE_QUESTION, DELETE_QUESTION, RESET_SURVEY, UPDATE_SURVEY } from '../type';
import { Question } from '../../models';

const initialState = { title: '', content: '', surveyQuestions: [new Question()], numberOfQuestions: 1 };

const questionReducer = (survey = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_SURVEY:
      return { ...survey, surveyQuestions: payload, numberOfQuestions: payload.length };
    case UPDATE_SURVEY:
      return {
        id: payload.id,
        title: payload.title,
        content: payload.content,
        surveyQuestions: payload?.questions?.map(d => {
          if (typeof d.choices === 'string') {
            d.choices = JSON.parse('[' + d.choices.split('[')[1].split(']')[0] + ']');
          }
          return d;
        }),
        numberOfQuestions: payload.numberOfQuestions,
      };
    case RESET_SURVEY:
      return initialState;
    case ADD_META:
      return { ...survey, title: payload.title, content: payload.content };
    case ADD_QUESTION:
      return { ...survey, surveyQuestions: [...survey.surveyQuestions, payload], numberOfQuestions: survey.surveyQuestions.length + 1 };
    case UPDATE_QUESTION:
      let update = survey?.surveyQuestions?.map((question, idx) => {
        if (payload.id === idx) {
          return payload.data;
        }
        return question;
      });
      return { ...survey, surveyQuestions: update };
    case DELETE_QUESTION:
      let newList = survey.surveyQuestions.filter((question, idx) => {
        return payload.id !== idx;
      });
      return { ...survey, surveyQuestions: newList, numberOfQuestions: survey.surveyQuestions.length - 1 };
    default:
      return survey;
  }
};

export default questionReducer;
