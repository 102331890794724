import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoImage } from '../../../assets/logo.svg';
import { useSelector } from 'react-redux';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

const Logo = ({ size, url, showAppName }) => {
  const assets = useSelector(state => state.assets);
  const history = useHistory();
  const [isDropped, setIsDropped] = useState(false);
  return (
    <div className='w-full'>
      <div className='relative w-full'>
        {showAppName && (
          <div className='absolute right-0 h-full flex items-center'>
            {isDropped ? (
              <MdArrowDropUp onClick={() => setIsDropped(false)} className='text-lg cursor-pointer' />
            ) : (
              <MdArrowDropDown onClick={() => setIsDropped(true)} className='text-lg cursor-pointer' />
            )}
          </div>
        )}
        <LogoImage
          onClick={() => history.push(`${url ? url + '/dashboard' : assets.appName === 'as' ? '/dashboard' : '/im/dashboard'}`)}
          width={size}
          className={`cursor-pointer`}
        />
        {showAppName && (
          <div className='flex-shrink-0 absolute left-12 bottom-1 text-xs text-gray-500'>
            {assets.appName === 'as' ? 'Asset management' : 'Inventory management'}
          </div>
        )}
      </div>
      {showAppName && isDropped && (
        <div
          onClick={() => history.push(`${assets.appName === 'as' ? '/im/dashboard' : '/dashboard'}`)}
          className='w-full bg-brand_blue p-4 bg-opacity-10 hover:bg-opacity-20 cursor-pointer rounded-md mt-2'
        >
          <div className='flex-shrink-0 text-xs text-gray-500'>{assets.appName === 'as' ? 'Inventory management' : 'Asset management'}</div>
        </div>
      )}
    </div>
  );
};

export default Logo;
