import React from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { useSelector, useDispatch } from 'react-redux';

import Heading from '../../../components/fragments/heading';
import Button from '../../../components/fragments/button';
import AssetTable from '../../../components/fragments/asset-table';
import Logo from '../../../components/fragments/logo/logo';

import { AssetActions } from '../../../states/actions';

const OnboardSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const assets = useSelector(state => state.assets);

  const onboardAssets = () => {
    dispatch(AssetActions.onboardAssets({ assetDetails: assets.onboarding.assetDetails })).then(res => {
      history.push('/im/onboarding/onboarding-success');
    });
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className={` px-4 sm:px-6 h-full`}>
        <div className={`py-8 sm:py-16 space-y-6 w-full`}>
          <div className={`flex flex-col sm:flex-row space-y-4 sm:space-x-4 justify-start items-center`}>
            <Heading className={`text-brand_blue font-semibold`} title={`Onboard Summary`} size={2} />
          </div>
          <AssetTable data={assets.onboarding.assetDetails} variant={7} appName={assets.appName} />
          <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
            <Button text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
            <Button text={`Proceed To Onboard Assets`} onClick={onboardAssets} />
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default OnboardSummary;
