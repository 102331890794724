import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

import SurveyTable from '../../../../components/fragments/survey-table';
import Button from '../../../../components/fragments/button';
import Chart from '../../../../components/fragments/chart';

import { AssetActions, AnalyticsActions } from '../../../../states/actions';

const AllSurvey = () => {
  let itemsPerPage = 5;
  const assets = useSelector(state => state.assets);
  const filter = useSelector(state => state.filter);
  const analytics = useSelector(state => state.analytics);
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (filter && filter.from && filter.to) {
      dispatch(AnalyticsActions.retrieveSurveyPerformace(filter.from, filter.to));
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (analytics && analytics.surveyPerformance && analytics.surveyPerformance.length > 0) {
      setChartData(
        analytics.surveyPerformance.map(a => {
          a.numberOfResponse = Number(a.numberOfResponse);
          return a;
        }),
      );
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line
  }, [analytics.surveyPerformance]);

  useEffect(() => {
    dispatch(AssetActions.fetchSurveys(itemsPerPage, currentPage))
      .then(res => {
        setPageCount(res.paginationMetaData.numberOfPages);
      })
      .catch(console.log);
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageClick = event => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-12 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex flex-col space-y-4 xl:space-y-0 xl:flex-row items-center justify-between space-x-4`}>
        <div className={`flex items-center space-x-12`}></div>
        <div className={`flex items-center space-x-6`}>
          {/* <Button className={`flex-shrink-0`} text={`View Rewards`} type={`secondary`} /> */}
          <Button className={`flex-shrink-0`} onClick={() => history.push('/onboarding/create-survey/survey-edit/true')} text={`Create Survey`} />
        </div>
      </div>
      <SurveyTable data={assets?.surveys} />
      <div className={`flex justify-center`}>
        <ReactPaginate
          className={`flex items-center space-x-6 mx-auto mb-4`}
          nextClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          previousClassName={`bg-blue-100 w-10 h-10 flex justify-center items-center text-2xl rounded-full text-brand_blue hover:bg-blue-200`}
          activeClassName={`bg-brand_blue w-8 h-8 flex justify-center items-center text-lg rounded-full text-white`}
          breakLabel='...'
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel='<'
          renderOnZeroPageCount={null}
        />
      </div>
      <div className='border border-gray-100 rounded-xl p-4'>
        <Chart type={'survey'} data={chartData} />
      </div>
    </FadeIn>
  );
};

export default AllSurvey;
