import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';

import { ReactComponent as Success } from '../../../../assets/check.svg';
import Button from '../../../../components/fragments/button';
import Heading from '../../../../components/fragments/heading';
import Text from '../../../../components/fragments/text';
import { AssetActions } from '../../../../states/actions';

const MoveSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reOnboard } = useParams();

  useEffect(() => {
    dispatch(AssetActions.setApp('as'));
    // eslint-disable-next-line
  }, []);

  return (
    <FadeIn className={`p-6 pb-16 pt-12 w-full min-h-screen flex flex-col justify-center items-center space-y-12`}>
      <div className={`w-80 h-80`}>
        <Success className={`w-80`} />
      </div>
      <div className={`space-y-2`}>
        {reOnboard ? (
          <Heading className={`font-semibold text-center mt-6`} title={`Assets Moved and Reoboarded Successfully!`} />
        ) : (
          <Heading className={`font-semibold text-center mt-6`} title={`Assets Moved Successfully!`} />
        )}
        <Text className={`text-center text-brand_blue`} value={`Awesome. You have started the asset movement process`} />
      </div>
      {reOnboard ? (
        <Button text={`Monitor Reoboarding Progress`} onClick={() => history.push('/onboarding/onboard-progress')} />
      ) : (
        <Button text={`Back to Dashboard`} onClick={() => history.push('/dashboard')} />
      )}
    </FadeIn>
  );
};

export default MoveSuccess;
