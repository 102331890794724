import { useRef } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';

import { ReactComponent as Upload } from '../../../assets/upload-2.svg';

import InputBox from '../input-box';
import SelectBox from '../select-box';

// const initialData = {
//   image: '',
//   name: '',
//   level: 'Primary',
// };

const AddAssets = ({ className, assetsTypes, setAssetsTypes }) => {
  const imgRef = useRef();

  // const handleAdd = () => {
  //   setAssetsTypes([...assetsTypes, { ...initialData, id: assetsTypes.length + 1 }]);
  // };

  const handleRemove = index => {
    let i = assetsTypes.filter(a => {
      return index !== a.id;
    });
    setAssetsTypes(i);
  };

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    let data = assetsTypes.map(j => {
      if (j.id === id) {
        return { ...j, [name]: value };
      }
      return j;
    });
    setAssetsTypes(data);
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleImageChange = async (e, id) => {
    e.preventDefault();
    console.log('asset types', assetsTypes);
    console.log('item id', id);
    if (e.target.files[0]) {
      let dataUri = await toBase64(e.target.files[0]);
      let data = assetsTypes.map(j => {
        if (j.id === id) {
          return { ...j, image: e.target.files[0], photo: { image: dataUri } };
        } else {
          return j;
        }
      });
      setAssetsTypes(data);
    }
  };

  return (
    <FadeIn className={`${className} px-6 md:px-12 py-16 bg-brand_blue rounded-3xl max-w-3xl mx-auto space-6-12 w-full`}>
      {/* <div className='text-white text-center pb-4'>Onboard one asset type at a time</div> */}
      {assetsTypes &&
        assetsTypes.length > 0 &&
        assetsTypes.map((item, idx) => {
          return (
            <div key={idx} className={`w-full mb-12 text-gray-100 flex flex-col items-center space-y-6 relative`}>
              <div className='w-full space-y-2'>
                <div className={`font-semibold text-gray-100`}>Asset Image </div>
                <div
                  onClick={() => {
                    imgRef?.current?.click();
                  }}
                  className='w-full h-60 bg-white bg-opacity-10 text-brand_blue cursor-pointer rounded-xl'
                >
                  {item.image ? (
                    <img className='w-full h-full object-cover' src={URL.createObjectURL(item.image)} alt='' />
                  ) : (
                    <div className='p-6 w-full h-full'>
                      <Upload className='w-full h-full ' />
                    </div>
                  )}
                  <input ref={imgRef} className='hidden' onChange={e => handleImageChange(e, item.id)} type='file' accept='image/*' />
                </div>
              </div>
              {idx > 0 && (
                <div
                  onClick={() => handleRemove(item.id)}
                  className='absolute -right-2 -top-1 w-6 text-sm h-6 bg-white shadow text-brand_blue text-center rounded-full flex justify-center items-center cursor-pointer font-medium'
                >
                  x
                </div>
              )}
              {/* )} */}

              <div className='flex space-x-6 items-center w-full'>
                <InputBox
                  type={`text`}
                  name={`name`}
                  className={'text-gray-100'}
                  value={item.name}
                  onValueChange={e => handleInputChange(e, item.id)}
                  required={true}
                  variant={'controlled'}
                  placeholder={`e.g. Batteries, Medicine, Fridge`}
                  label={'Enter Asset Name'}
                />

                <SelectBox
                  className='w-20'
                  name='level'
                  value={item.level}
                  onValueChange={e => handleInputChange(e, item.id)}
                  options={['Primary', 'Secondary', 'Tertiary']}
                  label={'Package Level'}
                  placeholder={'Select package'}
                />
              </div>
            </div>
          );
        })}
      {/* <div className={`p-4 flex justify-center items-center cursor-pointer border border-dashed border-gray-200 rounded-md  text-gray-200`} onClick={handleAdd}>
        Add More Assets
      </div> */}
    </FadeIn>
  );
};

export default AddAssets;
