import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NotFound from '../404-page';
import Logo from '../../components/fragments/logo';
import Container from '../../components/layout/container';
import SetUpWarehouse from './setup-warehouse';
import MapPreview from './map-preview';
import SetUpHardware from './setup-hardware';

import { PlanActions } from '../../states/actions';

const SetUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector(state => state.products);

  useEffect(() => {
    if (products && products.length > 0) {
      products.forEach(p => {
        if (p.code === 'AS') {
          dispatch(PlanActions.fetchPlans(p.id));
        }
      });
    }
    // eslint-disable-next-line
  }, [products]);

  return (
    <div className={`min-h-screen bg`}>
      <Route exact path={['/setup/hardware', '/setup/hardware/ctr/:controller', '/setup/hardware/preview']}>
        <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto`}>
          <Logo size={180} />
          <div onClick={() => history.goBack()} className='text-brand_blue font-semibold my-6 cursor-pointer'>
            &larr; Back
          </div>
        </div>
      </Route>
      <Container className={`w-full max-w-7xl mx-auto`}>
        <Switch>
          <Route exact path={['/setup/warehouse', '/setup/warehouse/ctr/:controller']}>
            <SetUpWarehouse />
          </Route>
          <Route exact path={'/setup/warehouse/map-preview'}>
            <MapPreview />
          </Route>
          <Route exact path={['/setup/hardware', '/setup/hardware/ctr/:controller']}>
            <SetUpHardware />
          </Route>
          <Route render={() => <NotFound />} />
        </Switch>
      </Container>
    </div>
  );
};

export default SetUp;
