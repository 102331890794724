import React, { useEffect, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AssetsTable from '../../../../components/fragments/asset-table';
import Heading from '../../../../components/fragments/heading';
import Button from '../../../../components/fragments/button';
import Loader from '../../../../components/fragments/loader/loader';

import { AssetActions } from '../../../../states/actions';

const StockCountBase = () => {
  const dispatch = useDispatch();
  const { batchId } = useParams();
  const assets = useSelector(state => state.assets);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (batchId) {
      dispatch(AssetActions.fetchStockCountBatch(batchId)).then(res => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [batchId]);

  const handleClick = item => {
    console.log(item);
  };

  return (
    <FadeIn
      className={`flex flex-col justify-start
      p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4  justify-between items-center'>
        <Heading className={`text-brand_blue font-semibold`} title={`Stock Count Report`} />

        <Button className={`flex-shrink-0`} text={`Download Report`} />
      </div>

      {assets?.stockCount && assets?.stockCount?.currentBatch.length > 0 && !loading ? (
        <AssetsTable data={assets?.stockCount?.currentBatch} variant={1.6} onClick={handleClick} />
      ) : (
        <Loader />
      )}
    </FadeIn>
  );
};

export default StockCountBase;
