import React, { useState } from 'react';
import { GoogleMap, DirectionsService, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';

import { REACT_APP_MAP_API_KEY } from '../../../config';

const libraries = ['places'];

const center = { lat: 6.5244, lng: 3.3792 };
const MovementMap = () => {
  const [state, setState] = useState({
    response: null,
    travelMode: 'DRIVING',
    origin: 'yaba, nigeria',
    destination: 'alaba international market',
  });

  const directionsCallback = response => {
    console.log('response: ', response);
    if (response !== null && !state.response) {
      if (response.status === 'OK') {
        setState({
          ...state,
          response,
        });
      } else {
        console.log('response: ', response);
      }
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: libraries,
    googleMapsApiKey: REACT_APP_MAP_API_KEY,
  });

  return isLoaded ? (
    <div style={{ height: '600px' }} className={`bg-gray-200 rounded-3xl w-full overflow-hidden`}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={center}
        zoom={10}
        options={{
          // disableDefaultUI: true,
          // mapTypeControl: false,
          styles: [
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }],
            },
            // {
            //   featureType: 'transit',
            //   elementType: 'labels.icon',
            //   stylers: [{ visibility: 'off' }],
            // },
          ],
        }}
      >
        {state.destination !== '' && state.origin !== '' && (
          <DirectionsService
            // required
            options={{
              destination: state.destination,
              origin: state.origin,
              travelMode: state.travelMode,
            }}
            // required
            callback={directionsCallback}
          />
        )}

        {state.response !== null && (
          <DirectionsRenderer
            options={{
              directions: state.response,
            }}
          ></DirectionsRenderer>
        )}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default MovementMap;
