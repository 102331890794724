import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AllAssets from './all-assets';

const Assets = () => {
  return (
    <Switch>
      <Route exact path={['/im/assets', '/im/assets/attach-survey']}>
        <AllAssets />
      </Route>
      <Redirect to={`/404`} />
    </Switch>
  );
};

export default Assets;
