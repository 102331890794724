import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import ImageFadeIn from 'react-image-fade-in';
import { useHistory } from 'react-router-dom';

import Placeholder from '../../../assets/placeholder.png';

import { ReactComponent as Checked } from '../../../assets/radio-checked.svg';
import { ReactComponent as Unchecked } from '../../../assets/radio-unchecked.svg';

import Heading from '../heading';
import Text from '../text';
import Button from '../button';

const SelectCode = ({ codeType, setCodeType, onProceed }) => {
  const history = useHistory();

  const handleClick = idx => {
    setCodeType(
      codeType.map((d, i) => {
        if (i === idx && !d.selected) {
          d.selected = true;
        } else if (i !== idx) {
          d.selected = false;
        }
        return d;
      }),
    );
  };

  return (
    <FadeIn className='space-y-12 flex flex-col items-center'>
      <Heading className={`text-center text-brand_blue font-semibold`} title={`How Do You Want The Stickers To Be Printed`} />
      <FadeIn className={`flex flex-col lg:flex-row space-y-12 lg:space-x-12 lg:space-y-0 max-w-3xl justify-center`}>
        {codeType.map((item, idx) => {
          return (
            <div key={idx} onClick={() => handleClick(idx)} className='col-span-1 bg-white rounded-2xl overflow-hidden shadow-lg hover:shadow cursor-pointer'>
              <div className='w-80'>
                {item.image ? (
                  <ImageFadeIn className='w-full h-60 object-top  flex-shrink-0' src={item.image} alt='' opacityTransition={1} />
                ) : (
                  <ImageFadeIn className='w-full h-60 object-top  flex-shrink-0' src={Placeholder} alt='' opacityTransition={1} />
                )}
              </div>
              <div>
                <div className='flex items-center space-x-6 p-8'>
                  {item.selected ? <Checked /> : <Unchecked />}
                  <Text className={`text-base xl:text-lg font-medium text-brand_blue`} value={item.name} />
                </div>
              </div>
            </div>
          );
        })}
      </FadeIn>
      <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
        <Button text={'Back'} type={`secondary`} onClick={() => history.goBack()} />
        <Button text={'Generate ' + codeType.find(d => d.selected).name} onClick={() => onProceed(true)} />
      </div>
    </FadeIn>
  );
};

export default SelectCode;
