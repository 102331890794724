import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';

import Heading from '../../../components/fragments/heading';
import Button from '../../../components/fragments/button';
import AddAssets from '../../../components/fragments/add-assets';
import Logo from '../../../components/fragments/logo/logo';

import { ResponseActions, AssetActions, SurveyActions } from '../../../states/actions';

const initialData = {
  image: '',
  name: '',
  level: 'Primary',
};

const GetStarted = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [assetsTypes, setAssetsTypes] = useState([initialData]);

  useEffect(() => {
    dispatch(AssetActions.resetOnboard());
    dispatch(SurveyActions.resetSurvey());
    dispatch(AssetActions.setApp('im'));
    // eslint-disable-next-line
  }, []);

  const onContinue = () => {
    const proceed = assetsTypes.every(item => item.name && item.level);
    if (!proceed) {
      dispatch(ResponseActions.notify({ title: 'Incomplete Data', message: 'Enter Asset Name', type: 'default', loading: false }));
      return;
    }
    dispatch(AssetActions.updatePackageTypes(assetsTypes));
    dispatch(AssetActions.updateOnboardPhoto(assetsTypes[0]?.photo));
    history.push('/im/onboarding/upload-assets');
  };

  return (
    <div className={`w-full px-6 2xl:px-52 pt-8 max-w-3000 mx-auto min-h-screen`}>
      <Logo size={180} />
      <FadeIn className='w-full h-auto py-12 px-4 sm:px-6 md:px-8 flex flex-col justify-center space-y-6'>
        <Heading className={`text-center text-brand_blue font-semibold`} title={`What Assets Will You Be Onboarding?`} />

        <AddAssets assetsTypes={assetsTypes} setAssetsTypes={setAssetsTypes} />

        <div className={`flex flex-col w-full space-y-6 md:flex-row md:space-y-0 md:space-x-12  justify-center`}>
          <Button text={'Back'} type={`secondary`} onClick={() => history.goBack()} />
          <Button text={'Continue'} onClick={onContinue} />
        </div>
      </FadeIn>
    </div>
  );
};

export default GetStarted;
