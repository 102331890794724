import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';

import Tracker from '../../../../assets/tracking.png';
import MovementMap from '../../../../components/fragments/movement-map/movement-map';

const Tracking = () => {
  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className='flex items-start flex-col space-y-6 2xl:space-y-0 2xl:flex-row 2xl:space-x-6'>
        <div className='space-y-4 p-4 border border-brand_blue rounded-md max-w-sm w-full'>
          {/*  <div className=''>
            <div className='text-gray-400 font-medium'>Name</div>
            <div className='text-brand_blue font-medium'>{'warehouse.name'}</div>
          </div>
          <div className=''>
            <div className='text-gray-400 font-medium'>Location</div>
            <div className='text-brand_blue font-medium'>
              {'warehouse.address'}, {'warehouse.country'}
            </div>
          </div>
          <div className=''>
            <div className='text-gray-400 font-medium'>Number Of Assets</div>
            <div className='text-brand_blue font-medium'>{'assetCount'}</div>
          </div>
          <div className=''>
            <div className='text-gray-400 font-medium mb-1'>Type Of Assets</div>
            <div className='text-brand_blue font-medium mb-1 flex items-center space-x-2'>
              <div className='w-4 h-4 bg-brand_green'></div>
              <div>Battery (AS)</div>
            </div>
            <div className='text-brand_blue font-medium mb-1 flex items-center space-x-2'>
              <div className='w-4 h-4 bg-brand_blue'></div>
              <div>Charger Controllers (BS)</div>
            </div>
            <div className='text-brand_blue font-medium mb-1 flex items-center space-x-2'>
              <div className='w-4 h-4 bg-yellow-500'></div>
              <div>Solar Panel (CS)</div>
            </div>
          </div> */}
          <img className='w-full object-cover' src={Tracker} alt='' />
        </div>
        <MovementMap />
      </div>
    </FadeIn>
  );
};

export default Tracking;
