import React from 'react';

import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MyWarehouses from '../my-warehouses';

const GetStarted = () => {
  const warehouses = useSelector(state => state.warehouses);

  return <>{warehouses.length < 1 ? <Redirect to={'/im/setup/warehouse/ctr/add'} /> : <MyWarehouses />}</>;
};

export default GetStarted;
