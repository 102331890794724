import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import runOneSignal from './runOneSignal';

import Loader from './components/fragments/loader/loader';
import AsController from './as-controller';
import ImController from './im-controller';
import SetUp from './pages/SetUp';
import AlarmLogs from './components/fragments/alarm-logs';
import ImSetUp from './im/setup';
import Base from './components/layout/base';
import ImBase from './components/layout/im-base';
import GenerateCode from './pages/dashboard/onboarding/generate-code';
import StickerPrintSuccess from './pages/dashboard/onboarding/sticker-print-success';
import PostOnboard from './pages/dashboard/onboarding/post-onboard';
import OnboardedAssets from './pages/dashboard/onboarding/onboarded-assets';
import Onboarding from './pages/dashboard/onboarding';
import ImOnboarding from './im/onboarding';
import OnboardProgress from './components/fragments/onboard-progress';
import Profile from './components/fragments/profile/';
import Notification from './components/fragments/notifications';
import NotFound from './pages/404-page';
import Map from './components/fragments/map';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { REACT_APP_SUPPLY_CHAIN_URL, APPINSIGHTS_INSTRUMENTATIONKEY } from './config';

import {
  UserActions,
  AssetActions,
  SiteActions,
  HardwareActions,
  WarehouseActions,
  AnalyticsActions,
  NotificationActions,
  ResponseActions,
} from './states/actions';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const response = useSelector(state => state.response);
  const user = useSelector(state => state.user);
  const sites = useSelector(state => state.sites);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let reactPlugin = new ReactPlugin();
    let appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
      },
    });
    appInsights.loadAppInsights();
  }, []);

  useEffect(() => {
    if (user?.id) {
      console.log('chektrack updates here 9');
      if (!window?.OneSignal?.User?.PushSubscription?.id) {
        runOneSignal(user);
      }
    }
    // eslint-disable-next-line
  }, [user?.id]);

  useEffect(() => {
    let appName = localStorage.getItem('app-name');
    if (appName) {
      dispatch(AssetActions.setApp(appName));
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    let lsToken = localStorage.getItem('chekkit-act');
    if (lsToken) {
      dispatch(UserActions.updateUser({ token: lsToken }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user?.token || !user?.isToken) {
      Promise.allSettled([
        dispatch(UserActions.fetchUser()),
        dispatch(AnalyticsActions.retrieveWarehousesWithTopAssets(5)),
        dispatch(WarehouseActions.fetchWarehouses(5, 1)),
        dispatch(AssetActions.fetchAssets(5, 1)),
        dispatch(AssetActions.fetchAssetTypes(5, 1)),
        // dispatch(AssetActions.fetchStockCountHistory()),
        dispatch(AssetActions.fetchRfidTags()),
        dispatch(SiteActions.fetchSites(5, 1)),
        dispatch(HardwareActions.fetchHardwares(5, 1)),
        dispatch(NotificationActions.retrieveNotifications()),
        dispatch(AssetActions.fetchSurveys(5, 1)),
      ])
        .then(_ => setLoaded(true))
        .catch(_ => setLoaded(true));
      history.push(location.pathname);
    }
    // eslint-disable-next-line
  }, [user?.token, user?.isToken]);

  useEffect(() => {
    if (response.type) {
      let msg = '';
      if (typeof response.message === 'string') {
        msg = response.message;
      } else {
        response.message.forEach(msg => {
          Object.keys(msg).forEach(key => {
            let str = `${key} Error`;
            let title = str.charAt(0).toUpperCase() + str.slice(1);
            let message = msg[key];
            showNotification(response, message, title);
          });
        });
        return;
      }
      showNotification(response, msg);
    } // eslint-disable-next-line
  }, [response]);

  const showNotification = (response, message, title) => {
    store.addNotification({
      message: message,
      type: response.type,
      title: title ? title : response.title,
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 2000,
        onScreen: true,
        pauseOnHover: true,
        showIcon: true,
      },
      onRemoval: () => {
        let timer;
        (() => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            dispatch(ResponseActions.notify({ title: '', message: '', type: '', loading: false }));
          }, 2000);
        })();
      },
    });
  };

  return (
    <>
      <ReactNotification isMobile={true} />
      <Switch location={location}>
        <Route exact path={['/', '/as/:token']}>
          <AsController />
        </Route>

        {/*  */}
        <Route exact path={['/im', '/inv/:token']}>
          <ImController />
        </Route>
        {/*  */}

        {/*  */}
        {loaded ? (
          <Switch location={location}>
            <Route path={'/map'}>
              <Map sites={sites} />
            </Route>
            <Route path={['/setup/warehouse', '/setup/warehouse/map-preview', '/setup/hardware']}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <SetUp /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route path={['/im/setup/warehouse', '/im/setup/warehouse/map-preview']}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <ImSetUp /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route exact path={'/onboarding/generate-code'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <GenerateCode /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/* */}
            <Route exact path={'/onboarding/onboard-progress'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <OnboardProgress /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route exact path={'/profile'}>
              {user && user?.isAuthorized ? (
                <div className='bg-dots'>{user?.company?.subscription?.status ? <Profile /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</div>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/* */}
            <Route exact path={'/alarm-logs'}>
              {user && user?.isAuthorized ? (
                <div className='bg-dots'>
                  {user?.company?.subscription?.status ? <AlarmLogs /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}
                </div>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/* */}
            <Route exact path={['/notifications', '/notifications/:id']}>
              {user && user?.isAuthorized ? (
                <div className='bg-dots'>
                  {user?.company?.subscription?.status ? <Notification /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}
                </div>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/* */}
            <Route exact path={'/onboarding/:id/post-onboard'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <PostOnboard /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route exact path={'/onboarding/onboarded-assets'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <OnboardedAssets /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route exact path={'/onboarding/sticker-id-success'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <StickerPrintSuccess /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route path={'/onboarding'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <Onboarding /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route path={['/onboarding', '/dashboard', '/assets', '/field-configuration', '/field-report', '/reports', '/finances', '/settings']}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <Base /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route path={'/im/onboarding'}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <ImOnboarding /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route path={['/im/onboarding', '/im/dashboard', '/im/assets', '/im/warehouses', '/im/stock-count', '/im/reports']}>
              {user && user?.isAuthorized ? (
                <>{user?.company?.subscription?.status ? <ImBase /> : window.open(REACT_APP_SUPPLY_CHAIN_URL + '/plans', '_self')}</>
              ) : (
                window.open(REACT_APP_SUPPLY_CHAIN_URL + '/auth/signin', '_self')
              )}
            </Route>
            {/*  */}
            <Route
              render={() => {
                return (
                  <div className={`bg`}>
                    <NotFound />;
                  </div>
                );
              }}
            />
          </Switch>
        ) : (
          <Loader />
        )}

        <Route
          render={() => {
            return (
              <div className={`bg`}>
                <NotFound />;
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
}

export default App;
