import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AllSurvey from './all-survey';
import SurveyDetail from './survey-detail';

const SurveyRewards = () => {
  return (
    <Switch>
      <Route exact path={'/field-report'}>
        <AllSurvey />
      </Route>
      <Route exact path={['/field-report/survey/:id/update-survey', '/field-report/survey/:id/preview-survey']}>
        <SurveyDetail />
      </Route>
      <Redirect to={`/404`} />
    </Switch>
  );
};

export default SurveyRewards;
