import React from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';

import Heading from '../../../../components/fragments/heading';
import AssetTable from '../../../../components/fragments/asset-table';
import Chart from '../../../../components/fragments/chart';

const data = [
  { day: 'Sun', time: '8:30', initialTemp: '30C', finalTemp: '30C', date: 'Apr 20, 2021' },
  { day: 'Mon', time: '8:30', initialTemp: '30C', finalTemp: '30C', date: 'Apr 20, 2021' },
  { day: 'Tue', time: '8:30', initialTemp: '30C', finalTemp: '30C', date: 'Apr 20, 2021' },
  { day: 'Wed', time: '8:30', initialTemp: '30C', finalTemp: '30C', date: 'Apr 20, 2021' },
];

const tempData = [
  {
    day: 'Mar 1',
    temp: 40,
  },
  {
    day: 'Mar 2',
    temp: 35,
  },
  {
    day: 'Mar 3',
    temp: 60,
  },
  {
    day: 'Mar 4',
    temp: 40,
  },
  {
    day: 'Mar 5',
    temp: 50,
  },
  {
    day: 'Mar 6',
    temp: 20,
  },
  {
    day: 'Mar 7',
    temp: 50,
  },
];

const humidData = [
  {
    day: 'Mar 1',
    humid: 12,
  },
  {
    day: 'Mar 2',
    humid: 35,
  },
  {
    day: 'Mar 3',
    humid: 32,
  },
  {
    day: 'Mar 4',
    humid: 15,
  },
  {
    day: 'Mar 5',
    humid: 40,
  },
  {
    day: 'Mar 6',
    humid: 20,
  },
  {
    day: 'Mar 7',
    humid: 25,
  },
];

const AssetVitals = () => {
  return (
    <FadeIn
      className={`flex flex-col justify-start
     space-y-6 p-6 pb-16 min-h-screen w-full mx-auto bg-white`}
    >
      <div className={`flex justify-between items-start space-y-16 xl:space-x-16 xl:space-y-0 flex-col xl:flex-row`}>
        <div className={`flex-1 space-y-8 w-full`}>
          <div>
            <Heading className={`font-semibold text-gray-700`} title={`Daily Temperature`} size={3} />
            <AssetTable data={data} variant={4} />
          </div>

          <div className='border border-gray-100 rounded-xl p-4'>
            <Chart type={'temp'} data={tempData} />
          </div>
        </div>
        <div className={`flex-1 space-y-8 w-full`}>
          <div>
            <Heading className={`font-semibold text-gray-700`} title={`Daily Humidity`} size={3} />
            <AssetTable data={data} variant={4} />
          </div>
          <div className='border border-gray-100 rounded-xl p-4'>
            <Chart type={'humid'} data={humidData} />
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

export default AssetVitals;
