import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import StockCountBase from './stock-count-base';
import Counter from './counter';
import CounterResult from './counter-result';

const StockCount = () => {
  return (
    <Switch>
      <Route exact path={'/im/stock-count'}>
        <StockCountBase />
      </Route>
      <Route exact path={'/im/stock-count/counter'}>
        <Counter />
      </Route>
      <Route exact path={'/im/stock-count/counter-result/:batchId'}>
        <CounterResult />
      </Route>
      <Redirect to={`/404`} />
    </Switch>
  );
};

export default StockCount;
