import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { useDispatch } from 'react-redux';

import { ReactComponent as UploadIcon } from '../../../../assets/upload.svg';
import Button from '../../../../components/fragments/button';
import Heading from '../../../../components/fragments/heading';

import { AssetActions } from '../../../../states/actions';

const EmbedInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [stickerInfo, setStickerInfo] = useState({
    description: '',
    image: '',
  });
  const [file, setFile] = useState();

  const handleFileChange = e => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const fileToBase64 = file => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (file) {
      fileToBase64(file).then(result => {
        setStickerInfo({ ...stickerInfo, image: result });
      });
    } // eslint-disable-next-line
  }, [file]);

  const handleChange = e => {
    const { name, value } = e.target;
    setStickerInfo({ ...stickerInfo, [name]: value });
  };

  const embedInfo = () => {
    dispatch(AssetActions.updateSticker(stickerInfo));
    history.push('/onboarding/onboard-summary');
  };

  return (
    <FadeIn className='w-full h-full py-12 md:py-32 px-4 sm:px-6 md:px-8 flex flex-col items-center justify-center min-h-screen bg-dots'>
      <div className={`flex flex-col justify-center items-center p-4 w-full max-w-3xl space-y-10`}>
        <div className={`space-y-4 text-center`}>
          <div className={`text-center`}>
            <Heading className={`font-medium inline`} title={`Step 2 of sticker setup: `} />
            <Heading className={`font-bold text-green-400 inline`} title={`Embed Information`} />
          </div>
          <div>What other information will you like the sticker to carry:</div>
        </div>

        <div className={`p-10 rounded-xl bg-brand_blue max-w-lg w-full space-y-6 text-gray-100`}>
          <div className={`rounded-xl bg-white bg-opacity-10 h-48`}>
            <textarea
              className={`w-full h-full p-4 bg-transparent focus:outline-none resize-none font-extrabold text-2xl`}
              placeholder={`Text to embed`}
              spellCheck={false}
              type='text'
              name='description'
              onChange={handleChange}
              value={stickerInfo.description}
            />
          </div>
          <div
            onClick={() => fileRef.current.click()}
            className={`rounded-xl bg-white bg-opacity-10 h-24 border-2 border-dashed cursor-pointer p-6 flex items-center justify-center text-sm`}
          >
            <input ref={fileRef} type={`file`} accept='image/png, image/jpeg' onChange={handleFileChange} hidden />
            <div className={`font-bold mr-2 bg-white w-10 h-10 rounded-lg p-3`}>
              <UploadIcon className={`w-full h-full`} />
            </div>
            {!file && (
              <>
                <div className={`font-bold`}>Image to embed</div>
                <div className={`font-medium text-gray-400`}>(.jpg, .png, .jpeg)</div>
              </>
            )}
            {file && (
              <>
                <div className={`font-bold`}>{file.name}</div>
              </>
            )}
          </div>
        </div>

        <div className={`flex flex-col w-full space-y-6 lg:flex-row lg:space-y-0 lg:space-x-12  justify-center`}>
          <Button className={`lg:w-60`} text={`Back`} type={`secondary`} onClick={() => history.goBack()} />
          <Button className={``} text={`Embed And Continue`} onClick={embedInfo} />
        </div>
      </div>
    </FadeIn>
  );
};

export default EmbedInfo;
