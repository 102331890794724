import { Route, Switch } from 'react-router-dom';

import Home from '../../im/dashboard/home';
import Assets from '../../im/dashboard/assets';
import Warehouses from '../../im/dashboard/warehouses';
import StockCount from '../../im/dashboard/stock-count';
import Reports from '../../im/dashboard/reports';

function ImContent() {
  return (
    <Switch exitBeforeEnter initial={false}>
      <Route path={'/im/dashboard'}>
        <Home />
      </Route>

      <Route path={'/im/assets'}>
        <Assets />
      </Route>

      <Route path={'/im/warehouses'}>
        <Warehouses />
      </Route>

      <Route path={'/im/stock-count'}>
        <StockCount />
      </Route>

      <Route path={'/im/reports'}>
        <Reports />
      </Route>
    </Switch>
  );
}

export default ImContent;
